import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "App";
import FlexContainer from "components/FlexContainer";
import CancelButton from "../components/CancelButton";
import ProceedButton from "../components/ProceedButton";
import SquareLogo from "img/Square.png";
import { Methods, NewRadioButton, HorizonContainer, HorizonLine, ButtonContainer } from "./Review";
import { SubscriptionContext } from "../SubscriptionContext";
import { Forms } from "../Subscription";
import { RadioGroup } from "@trendmicro/react-radio";
import Text from "components/Text";
import LeftText from "../components/LeftText";
import LoadingIndicator from "LoadingIndicator";
import PaymentForm from "../components/PaymentForm";
import styled from "styled-components";
import Toast from "Toast";

const FailingPayments = styled.div`
    color: red;
    font-size: 20px;
    font-weight: 500;
`;

class Edit extends Component {
    state = {
        method: Methods.NEW,
        loaded: false,
        loading: false,
    };

    componentDidMount() {
        const { cards } = this.context;
        this.loadingSquareJS();

        if (cards.length === 0) {
            return Toast.info("Card information doesn't exist");
        }
    }

    loadingSquareJS = () => {
        // Add Square to our project
        // Previously referenced https://medium.com/square-corner-blog/online-payments-form-react-9ecf164880bf
        // Now using Web Payments SDK: https://developer.squareup.com/docs/web-payments/overview
        let that = this;
        let sqPaymentScript = document.createElement("script");
        sqPaymentScript.src = (window.location.hostname === "business.dailyfixme.com") ?
            "https://web.squarecdn.com/v1/square.js" :
            "https://sandbox.web.squarecdn.com/v1/square.js";
        sqPaymentScript.type = "text/javascript";
        sqPaymentScript.async = false;
        sqPaymentScript.onload = () => {
            that.setState({
                loaded: true,
                isProcessClickable: true
            });
        };
        document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    };

    static contextType = SubscriptionContext;

    updateLoading = () => {
        this.setState({
            loading: true
        });
    };

    savePaymentMethod = () => {
        const { savePaymentMethod, selectedCard, token } = this.context;

        // Facebook Pixel Tracking
        window.fbq && window.fbq("track", "AddPaymentInfo");

        this.setState({
            loading: true
        });

        axios.put(`${API_URL}/company/square/card`, {
            cardId: selectedCard
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {
            this.setState({
                loading: false
            });

            savePaymentMethod();
            return Toast.info("Change payment method success");
        }).catch(error => {
            this.setState({
                loading: false
            });
            if (error.response) {
                if (error.response.data.error.messages) {
                    return Toast.error(error.response.data.error.messages.toString());
                }
                return Toast.error(error.response.data.error.toString());
            } else {
                return Toast.error(error.message);
            }
        });
    };

    deletePaymentMethod = () => {
        const { savePaymentMethod, selectedCard, token } = this.context;

        this.setState({
            loading: true
        });

        axios.delete(`${API_URL}/company/square/card/${selectedCard}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {
            this.setState({
                loading: false
            });

            savePaymentMethod();
            return Toast.info("Delete payment method success");
        }).catch(error => {
            this.setState({
                loading: false
            });
            if (error.response) {
                if (error.response.data.exception) {
                    return Toast.error(error.response.data.exception.toString());
                }
                return Toast.error(error.response.data.error.toString());
            } else {
                return Toast.error(error.message);
            }
        });
    };

    updatMethod = (value) => {
        this.setState({
            method: value
        });
    };

    render() {
        const { selectedCard, updateSelectedCard, savePaymentMethod, cards, currentForm,
            token, getAllCards, isPaymentFailing } = this.context;

        const { method } = this.state;

        if (this.state.loading) {
            return <LoadingIndicator />;
        }

        return (
            <FlexContainer flexGrow="1">
                <Text color="black" size="60px">
                    Change Payment Method
                </Text>
                <FlexContainer justifyContent="center" alignItems="center">
                    {
                        isPaymentFailing ?
                            <FailingPayments>
                                *Update your payment method information.
                            </FailingPayments>
                            : ""
                    }
                    <br/>
                    <LeftText color="black" size="20px" weight="500" margin="0">
                        { currentForm === Forms.REVIEW ? "Payment Method" : "Select Payment Method" }
                    </LeftText>
                    <HorizonLine />
                    <HorizonContainer align="start">
                        <RadioGroup
                            name="method"
                            value={method}
                        >
                            <HorizonContainer direction="row">
                                <FlexContainer direction="column">
                                    <NewRadioButton
                                        value={Methods.EXISTING}
                                        label="Use an existing credit/debit card"
                                        checked={ method === Methods.EXISTING }
                                        onChange={() => this.updatMethod(Methods.EXISTING)} />
                                </FlexContainer>
                                <FlexContainer direction="column">
                                    <NewRadioButton
                                        style={{ marginLeft: "10px", color: `${ isPaymentFailing && "red" }` }}
                                        value={Methods.NEW}
                                        label="Add a new Card"
                                        checked={ method === Methods.NEW}
                                        onChange={() => this.updatMethod(Methods.NEW)} />
                                </FlexContainer>
                            </HorizonContainer>
                        </RadioGroup>
                        { this.state.method === Methods.NEW ?
                            <FlexContainer align="start" direction="column">
                                { this.state.loaded ?
                                    <PaymentForm paymentForm={window.SqPaymentForm} token={token} currentForm={currentForm} getAllCards={getAllCards}
                                        callBack={ savePaymentMethod } nextClick={ savePaymentMethod } updateLoading={this.updateLoading} />
                                    :
                                    <LoadingIndicator />
                                }
                            </FlexContainer>
                            :
                            <FlexContainer>
                                <FlexContainer border="1px solid #707070" padding="20px 40px 20px 20px" marginTop="20px" align="left" >
                                    <RadioGroup
                                        name="card"
                                        value={selectedCard}
                                    >
                                        <FlexContainer direction="column">
                                            {cards.map((card) => {
                                                return (
                                                    <NewRadioButton
                                                        key={card.cardId}
                                                        style={{ marginLeft: "10px" }}
                                                        value={card.cardId}
                                                        label={card.displayInfo}
                                                        checked={ selectedCard === card.cardId}
                                                        onChange={() => updateSelectedCard(card.cardId)} />);}
                                            )}
                                        </FlexContainer>
                                    </RadioGroup>
                                </FlexContainer>
                                <FlexContainer marginTop="20px">
                                    <img src={SquareLogo} height="40px" width="140px" alt="square" />
                                </FlexContainer>
                                <ButtonContainer justifyContent="space-between" width="400px">
                                    <ProceedButton color="#6A1E00" width="100px" left="0" onClick={this.deletePaymentMethod}>
                                        DELETE
                                    </ProceedButton>
                                    <ButtonContainer margin="0">
                                        <CancelButton onClick={savePaymentMethod}>
                                            BACK
                                        </CancelButton>
                                        <ProceedButton left="5px" width="130px" padding="5px" onClick={this.savePaymentMethod}>
                                            Save Changes
                                        </ProceedButton>
                                    </ButtonContainer>
                                </ButtonContainer>
                            </FlexContainer>
                        }
                    </HorizonContainer>
                </FlexContainer>
            </FlexContainer>
        );
    }
}

export default Edit;