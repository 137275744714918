import React, { Component } from "react";
import FlexContainer from "components/FlexContainer";
import Text from "components/Text";
import Button from "components/Button";
import ImageSlider from "../components/ImageSlider";
import { ColorCircle } from "../components/ColorPicker";
import { CustomiseContext } from "../CustomiseContext";
import DifferentFonts from "components/DifferentFonts";
import BackgroundContainer from "components/BackgroundContainer";
import GridBackground from "img/GridBackground.png";

class Finish extends Component {
    static contextType = CustomiseContext;

    state = {
        imageHash: Date.now()
    }

    render() {
        const { backGroundColor, secondaryColor, primaryColor, companyName, colourPalette,
            currentScreen, currentForm, screenIds, leftScreen, rightScreen, assetUrl } = this.context;
        const backgroundColour = backGroundColor === "" ?
            "#000000" : backGroundColor;
        const primaryColour = primaryColor === "" ?
            "#FFFFFF" : primaryColor;
        const secondaryColour = secondaryColor === "" ?
            "#D9D9D9" : secondaryColor;

        return (
            <FlexContainer flexGrow="1">
                <Text color="black" size="60px" margin="0">
                    My Me App
                </Text>
                <FlexContainer margin="64px" justifyContent="center" direction="row" alignItems="center">
                    <FlexContainer width="400px" alignItems="flex-end">
                        <ImageSlider 
                            logoTopOffset={50}
                            currentScreen={currentScreen} 
                            currentForm={currentForm}
                            screenIds={screenIds}
                            leftScreen={leftScreen}
                            rightScreen={rightScreen}
                            colourPalette={colourPalette}
                            assetUrl={assetUrl + "?" + this.state.imageHash}/>
                    </FlexContainer>
                    <FlexContainer alignItems="flex-start" marginLeft="32px">
                        <Text family="Affogato" size="40px" color="#2D3037" margin="0 0 20px" noWrap={true}>
                            Customise Overview:
                        </Text>
                        <DifferentFonts 
                            size="20px"
                            marginTop="0" 
                            mediumText="Business Logo:"
                            color="#2A2B2A"
                            regularText={companyName + ".png"}
                            bottom="10px"
                        />
                        <FlexContainer width="391px" height="102px" alignItems="center">
                            <BackgroundContainer image={GridBackground} width="391px" height="102px">
                                {/* Adding a time stamp on the image url force the image to be updated each time  */}
                                <img style={{ maxWidth: "391px", maxHeight: "102px" }} src={`${assetUrl}?${this.state.imageHash}`} alt={assetUrl} />
                            </BackgroundContainer>
                        </FlexContainer>
                        <Text family="Affogato" size="20px" color="#2A2B2A" margin="20px 0">
                            Colour Selection:
                        </Text>
                        <FlexContainer direction="row" height="100px">
                            <div style={{ width: "80px", marginRight: "40px" }}>
                                <ColorCircle color={backgroundColour} />
                                <Text size="14px" color="#2D3037" align="center" margin="0">Background</Text>
                            </div>
                            <FlexContainer direction="row">
                                <div style={{ width: "80px", marginRight: "40px" }}>
                                    <ColorCircle color={primaryColour} />
                                    <Text size="14px" color="#2D3037" align="center" margin="0">Primary</Text>
                                </div>
                                <div style={{ width: "80px" }}>
                                    <ColorCircle color={secondaryColour} />
                                    <Text size="14px" color="#2D3037" align="center" margin="0">Secondary</Text>
                                </div>
                            </FlexContainer>
                        </FlexContainer>
                        <hr style={{ width: "100%", margin: "30px 0" }}/>
                        <Button backgroundColor="#2D3037" color="white" padding="10px 60px" size="14px"
                            borderRadius="30px" style={{ boxShadow: "2px 2px #e3e3e3" }}
                            onClick={() => this.context.editClick()}>
                            Edit
                        </Button>
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        );
    }
}

export default Finish;