import React, { Component } from "react";
import { hot } from "react-hot-loader/root";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import styled, { createGlobalStyle, css } from "styled-components";
import { UserContext } from "./UserContext";
import moment from "moment";
import LeftNav from "./components/LeftNav";
import FlexContainer from "components/FlexContainer";
import Text from "components/Text";
import Button from "components/Button";
import FootLogo from "img/FootLogo.png";
import AuthorizedRoute from "AuthorizedRoute";
import SignUp from "SignUp";
import Statistics from "Statistics";
import Noticeboard from "Noticeboard";
import Login from "Login";
import Subscription from "Subscription";
import ForgotPassword from "ForgotPassword";
import Customise from "Customise";
import EmployeeList from "EmployeeList";
import Setting from "Setting";
import ResetPassword from "ResetPassword";
import SignUpContainer from "SignUpContainer";
import Toast from "Toast";
import Modal from "Modal";
import QuoteCategorySelection from "../EmployeeList/QuoteCategorySelection";
import axios from "axios";
//Fonts
import FontFaceObserver from "fontfaceobserver";
import AffogatoBlack from "fonts/Affogato/Affogato-Black.woff";
import AffogatoBold from "fonts/Affogato/Affogato-Bold.woff";
import AffogatoLight from "fonts/Affogato/Affogato-Light.woff";
import AffogatoMedium from "fonts/Affogato/Affogato-Medium.woff";
import AffogatoRegular from "fonts/Affogato/Affogato-Regular.woff";

export const API_URL = (() => {
    if (window.location.hostname === "localhost") {
        return "http://127.0.0.1:8880";
    }

    if (window.location.hostname === "staging.dailyfixme.com") {
        return "https://api.staging.dailyfixme.com";
    }

    if (window.location.hostname === "business.dailyfixme.com") {
        return "https://api.dailyfixme.com";
    }

    return null;
})();

const GlobalStyle = createGlobalStyle`
    /* * { box-shadow: inset 0 0 1px rgba(0, 0, 0, .6); } */

    @font-face {
        font-family: 'Affogato';
        font-weight: 400;
        src: url('${AffogatoRegular}');
    }

    @font-face {
        font-family: 'Affogato';

        font-weight: 300;
        src: url('${AffogatoLight}');
    }

    @font-face {
        font-family: 'Affogato';
        font-weight: 500;
        src: url('${AffogatoMedium}');
    }

    @font-face {
        font-family: 'Affogato';
        font-weight: bold;
        font-weight: 700;
        src: url('${AffogatoBold}')
    }

    @font-face {
        font-family: 'Affogato';
        font-weight: 900;
        src: url('${AffogatoBlack}');
    }

    html,
    body,
    #root {
        font-family: 'Affogato', Verdana, Geneva, sans-serif;
        height: 100%;
        margin: 0;
        padding: 0;
        width: 100%;
        font-weight: 500;
    }

    ${p => p.isLoadingFonts && css`
        html,body, #root {
                font-family: Verdana, Geneva, sans-serif;
                line-height: 1.6;
                letter-spacing: -0.9px;
                word-spacing: -0.95px;
                height: auto;
                visibility: visible;
                font-weight: 500;
        }`}
`;

const LoggedInRightContainer = styled(FlexContainer)`
    background: #ffffff;
    flex-grow: 1;
    height: auto;
    width: auto;
`;

export const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        width: "40%",
        backgroundColor: "#292C33",
        color: "#F4F4F4",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        transform: "translate(-50%, -50%)"
    }
};

const StorageKey = {
    TOKEN: "token",
    UUID: "uuid",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    IS_CUSTOMIZE_COMPLETE: "isCustomizeComplete",
    IS_ACTIVE: "isActive",
    EMAIL: "email",
    IS_AUSTRALIAN: "isAustralian",
};

class App extends Component {
    state = {
        isLoadingFonts: true,
        user: null
    };

    openSupportMail = () => {
        window.location.href = "mailto:support@dailyfixme.com";
    };

    openTermsConditions = () => {
        window.open("https://s3-ap-southeast-2.amazonaws.com/dailyfixme.content/Terms+and+Conditions.pdf", "_blank");
    };

    openPrivacyPolicy = () => {
        window.open("https://s3-ap-southeast-2.amazonaws.com/dailyfixme.content/Privacy+Policy.pdf", "_blank");
    };

    componentDidMount() {
        let observer = new FontFaceObserver("Affogato");
        observer.load().then(() => {
            this.setState({
                isLoadingFonts: false
            });
        });

        const token = localStorage.getItem(StorageKey.TOKEN);

        if (token) {
            const companyName = localStorage.getItem(StorageKey.COMPANY_NAME);
            const firstName = localStorage.getItem(StorageKey.FIRST_NAME);
            const lastName = localStorage.getItem(StorageKey.LAST_NAME);
            const isCustomizeComplete = JSON.parse(localStorage.getItem(StorageKey.IS_CUSTOMIZE_COMPLETE));
            const isActive = JSON.parse(localStorage.getItem(StorageKey.IS_ACTIVE));
            const email = localStorage.getItem(StorageKey.EMAIL);
            const isAustralian = JSON.parse(localStorage.getItem(StorageKey.IS_AUSTRALIAN));

            this.setState({
                isLoadingFonts: false,
                user: {
                    token: token,
                    companyName: companyName,
                    firstName: firstName,
                    lastName: lastName,
                    isCustomComplete: isCustomizeComplete,
                    isActive: isActive,
                    email: email,
                    isAustralian: isAustralian,
                }
            }, () => {
                // A warning will be displayed whenever the screen is refreshed while a user is
                // logged in and using a device with a smaller than most laptops (1280px).
                if (window.innerWidth < 1280 &&
                    this.state.user !== null
                ) {
                    Modal.open(
                        "Small Device",
                        <div>
                            The DailyFixMe administration page was not developed for a small screen, so
                            we recommend a laptop or desktop be used for the best user experience.
                        </div>
                    );
                }

                let leftNavWidth = 300;
                let width = window.innerWidth - leftNavWidth;
                Toast.init(width, leftNavWidth / 2);
            });
        } else {
            Toast.init();
        }
    }

    loggedInSuccess = (data) => {
        let birthdate = moment(data.birthDate).format("YYYY-MM-DD");
        this.setState({
            user: {
                uuid: data.uuid,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                isCustomComplete: data.isCustomizeComplete,
                token: data.token,
                isActive: data.isActive,
                phoneNumber: data.phoneNumber,
                birthday: birthdate,
                isAustralian: data.isAustralian
            }
        });

        //redirect user to /active page when they successed logged in
        window.location.href = "/active";
        localStorage.setItem(StorageKey.TOKEN, data.token);
        //all these values are stored because they can just access by login route
        //and cannot get by a token value
        localStorage.setItem(StorageKey.FIRST_NAME, data.firstName);
        localStorage.setItem(StorageKey.COMPANY_NAME, data.companyName);
        localStorage.setItem(StorageKey.LAST_NAME, data.lastName);
        localStorage.setItem(StorageKey.IS_CUSTOMIZE_COMPLETE, data.isCustomizeComplete);
        localStorage.setItem(StorageKey.IS_ACTIVE, data.isActive);
        localStorage.setItem(StorageKey.EMAIL, data.email);
        localStorage.setItem(StorageKey.IS_AUSTRALIAN, data.isAustralian);
    };

    loggedOut = () => {
        this.setState({
            user: null
        });

        localStorage.clear();
    };

    completeCustomize = () => {
        this.setState({
            user: {
                ...this.state.user,
                isCustomComplete: true,
            }
        });

        localStorage.setItem(StorageKey.IS_CUSTOMIZE_COMPLETE, true);
    };

    setUserToActive = () => {
        this.setState({
            user: {
                ...this.state.user,
                isActive: true
            }
        });

        localStorage.setItem(StorageKey.IS_ACTIVE, true);
    };

    render() {
        const { user } = this.state;

        return (
            <UserContext.Provider value={user}>
                <GlobalStyle isLoadingFonts={this.state.isLoadingFonts} />
                {user === null ?
                    <Router>
                        <div>
                            <Switch>
                                <Route path="/signUp" render={(props) => (
                                    <SignUpContainer>
                                        <SignUp {...props} signUpComplete={this.loggedInSuccess} />
                                    </SignUpContainer>
                                )} />
                                <Route path="/login" render={(props) => (
                                    <SignUpContainer>
                                        <Login {...props} login={this.loggedInSuccess} />
                                    </SignUpContainer>
                                )} />
                                <Route path="/forgotPassword" render={(props) => (
                                    <SignUpContainer>
                                        <ForgotPassword />
                                    </SignUpContainer>
                                )} />
                                <Route path="/resetPassword:token" render={(props) => (
                                    <SignUpContainer>
                                        <ResetPassword {...props} />
                                    </SignUpContainer>
                                )} />
                                <Route exact path="/">
                                    <Redirect to="/login" />
                                </Route>
                            </Switch>
                        </div>
                    </Router>
                    :
                    <Router>
                        <FlexContainer direction="row" height="auto" minHeight="100%" overflowY="auto">
                            <LeftNav logout={this.loggedOut} />
                            <LoggedInRightContainer>
                                <FlexContainer alignItems="center" justifyContent="center" height="auto" padding="5rem 0 0 0" position="relative">
                                    <Text color="black" margin="0 auto" weight="600">{user.email}</Text>
                                </FlexContainer>
                                <FlexContainer height="auto" flexGrow="1" padding="120px 64px">
                                    <AuthorizedRoute path="/active" component={Subscription} user={user} setUserToActive={this.setUserToActive} />
                                    <AuthorizedRoute path="/customise" component={Customise} user={user} completeCustomize={this.completeCustomize} />
                                    <AuthorizedRoute path="/employList" component={EmployeeList} user={user} isClient={false}/>
                                    <AuthorizedRoute path="/invite" component={EmployeeList} user={user} isClient={true}/>
                                    <AuthorizedRoute path="/noticeboard" component={Noticeboard} user={user}/>
                                    <AuthorizedRoute path="/statistics" component={Statistics} user={user}/>
                                    <AuthorizedRoute path="/setting" component={Setting} user={user} />
                                    <AuthorizedRoute path="/quoteSelection" component={QuoteCategorySelection} isClient={true} user={user} />
                                </FlexContainer>
                                <FlexContainer direction="row" justifyContent="center" height="35px" position="relative" bottom="24px">
                                    <Button marginRight="30px" marginLeft="30px" color="grey" onClick={this.openTermsConditions}>Terms & Conditions</Button>
                                    <Button marginRight="30px" marginLeft="30px" color="grey" onClick={this.openPrivacyPolicy}>Privacy Policy</Button>
                                    <Button marginRight="30px" marginLeft="30px" color="grey" onClick={this.openSupportMail}>Contact Us</Button>
                                </FlexContainer>
                                <FlexContainer direction="row" justifyContent="flex-end" height="35px" position="relative" bottom="24px" right="0px"
                                    style={{ marginRight: "5vw", marginLeft: "1vw"}}>
                                    <input type="image" height="35px" src={FootLogo} alt="Logo" />
                                </FlexContainer>
                            </LoggedInRightContainer>
                        </FlexContainer>
                    </Router>
                }
            </UserContext.Provider>
        );
    }
}

// Add an axios response interceptor. This is similar to middleware.
// We 'intercept' the response object before passing it on to the app
axios.interceptors.response.use(function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
        setTimeout(function() {
            window.location.href = "/login";
            localStorage.clear();
        }, 3000);
        
        // Extract error message sent from the API
        const message = error.response.data["error"];
        return Promise.reject(new Error(message));
    } else {
        return Promise.reject(error);
    }
});

export default hot(App);
