import React, { useState, useContext } from 'react';
import { FormContext } from './SecurityForm';
import styled from "styled-components";
import Text from "components/Text";
import Button from "components/Button";
import FormValidation from '../../../components/FormValidation';

const BorderFormField = styled.input`
    opacity: 0.6;
    width: 100%;
    height: 2.4rem;
    padding: 10px 20px 10px 20px;
    border-radius: 1.2rem;
    border: 1px solid #000000;
    margin: 5px 0;
    box-sizing: border-box;
    font-size: 14px;
    overflow: hidden;
    &:focus {
        background-color: #EFEFEF;
    }
    ::placeholder {
        opacity: initial;
        color: #000000;
        font-weight: 400;
    };
    ::-webkit-inner-spin-button { display: none; }
    ::-webkit-datetime-edit-text { 
        color: #000000; 
        padding: 0 0.3rem; 
    }
    ::-webkit-datetime-edit-month-field { color: #000000; }
    ::-webkit-datetime-edit-day-field { color: #000000; }
    ::-webkit-datetime-edit-year-field { color: #000000; }   
    ::-webkit-calendar-picker-indicator { background: #000000; }
    border: 1px solid #000;
`;

const LeftText = styled(Text)`
    text-align: ${p => p.align || "left"};
    color: #000000;
    font-size: 14px;
`;
const ValidationError = styled(Text)`
    margin: 0 10px;
    color: #FF4B4B;
    font-size: 12px;
    text-align: left;
`;

const EditButton = styled(Button)`
    background: #000000;
    color: #ffffff;
    border-radius: 20px;
    width: 135px;
    height: 2.4rem;
    margin: 5px 0;
`
const SaveButton = styled(Button)`
    background: #51315D;
    color: #ffffff;
    border-radius: 20px;
    width: 135px;
    height: 2.4rem;
    margin: 5px 0;
`;

const DisabledSaveButton = styled(Button)`
    background: #2D3037;
    color: #ffffff;
    border-radius: 20px;
    width: 135px;
    height: 2.4rem;
    margin: 5px 0;
`;

const CancelButton = styled(Button)`
    background: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 20px;
    width: 135px;
    height: 2.4rem;
    margin: 5px 5px;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-temnpate-rows: 100%;
    text-align: ${p => p.align || "left"};
`;

const GridChild = styled.div`
    margin: 0 10px 0 10px;
`;

function FormInputGroup(props) {
  const { label, name, value, type } = props;

  const formContext = useContext(FormContext);
  const { form, handleFormChange, handleEdit, submit } = formContext;
  
  const [editButton, setEditButton] = useState('');
  const [initialValue, setInitialValue] = useState(value);
  const [newInputValue, setNewInputValue] = useState('');
  const [oldInputValue, setOldInputValue] = useState('')
  const [retypedInputValue, setRetypedInputValue] = useState('')
  const [errors, setErrors] = useState({});
  
  const handleCancel = (name, initialValue) => {
    setEditButton('');
    setOldInputValue('');
    setRetypedInputValue('');
    setErrors({})
    handleEdit(name, initialValue);
  }

  const handleSave = (form, name, value) => {
    const validationErrors = {};
    if (name == "password") {
        Object.assign(validationErrors, FormValidation(name, {newInputValue, retypedInputValue}));
    } else {
        Object.assign(validationErrors, FormValidation(name, retypedInputValue));
    }

    if(Object.keys(validationErrors).length == 0) {
        setEditButton('');
        setInitialValue(value);
        handleEdit(name, retypedInputValue);
        if (name == "password") {
            submit(form, name, {oldPassword: oldInputValue, newPassword: retypedInputValue});
        } else {
            submit(form, name, retypedInputValue);
        }
    }
    
    setErrors(validationErrors);
  }
  let placeholder = (type == "password") ? "******" : "email@email.com";

  return (
    <div>
        {(editButton !== name) ?
            <GridContainer>
                <GridChild>
                    <LeftText>{label}</LeftText>
                </GridChild>
                <GridChild>
                    <BorderFormField
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        defaultValue={form[name]}
                        disabled/>
                </GridChild>
                <GridChild>
                    <EditButton onClick={(e) => {setEditButton(name)}}>Change</EditButton>
                </GridChild>
            </GridContainer>
            :
            <>
                {type == "email" ?
                    <>
                    <GridContainer>
                        <GridChild>
                            <LeftText>Current Email</LeftText>
                        </GridChild>
                        <GridChild>
                                <BorderFormField
                                    type={type}
                                    name={"old" + name}
                                    disabled={type == "email" ? "disabled" : ""}
                                    defaultValue={form[name]}/>
                        </GridChild>
                    </GridContainer>
                    <GridContainer>
                        <GridChild>
                            <LeftText>New Email</LeftText>
                        </GridChild>
                        <GridChild>
                            <BorderFormField
                                type={type}
                                name={name}
                                defaultValue={form[name] || ''}
                                onChange={e => setRetypedInputValue(e.target.value)}/>
                        </GridChild>
                    </GridContainer>
                    </>
                :
                    <>
                    <GridContainer>
                        <GridChild>
                            <LeftText>Enter Old Password</LeftText>
                        </GridChild>
                        <GridChild>    
                            <BorderFormField
                                    type={type}
                                    name={"old" + name}
                                    placeholder="******"
                                    defaultValue={form[name]}
                                    onChange={e => setOldInputValue(e.target.value)}/>                            
                        </GridChild>
                    </GridContainer>
                    <GridContainer>
                        <GridChild>
                            <LeftText>Enter New Password</LeftText>
                        </GridChild>
                        <GridChild>
                            <BorderFormField
                                type={type}
                                name={"new" + name}
                                placeholder="******"
                                defaultValue={form[name] || ''}
                                onChange={e => setNewInputValue(e.target.value)}/>
                        </GridChild>
                    </GridContainer>
                    <GridContainer>
                        <GridChild>
                            <LeftText>Retype New Password</LeftText>
                        </GridChild>
                        <GridChild>
                            <BorderFormField
                                type={type}
                                name={name}
                                placeholder="******"
                                defaultValue={form[name] || ''}
                                onChange={e => setRetypedInputValue(e.target.value)}/>
                        </GridChild>
                    </GridContainer>
                    </>
                }
                <GridContainer align="right">
                    <GridChild />
                    <GridChild>
                        {Object.keys(errors).map((key, i) => (<ValidationError key={i}>{errors[key]}</ValidationError>)) }
                    </GridChild>
                </GridContainer>
                <GridContainer align="right">
                    <GridChild />
                    <GridChild>
                        <CancelButton onClick={(e) => {handleCancel(name, initialValue)}}>Cancel</CancelButton>
                        {retypedInputValue.length === 0 ? <DisabledSaveButton disabled>Save Changes</DisabledSaveButton>
                        :
                        <SaveButton onClick={(e) => {handleSave(form, name, retypedInputValue)}}>Save Changes</SaveButton>
                        }
                    </GridChild>
                
                </GridContainer>
            </>
            }
    </div>
  )
}

export default FormInputGroup;