import React, { useState, useRef } from "react";
import Text from "components/Text";
import styled, { css } from "styled-components";

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30% 45% 30%;

    height: 59%;
    width: 38%;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #00000029;
    border-radius: 48px;

    :nth-child(0) {
        grid-column: 1;
        grid-row: 1;
    }

    :nth-child(1) {
        grid-column: 1;
        grid-row: 1;
    }

    :nth-child(2) {
        grid-column: 1;
        grid-row: 1;
    }
`;

const GridBody = styled.div`
    transform: translateX(12%);
    width: 80%;
    ${p => p.borderedTop && css`
        border-top: 1px solid black;
    `}
    ${p => p.borderedBottom && css`
        border-bottom: 1px solid black;
    `}
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;

    transform: translateY(20%);
    height: 100%;
    user-select: none;
    pointer-events: none;

    font-size: 1.8rem;
    font-weight: 500;
    font-family: Affogato;
    letter-spacing: 0.75px;
    color: #23262D;

    :nth-child(0) {
        grid-column: 1;
        grid-row: 1;
    }

    :nth-child(1) {
        grid-column: 1;
        grid-row: 1;
    }
`;

const GenericButtonStyle = css`
    height: 2.5rem;    
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;

    font-family: Affogato;
    letter-spacing: 0.35px;
    line-height: 2.5rem;
    color: white;
    text-align: center;

    user-select: none;
    cursor: pointer;
`;

const FinishButton = styled.div`
    width: 212px;
    display: inline-block;
    ${GenericButtonStyle}
    background: #FF3E3E 0% 0% no-repeat padding-box;
    font-weight: 400;
    color: #FFFFFF;
`;

const CloseButton = styled.div`
    width: 9.375rem;
    display: inline-block;
    ${GenericButtonStyle}
    background: #51315D 0% 0% no-repeat padding-box;
`;

const OuterFlexBox = styled.div`
    display: flex;
    flex-direction: column;

    min-width: 100%;
    min-height: 100%;
`;

const InnerFlexBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: ${p => p.alignItems || 'start'};
    margin-top: 30px;
`;

function CancellationModal(props) {
    const { closeCallback, confirmCallback, handleShowCancellationTermsModal, companyDetails } = props;    
    const subscriptionEnd = new Date(companyDetails.subscriptionEnd)
    
    const formatedSubscriptionEnd = new Intl.DateTimeFormat("default", { dateStyle: 'full' }).format(subscriptionEnd);

    return(
        <Container>
            <GridBody>
                <Header>
                    <Text align="left" color="#000000" size="30px">{companyDetails.companyName}, are you sure you want to end your subscription?</Text>
                </Header>
            </GridBody>
            <GridBody bordered style={{ minHeight: "100%", height: "100%", marginBottom: "20px" }}>
                <OuterFlexBox style={{ minHeight: "100%", height: "100%", borderBottom: "1px solid #000" }}>
                    <Text align="left" color="#000000">
                        Click “Confirm Cancellation” below to proceed with canceling your business subscription with Daily Fix Me.
                    </Text>
                    <Text align="left" color="#000000">
                        - Cancellation will be effective at the end of your current billing period on <b>{formatedSubscriptionEnd}</b>.
                    </Text>
                    <Text align="left" color="#000000">
                        - Restart your subscription any time - your account details will still be available to you.
                    </Text>
                    <Text align="left" color="#51315D" weight="600" style={{ cursor: "pointer"}} onClick={() => handleShowCancellationTermsModal()}>
                        *Subscription & Cancellation Terms
                    </Text>
                </OuterFlexBox>
            </GridBody>
            <GridBody>
                <OuterFlexBox>
                    <InnerFlexBox alignItems="end">
                        <div style={{ display: "flex", flexDirection: "row", alignItems:"start"}}>
                        <CloseButton onClick={() => closeCallback()}>Nevermind</CloseButton>
                        <FinishButton style={{marginLeft: "20px"}} onClick={() => confirmCallback()}>Confirm Cancellation</FinishButton>
                        </div>
                    </InnerFlexBox>
                </OuterFlexBox>
            </GridBody>
        </Container>
    );
}

export default CancellationModal;