import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "App";
import styled, { css } from "styled-components";
import Button from "components/Button";
import Text from "components/Text";
import FlexContainer from "components/FlexContainer";
import LoadingIndicator from "LoadingIndicator";

const DateRange = {
    ALL_TIME: 0,
    LAST_30_DAYS: 1,
    LAST_7_DAYS: 2
};

const tablet = (...args) => css`
    @media (min-width: 600px) {
        ${css(...args)}
    }
`;

const desktop = (...args) => css`
    @media (min-width: 1280px) {
        ${css(...args)}
    }
`;

const Hr = styled.hr`
    margin: 0 64px;
`;

const BreakpointContainer = styled(FlexContainer)`
    width: 100%;
    margin: 0 auto;

    ${tablet`
        width: 80%;
    `}
    ${desktop`
        width: 65%;
    `}
`;

const VerticalDivider = styled.div`
    border: .5px #9a9a9a solid;
    transform: translateY(-2px);
`;

const Table = styled.table`
    border-collapse: collapse;
    margin: 0 auto;
    width: calc(100% - 64px);
`;

const Tr = styled.tr`
    :nth-child(even) {
        background-color: transparent;
    }
    :nth-child(odd) {
        background-color: #f3f3f4;
    }
`;

const Td = styled.td`
    padding: 16px;
    font-size: 20px;
`;

const DropDown = styled.div`
    position: relative;
    display: inline-block;
    margin: 0 auto 72px auto;
    width: 315px;
    
    :hover div {
        display: block;
    }
`;

const DropDownContent = styled.div`
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 20px;
    
    button {
        color: black;
        padding: 12px 24px;
        text-decoration: none;
        display: block;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 500;
        
        :hover {
            background-color: #f1f1f1
        }
    }
`;

const DropDownContentButton = styled(Button)`
    width: 100%;
`;

const DropDownButton = styled.button`
    background-color: transparent;
    color: black;
    font-size: 14px;
    border: 2px black solid;
    cursor: pointer;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    
    :hover {
        background-color: #f3f3f4;
    } 
`;

class Statistics extends Component {
    state = {
        fetching: 0,
        dateRange: DateRange.LAST_30_DAYS,
        stats: {}
    };

    dateRangeString = (range) => {
        switch (range) {
        case DateRange.LAST_30_DAYS:
            return "Last 30 days";
        case DateRange.LAST_7_DAYS:
            return "Last 7 days";
        case DateRange.ALL_TIME:
            return "All time";
        default:
            return "N/A";
        }
    };

    setRange = (range) => {
        if (this.state.fetching === 0) {
            this.setState({
                dateRange: range
            }, () => this.fetchDateRange());
        }
    };

    componentDidMount() {
        this.fetchDateRange();
    }

    fetchDateRange = () => {
        this.setState({
            fetching: 1
        });

        axios.post(`${API_URL}/company/statistics`, {
            dateRange: this.state.dateRange,
        }, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            if (res.status >= 200 && res.status <= 299) {
                this.setState({
                    fetching: 0,
                    stats: res.data
                });
            }
        }).catch(error => {
            console.error(error);
            this.setState({
                fetching: 0
            });
        });
    };

    statsData = (category, field) => {
        if (this.state.stats !== null &&
            this.state.stats[category] != null &&
            this.state.stats[category][field] != null) {
            return this.state.stats[category][field];
        }

        return "N/A";
    };

    render() {
        const { fetching, dateRange } = this.state;

        if (fetching > 0) {
            return (
                <LoadingIndicator/>
            );
        }

        return (
            <BreakpointContainer>
                <Text size="60px" color="#23262D" margin="72px auto 50px auto">
                    Statistics
                </Text>
                <DropDown>
                    <DropDownButton >
                        <table style={{ height: "inherit", width: "inherit", transform: "translate(-4px, -3px)" }}>
                            <tbody>
                                <tr>
                                    <td style={{ fontFamily: "Affogato", textAlign: "left", fontWeight: "400", transform: "translateX(16px)" }}>Date Range</td>
                                    <td style={{ fontFamily: "Affogato", textAlign: "right", fontWeight: "500" }}>{ this.dateRangeString(dateRange) }</td>
                                    <td style={{ right: "0", transform: "scaleX(1.5)" }}>▼</td>
                                </tr>
                            </tbody>
                        </table>
                    </DropDownButton>
                    <DropDownContent>
                        <DropDownContentButton onClick={() => this.setRange(DateRange.LAST_7_DAYS)}>Last 7 days</DropDownContentButton>
                        <DropDownContentButton onClick={() => this.setRange(DateRange.LAST_30_DAYS)}>Last 30 days</DropDownContentButton>
                        <DropDownContentButton onClick={() => this.setRange(DateRange.ALL_TIME)}>All Time</DropDownContentButton>
                    </DropDownContent>
                </DropDown>

                <Hr/>
                <FlexContainer justifyContent="center" direction="row">
                    <FlexContainer alignItems="center" justifyContent="center" height="auto" padding="16px" position="relative" width="50%">
                        <Text size="30px" color="#23262D" margin="30px auto 36px auto" weight="500">
                            Employees
                        </Text>
                        <Table>
                            <Tr>
                                <Td style={{ fontWeight: "500" }}>Total Employees</Td>
                                <Td style={{ fontWeight: "400", textAlign: "right" }}>{ this.statsData("employee", "total") }</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ fontWeight: "500" }}>Active Employees</Td>
                                <Td style={{ fontWeight: "400", textAlign: "right" }}>{ this.statsData("employee", "activityCount") }</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ fontWeight: "500" }}>Times Opened</Td>
                                <Td style={{ fontWeight: "400", textAlign: "right" }}>{ this.statsData("employee", "avgOpenRate") }</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ fontWeight: "500" }}>Average Time Spent</Td>
                                <Td style={{ fontWeight: "400", textAlign: "right" }}>{ this.statsData("employee", "avgTimeSpent") }</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ fontWeight: "500" }}>Noticeboard Read Messages</Td>
                                <Td style={{ fontWeight: "400", textAlign: "right" }}>{ this.statsData("employee", "noticesRead") }</Td>
                            </Tr>
                        </Table>
                    </FlexContainer>

                    <VerticalDivider/>

                    <FlexContainer height="auto" padding="16px" position="relative" width="50%">
                        <Text size="30px" color="#23262D" margin="30px auto 36px auto" weight="500">
                            Clients
                        </Text>
                        <Table>
                            <Tr>
                                <Td style={{ fontWeight: "500" }}>Total Clients</Td>
                                <Td style={{ fontWeight: "400", textAlign: "right" }}>{ this.statsData("client", "total") }</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ fontWeight: "500" }}>Active Clients</Td>
                                <Td style={{ fontWeight: "400", textAlign: "right" }}>{ this.statsData("client", "activityCount") }</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ fontWeight: "500" }}>Brand Impressions</Td>
                                <Td style={{ fontWeight: "400", textAlign: "right" }}>{ this.statsData("client", "avgOpenRate") }</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ fontWeight: "500" }}>Average Time Spent</Td>
                                <Td style={{ fontWeight: "400", textAlign: "right" }}>{ this.statsData("client", "avgTimeSpent") }</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ fontWeight: "500" }}>Noticeboard Read Messages</Td>
                                <Td style={{ fontWeight: "400", textAlign: "right" }}>{ this.statsData("client", "noticesRead") }</Td>
                            </Tr>
                        </Table>
                    </FlexContainer>
                </FlexContainer>

            </BreakpointContainer>
        );
    }
}

export default Statistics;