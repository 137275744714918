import React from "react";
import styled from "styled-components";
import Button from "components/Button";

const StyledButton = styled(Button)`
    padding: ${p => p.padding || "5px 10px"};
    width: ${p => p.width || "160px"};
    background-color: ${p => p.color || "#2D3037"};
    box-shadow: 0px 3px 6px #00000029;
    color: ${p => p.textColor || "#F4F4F4"};
    border-radius: 20px;    
    margin-left: ${p => p.left || "20px" };   
    height: ${ p => p.height || "40px"};
    margin-top: ${p => p.marginTop};
`;

function ProceedButton(props) {
    const { children, ...styleProps } = props;

    return (
        <StyledButton {...styleProps}>
            {children}
        </StyledButton>
    );
}

export default ProceedButton;