import React, { Component } from "react";
import styled from "styled-components";
import FlexContainer from "components/FlexContainer";
import Text from "components/Text";
import LeftText from "../components/LeftText";
import ProceedButton from "../components/ProceedButton";
import { SubscriptionContext } from "../SubscriptionContext";
import { LineContainer } from "../components/Price";
import DifferentFonts from "components/DifferentFonts";
import LoadingIndicator from "LoadingIndicator";
import moment from "moment";

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin: auto 64px;
`;

const Hr = styled.hr`
    margin: 0 64px;
`;

const SideContainer = styled(FlexContainer)`
    /* min-width: 30%; */
    /* flex-shrink: 0; */
    /* align-items: ${p => p.align || "flex-end"}; */
`;

const Span = styled.span`
    font-weight: 500;
`;

const FailingPayments = styled.span`
    color: red;
    font-size: 20px;
    font-weight: 500;
`;

const FailingPaymentsLabel = styled.span`
    color: red;
    font-weight: 500;    
`;

function VerticalDifferentFonts(props) {
    const { mediaText, regularText, regularTextWidth, color, width } = props;
    return (
        <FlexContainer direction="column" padding="0" width={width} margin="0 15px 0 0">
            <Text color={color} size="50px" weight="600" margin="0">
                {mediaText}
            </Text>
            <Text color={color} size="14px" weigth="600" margin="0" width={regularTextWidth}>
                {regularText}
            </Text>
        </FlexContainer>
    );
}

class Active extends Component {
    static contextType = SubscriptionContext;

    render() {
        const { isActive, companyName, subscriptionPeriod, remainDays, subscriptionStartDate, subscriptionEndDate, loadingStatus,
            totalNumber, employees, clients, nextClick, alterSubscription, editPaymentMethod, isCancelled, isPaymentFailing,
            resultMessage, hideResult } = this.context;

        const diffDays = moment(subscriptionEndDate).diff(moment(subscriptionStartDate), "days");

        return (
            <FlexContainer width="100%">
                <Text size="40px" color="#23262D" margin="0">
                    Active Subscription
                </Text>
                <Container>
                    <SideContainer>
                        <FlexContainer alignItems="start" flexGrow="1">
                            <DifferentFonts
                                size="30px"
                                mediumText="Subscription:"
                                color="#000000"
                            />
                            <LeftText margin="10px 0 0 0" fontWeight="600"
                                size="15px">
                                { companyName.length > 50 ?
                                    companyName.substring(0, 49) + "..."
                                    :
                                    companyName
                                }
                            </LeftText>
                            <DifferentFonts
                                size="15px"
                                marginTop="10px"
                                mediumText="Subscription Status:"
                                color="#A7CF5B"
                                regularText={ isActive ? "Active" : "Inactive" }
                            />
                            <DifferentFonts
                                marginTop="10px" 
                                size="15px"
                                mediumText="Start Date:"
                                color="#000000"
                                regularText={subscriptionStartDate}
                            />
                            <DifferentFonts
                                marginTop="10px" 
                                size="15px"
                                mediumText="Finish Date:"
                                color="#000000"
                                regularText={subscriptionEndDate}
                            />
                            <LeftText margin="10px 0 0 0" fontWeight="600" size="15px">
                                Expires in {remainDays ? remainDays : diffDays + " days"}
                            </LeftText>
                            {!hideResult && 
                                <Text color="grey" size="20px">
                                    {resultMessage}
                                </Text>
                            }
                        </FlexContainer>
                    </SideContainer>
                    <Hr />
                    <SideContainer>
                        <FlexContainer alignItems="start" flexGrow="1" justifyContent="space-between">
                            <Text color="#23262D" size="30px" weight="500" margin="0">
                                My User Status:
                            </Text>
                            <FlexContainer direction="row" alignItems="start" margin="30px 0 60px 0">
                                <VerticalDifferentFonts
                                    color="#000000"
                                    mediaText={totalNumber}
                                    regularText="Total Users Purchased"
                                    regularTextWidth="80px"
                                />
                                <VerticalDifferentFonts
                                    color="#A0A0A0"
                                    mediaText={employees}
                                    regularText={"Employees"}
                                    regularTextWidth="80px"
                                />
                                <VerticalDifferentFonts
                                    color="#A0A0A0"
                                    mediaText={clients}
                                    regularText={"Clients"}
                                    regularTextWidth="80px"
                                />
                                <VerticalDifferentFonts
                                    color="#51315D"
                                    mediaText={ totalNumber - employees - clients }
                                    regularText="Total Remaining Users"
                                    regularTextWidth="110px"
                                />
                            </FlexContainer>
                            <ProceedButton color="#51315D" left="0" margin="0" onClick={nextClick}>
                                Purchase More Users
                            </ProceedButton>
                        </FlexContainer>
                    </SideContainer>
                    {/* <SideContainer align="flex-start">
                        {
                            isPaymentFailing ?
                                <div>
                                    <FailingPayments>
                                        *We are unable to process your payment.
                                    </FailingPayments>
                                    <br/>
                                    <FailingPaymentsLabel>
                                        Your account remains active, but please update your payment method
                                        information to avoid suspension.
                                    </FailingPaymentsLabel>
                                </div>
                                : ""
                        }
                        {
                            isPaymentFailing ?
                                <ProceedButton left="0" onClick={editPaymentMethod} textColor="#2D3037"
                                    marginTop="auto" color="white" style={{ border: "1px solid red" }}>
                                    <span style={{ color: "red" }}>Edit Payment Method</span>
                                </ProceedButton>
                                :
                                <ProceedButton left="0" onClick={editPaymentMethod} textColor="#2D3037"
                                    marginTop="auto" color="white" style={{ border: "1px solid black" }}>
                                        Edit Payment Method
                                </ProceedButton>
                        }
                    </SideContainer> */}
                </Container>
            </FlexContainer>
        );
    }
}

export default Active;