import React, { Component } from "react";
import styled from "styled-components";
import Correct from "img/Correct.svg";
import Text from "components/Text";
import FlexContainer from "components/FlexContainer";
import ProceedButton from "../components/ProceedButton";
import { SubscriptionContext } from "../SubscriptionContext";
import { Forms, PRICE_PER_USER } from "../Subscription";
import NextButton from "components/NextButton";
import NextBlack from "img/NextBlack.svg";
import DifferentFonts from "components/DifferentFonts";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin: 64px;
`;

class Success extends Component {
    static contextType = SubscriptionContext;

    componentDidMount() {
        const GST = this.context.isAustralian ? 1.1 : 1.0;
        let userCost = Math.abs(PRICE_PER_USER * this.context.totalNumber * 12);
        let finalCost = (userCost * GST).toFixed(2);

        if (this.context.currentForm === Forms.SUCCESS) {
            // Facebook Pixel Tracking
            window.fbq && window.fbq("track", "Purchase", {
                value: finalCost,
                currency: "AUD"
            });
            window.fbq && window.fbq("track", "AddPaymentInfo");
        }
    }

    render() {
        const { companyName, subscriptionPeriod, totalNumber,
            currentForm, nextClick, remainDays } = this.context;

        return (
            <Container>
                <img src={Correct} alt="success" height="95px" />
                <Text color="#23262D" size="40px">
                    {currentForm === Forms.SUCCESS ?
                        "Business Subscription" : "User Purchase"} <br/>
                    Successful!
                </Text>
                { currentForm === Forms.SUCCESS &&
                    <FlexContainer alignItems="center">
                        <DifferentFonts
                            marginTop="10px"
                            size="14px"
                            mediumText="Business Name:"
                            color="#23262D"
                            regularText={companyName}
                            self="center"
                        />
                        <DifferentFonts
                            marginTop="10px"
                            size="14px"
                            mediumText="Selected Subscription:"
                            color="#23262D"
                            regularText="Annual Plan, Prepaid"
                            self="center"
                        />
                        <DifferentFonts
                            marginTop="10px"
                            size="14px"
                            mediumText="Subscription Duration:"
                            color="#23262D"
                            regularText={subscriptionPeriod + " Months" }
                            self="center"
                        />
                        <DifferentFonts
                            marginTop="10px"
                            size="14px"
                            mediumText="Number of Users:"
                            color="#23262D"
                            regularText={totalNumber}
                            self="center"
                        />
                        <ProceedButton color="#51315D" width="auto" padding="10px 40px" onClick={nextClick}
                            marginTop="60px" left="0">
                            Finish
                        </ProceedButton>
                    </FlexContainer>
                }
                {
                    currentForm === Forms.PAYMENT_SUCCESS &&
                    <FlexContainer alignItems="center">
                        <DifferentFonts
                            marginTop="10px"
                            size="14px"
                            mediumText="Selected Subscription:"
                            color="#23262D"
                            regularText="Business"
                            self="center"
                        />
                        <DifferentFonts
                            marginTop="10px"
                            size="14px"
                            mediumText="Business Name:"
                            color="#23262D"
                            regularText={companyName}
                            self="center"
                        />
                        <DifferentFonts
                            marginTop="10px"
                            size="14px"
                            mediumText="Days Remaining:"
                            color="#23262D"
                            regularText={remainDays}
                            self="center"
                        />
                        <DifferentFonts
                            marginTop="10px"
                            size="14px"
                            mediumText="Total Users:"
                            color="#23262D"
                            regularText={totalNumber}
                            self="center"
                        />
                        <ProceedButton color="#51315D" width="auto" padding="10px 40px" onClick={nextClick}
                            marginTop="60px" left="0">
                            OK
                        </ProceedButton>
                    </FlexContainer>
                }
            </Container>
        );
    }
}

export default Success;