import React, { Component } from "react";
import Text from "components/Text";
import FlexContainer from "components/FlexContainer";
import ProceedButton from "../../Subscription/components//ProceedButton";
import { CustomiseContext } from "../CustomiseContext";

class Email extends Component {
    static contextType = CustomiseContext;

    render() {
        const { email, nextClick } = this.context;
    
        return (
            <FlexContainer alignItems="center" justifyContent="center" margin="auto">
                <Text color="#23262D" size="60px" weight="500">
                    Get The App
                </Text>
                <Text color="#23262D" size="14px" lineHeight="22px" width="500px" align="center">
                    A unique code has been sent to <b>{email}</b> and should arrive momentarily. 
                    Download the Me App from the App Store or Google Play and enter this 
                    code to start using the app. 
                </Text>
                <Text color="#23262D" size="14px" lineHeight="22px" width="500px" align="center">
                    If you cannot find your confirmation email, please check your spam or junk folder.
                </Text>
                <ProceedButton width="auto" onClick={nextClick} padding="10px 30px" marginTop="50px" left="0">
                    Done
                </ProceedButton>
            </FlexContainer>
        );
    }
}

export default Email;