import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "App";
import styled, { css } from "styled-components";
import Button from "components/Button";
import Text from "components/Text";
import FlexContainer from "components/FlexContainer";
import DifferentFonts from "components/DifferentFonts";
import LoadingIndicator from "LoadingIndicator";
import ArrowDown from "img/ArrowDown.svg";
import ArrowUp from "img/ArrowDown.svg";
import moment from "moment";
import Toast from "Toast";
import Modal from "Modal";
import Backdrop from "Backdrop";
import DropZone from "react-dropzone";
import { NoticeModalContext } from "./NoticeModalContext";
import { Forms } from "Customise/Customise";
import $ from "jquery";

import "./NoticeboardStyling.css";

const HeaderContainer = styled.div`
    display: relative;
`;

const NewPostButton = styled(Button)`
    position: absolute;
    right: 0;
    bottom: 24px;
    top: 80px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    border: 1px solid #2e3036;
    border-radius: 20px;
    line-height: 16px;
    height: 40px;
    width: 204px;
    color: black;
    background-color: transparent;
    cursor: pointer;
    font-family: Arial;
    font-size: 14px;
`;

const ScrollContainer = styled.div`
    flex: 1;
    overflow: auto;
    display: relative;
    max-height: 50vh;
`;

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const Th = styled.th`
    background-color: #000000;
    color: white;
    border-left: 1px solid white;
    padding: 22px 10px;
    font-size: 13px;
    font-weight: 100;
    width: ${p => p.width || "5rem"};
    max-width: ${p => p.width || "5rem"};
`;

const Tr = styled.tr`
    :nth-child(even) {
        background-color: white;
    }
    :nth-child(odd) {
        background-color: #f3f3f4;
    }
`;

const Td = styled.td`
    border-right: ${p => p.borderRight || "1px solid #2e3036"};
    padding: 0px;
    width: ${p => p.width || "5rem"};
    max-width: ${p => p.width || "5rem"};
    height: ${p => p.height || "5rem"};
    max-height: ${p => p.height || "5rem"};
    letter-spacing: .4px;
`;

const ColumnHeader = (({ heading, sortDirection }) => {
    const Container = styled.div`
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-weight: 400;
        text-align: center;
        user-select: none;
        cursor: pointer;
    `;

    const SortIndicator = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding-left: 8px;
    `;

    return (
        <Container>
            {heading}
            <SortIndicator hidden={sortDirection === SortableColumnState.INACTIVE}>
                {(sortDirection === SortableColumnState.DESCENDING) &&
                <img width="10px" src={ArrowDown} alt="SortArrow"/>}
                {(sortDirection === SortableColumnState.ASCENDING) &&
                <img width="10px" src={ArrowUp} alt="SortArrow" style={{ transform: "rotate(180deg)" }} />}
            </SortIndicator>
        </Container>
    );
});

const DropDown = styled.div`
    position: absolute;
    transform: translateY(calc(4.5rem - 40px));

    display: inline-block;
    margin: ${p => p.margin || "0 auto 72px auto;"}
    width: 204px;

    :hover div {
        display: block;
        transform: translateY(calc(-4rem));
    }
`;

const DropDownContent = styled.div`
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 20px;

    button {
        color: black;
        padding: 12px 24px;
        text-decoration: none;
        display: block;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 500;

        :hover {
            background-color: #f1f1f1
        }
    }
`;

const DropDownButton = styled.button`
    background-color: #51315D;
    color: #FFFFFF;
    font-size: 14px;
    border: 0;
    cursor: pointer;
    width: 100%;
    height: 40px;
    border-radius: 20px;
`;

const SendingToButton = styled.button`
    position: relative;

    display: inline-block;
    margin: 0 auto 72px auto;
    width: 204px;
    
    right: 0;
    bottom: 64px;
    
    background-color: #FFFFFF

    color: black;
    font-size: 14px;
    border: 0;
    cursor: pointer;
    width: 204px;
    height: 40px;
    border-radius: 20px;
    margin: 0px;
    padding: 0px;
    margin-top: 30%;
`;

/* Bottom drop down button (in the modal*/
const DropDownContentButton = styled(Button)`
    width: 100%;
`;

/* This is for the top drop down Employee/Client button */
const DropDownButtonTop = styled(Button)`
    width: 100%;
    position: relative;
    top: 0px;
`;

/* This is for the top drop down Employee/Client button */
const DropDownTop = styled.div`
    position: absolute;
    transform: translateY(calc(4.5rem - 40px));
    display: inline-block;
    margin: 0 auto 72px auto;
    width: 204px;
    :hover div {
        display: block;
        transform: translateY(calc(0rem));
    }
`;

const DropDownContentTop = styled.div`
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 20px;

    button {
        color: black;
        padding: 12px 24px;
        text-decoration: none;
        display: block;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 500;

        :hover {
            background-color: #f1f1f1;
        }
    }
`;

const GridHeader = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 25% 30% 25%;
    grid-template-rows: 4.5rem;
    padding: 3rem 0rem 0rem 0rem;
    margin-bottom: 25px;
`;

const GridHeaderChild = styled.div`
    :nth-child(1) {
        grid-column: 1;
        grid-row: 1;
    }

    :nth-child(2) {
        text-align: center;
        grid-column: 2;
        grid-row: 1;
    }

    :nth-child(3) {
        grid-column: 3;
        grid-row: 1;
    }
`;

const DeleteButton = styled.div`
    height: 50%;
    width: 50%;
    background: #2D3037 0% 0% no-repeat padding-box;
    border-radius: 20px;
    
    
    display: block;
    
    user-select: none;
    cursor: pointer;
`;

const ModalContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100vw - 424px);
    height: calc(100vh - 256px);

    z-index: 500;
`;

class NoticeModal extends Component {
    state = {
        /* file list */
        file: [],
        mostRecentFile: null,
        assetUrl: "",
        isUploading: false,
        timestamp: "",
        errorMessage: "",
        sendingToClient: this.props.sendingToClient,
        isSending: false,
        fileExtension: null,
        /* variables for the video */
        videoSourceBlob: null,
        videoSourceType: null,
        /* variables for the image */
        imageSource: null,
        imageSourcePreview: null,
        /* number of files uploaded */
        numberOfFiles: 0,
        fileTooLarge: false,
        /* s3 parameters */
        uuid: null,
        fileType: null,
        fileUrl: null,
        postType: null,
        s3UploadUrl: null,
        s3UploadBucket: null,
        imageKey: null,
        // send button visibility
        latestFile: null,
        // widescreen or square
        aspectRatioString: null
    }

    Container = styled.div`
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 20% 40% 25% 25%;
        
        height: 90%;
        width: 90%;
        
        margin: 5%;

        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 30px #00000029;
        border-radius: 69px;

        :nth-child(0) {
            grid-column: 1;
            grid-row: 1;
        }

        :nth-child(1) {
            grid-column: 2;
            grid-row: 1;
        }

        :nth-child(2) {
            grid-column: 3;
            grid-row: 1;
        }
    `;

    GridBody = styled.div`
        transform: translateX(8.235%);
        width: 85%;
        ${p => p.borderedTop && css`
            border-top: 1px solid black;
        `}
        ${p => p.borderedBottom && css`
            border-bottom: 1px solid black;
        `}
    `;

    GenericInputStyling = css`
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #2D3037;
        border-radius: 27px;
        min-height: 3.1rem;

        text-align: left;
        font-size: 1.25rem;
        font-weight: 400;
        font-family: Affogato;
        letter-spacing: 0.5px;
        color: #23262D;

        outline: none;

        ::placeholder {
            color: #757575;
        }

        ${p => p.first && css`
            margin: 1.5rem 0 0.5rem 0;
        `}

        ${p => p.last && css`
            margin: 0.5rem 0 1.5rem 0;
        `}
    `;

    InputBody = styled.input`
        ${this.GenericInputStyling}

        padding: 0 1.5rem;
        width: calc(100% - 3rem);

        maxlength: 255;
    `;

    TextAreaBody = styled.textarea`
        ${this.GenericInputStyling}

        padding: 1rem 1.5rem;
        max-width: calc(100% - 3rem);
        min-width: calc(100% - 3rem);
        min-height: 12rem;
        max-height: 12rem;
        

        resize: none;
        maxlength: 1000;
    `;

    Header = styled.div`
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 100%;

        transform: translateY(35%);
        height: 100%;
        user-select: none;
        pointer-events: none;

        font-size: 1.8rem;
        font-weight: 500;
        font-family: Affogato;
        letter-spacing: 0.75px;
        color: #23262D;

        :nth-child(0) {
            grid-column: 1;
            grid-row: 1;
        }

        :nth-child(1) {
            grid-column: 2;
            grid-row: 1;
        }
    `;

    ButtonContainer = styled.div`
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 100%;
    `;

    DropDownLabel = styled.div`
        margin-top: 2rem;
        margin-bottom: -1rem;
        font-size: 1.2rem;
        font-weight: 500;
        font-family: Affogato;
        letter-spacing: 0.5px;
        color: #23262D;
    `;

    GenericButtonStyle = css`
        height: 2.5rem;
        width: 9.375rem;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;

        font-family: Affogato;
        letter-spacing: 0.35px;
        line-height: 2.5rem;
        color: white;
        text-align: center;

        user-select: none;
        cursor: pointer;
    `;

    SendButton = styled.div`
        display: inline-block;
        ${this.GenericButtonStyle}
        background: #006CFF 0% 0% no-repeat padding-box;
        font-weight: 400;
    `;

    CloseButton = styled.div`
        display: inline-block;
        ${this.GenericButtonStyle}
        background: #2D3037 0% 0% no-repeat padding-box;
    `;

    UploadContentBox = styled.div`
        background: #FFFFFF 0% 0% no-repeat padding-box;
    
        font-family: Affogato, Medium;
        letter-spacing: 0.35px;
        color: black;
        text-align: center;
        vertical-align: center;
        horizontal-align: center;
        

        user-select: none;
        cursor: pointer;
        border: 1px black solid;
        
        border-radius: 20px;
        
        width: 100%;
        height: 100%;
    `

    UploadContentButtonStyle = css`
        height: 2.5rem;
        border-radius: 20px;

        font-family: Affogato;
        letter-spacing: 0.35px;
        line-height: 2.5rem;
        color: black;
        text-align: center;

        user-select: none;
        cursor: pointer;
        border: 1px black solid;
    `;

    UploadContentButton = styled.div`
        ${this.UploadContentButtonStyle}
        font-family: Affogato, Medium;
        background: #000000;
        border-radius: 20px;
        color: #FFFFFF;
    `;

    OuterFlexBox = styled.div`
        display: flex;
        flex-direction: row;
        
        min-width: 100%;
        min-height: 100%;
    `;

    InnerFlexBox = styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: ${p => p.alignItems || 'start'};
    `

    InnerFlexBoxSepartor = styled.div`
        height:2px;
        border-bottom: 1px solid #000000;
        flex-direction: column;
        transform: translateY(calc(6rem))
    `

    DropZoneBrowseFlexBox = styled.div`
        padding-top: 24px;
        padding-right 0px;
        padding-left 24px;
        display: flex;
        flex-direction: column;
        align-items: right;
        min-width: 52%;
        max-width: 70%;
        min-height: 50%;
        max-height: 100%;
        
     
       
        z-index: 1;
    `;

    SubjectMessageFlexBox = styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 1;
        height: 100%;
    `;

    setFileStates = (name, value) => {
        this.setState({
            ...this.state,
            [name]: value,
        });
    };

    // Uploads a file to S3 by first generating an S3 Url,
    // and then uploading to that
    // also stores the s3 key in this.state.s3UploadUrl
    uploadToS3 = (postFileType, droppedFiles, fileToUpload) => {
        // get the S3 pre-signed url for video upload
        // create a shell
        axios.post(`${API_URL}/company/noticeboard/${this.state.sendingToClient ? "clients" : "employees"}`, { postType: postFileType }, {
            headers: {
                Authorization: "Bearer " + this.props.user.token,
            }
        })
            .then(response => {
                // handle success
                const s3UploadBucket = response.data.s3PresignedUrl.substring(0, response.data.s3PresignedUrl.indexOf("?"));

                // first set preview of of the updated file to the props
                this.setFileStates("file", droppedFiles.map(oneFile => ({
                    ...oneFile,
                    preview: URL.createObjectURL(oneFile)
                })));

                //  actually upload the file
                axios.put(response.data.s3PresignedUrl, fileToUpload)
                    .then(result => {
                        if (postFileType === "image") {
                            this.setState({ imageKey: response.data.notice.imageKey });
                        }

                        // once the upload is complete we want the
                        // send button to be visible so it can be
                        // sent to the API
                        const visibilityAndRenderCallback = () => {
                            this.props.parentObject.setSendButtonVisible(this.props.parentObject, true);
                            this.forceUpdate();
                        };

                        this.setState({ s3UploadBucket: s3UploadBucket,
                            s3UploadUrl: response.data.s3PresignedUrl,
                            uuid: response.data.notice.uuid }, visibilityAndRenderCallback
                        );
                    }).catch(err => {
                        this.setState({ s3UploadBucket: null, s3UploadUrl: null, uuid: null });

                        // s3 upload failed, send button still available.
                        this.props.setSendButtonVisible(true);
                        this.props.parentObject.setSendButtonVisible(true);
                        console.log("Error in sending file to S3: " + err);
                        return Toast.error(err.message);
                    });
            }).catch(function(error) {
                // s3 upload failed, send button still available.
                // this.props.setSendButtonVisible(this.props.parentObject, true);
                // handle error
                console.log("Error receiving S3 key : " + error);
            });
    }

    /**
     * File dropped into logo drop zone, validate file type and upload to S3
     * @param droppedFiles {Array<File>}
     */
    onDrop = (droppedFiles) => {
        // If the drop zone receives anything other than the accepted formats, or anything greater
        // than the max file size specified, the droppedFiles array will be empty.
        // however, the max file size is dependent on the file type, and so this will only filter out
        // files which are not videos or images, we still have to check later for the right file size
        // for the type of file
        if (droppedFiles.length === 0) {
            this.setState({
                errorMessage: "Your upload must be an image or a video, and must be less than 4MB for an image"
            });
            return;
        }

        // reset the aspect ratio
        // in case it has been calculated
        // for a previously uploaded file
        this.setState({ videoAspectRatio: null });

        // set the latest file
        this.setState({ latestFile: droppedFiles[0] });

        // now that we are uploading a file, we have to ensure the send button is invisible
        // for the duration of upload
        this.props.parentObject.setSendButtonVisible(false);

        // Take latest file and verify it
        let fileToUpload = droppedFiles[0];

        // Get the file extension
        let fileExtension = fileToUpload.name.split(".").pop();
        // Set the file extension
        this.setState({
            fileExtension: fileExtension.toString()
        });

        let postType = "";

        // guarantee that it is a video or an image
        if (fileToUpload.type.startsWith("image")) {
            postType = "image";
        } else if (fileToUpload.type.startsWith("video")) {
            postType = "video";
            // get the video dimensions
            // set the aspect ratio string in the state
            this.getVideoDimensions(droppedFiles[0]);
        } else {
            // invalid file type
            Toast.error("Not a valid file type: " + fileToUpload.type + ". Only images and videos are accepted");
            return;
        }

        // handle file size and set the source if it's a video
        if (postType === "image") {
            if (fileToUpload.size > maxImageSize) {
                this.setState({
                    errorMessage: "File must be less than 4MB for an image",
                    fileTooLarge: true,
                });
                this.props.parentObject.setSendButtonVisible(true);
                return Toast.error("File must be less than 4MB for an image");
            }

            // set file states first so that we don't get an error
            // when referencing file[numberOfFiles] in render...
            this.setFileStates("file", droppedFiles.map(oneFile => ({
                ...oneFile,
                preview: URL.createObjectURL(oneFile)
            })));

            const imageSourceUrl = URL.createObjectURL(fileToUpload);

            // now that the file is verified, we can add to the number of files uploaded
            this.setState({ numberOfFiles: this.state.file.length,
                imageSourcePreview: imageSourceUrl.preview,
                fileTooLarge: false, postType: "image"
            });

            // this update should occur last because otherwise it may render before
            // the image source has been set
        } else {
            // must be video
            this.setFileStates("file", droppedFiles.map(oneFile => ({
                ...oneFile,
                preview: URL.createObjectURL(oneFile)
            })));

            // set the source, create the element and get the dimensions
            this.setState({
                videoSourceBlob: URL.createObjectURL(fileToUpload),
                postType: "video",
            });

            // now that the file is verified, we can add to the number of files uploaded
            this.setState({
                numberOfFiles: this.state.file.length,
                fileTooLarge: false,
            });
        }

        // take fileToUpload and save it in the state
        // and then read that into FileReader()
        this.setState({
            mostRecentFile: fileToUpload, errorMessage: ""
        });

        this.uploadToS3(postType, droppedFiles, fileToUpload);
    };

    sendTo = (sendingToClient) => {
        this.setState({
            sendingToClient,
        });
    };

    // get the video height and width
    // using jquery to avoid getting the height from the
    // video element on the page, which may not be rendered yet
    getVideoDimensions(file) {
        let reader = new FileReader();
        let self = this; // save the parent context
        reader.addEventListener("load", function() {
            let dataUrl = reader.result;
            let videoId = "videoMain";
            let $videoEl = $("<video id=" + videoId + "></video>");
            $videoEl.attr("src", dataUrl);

            let videoTagRef = $videoEl[0];
            videoTagRef.addEventListener("loadedmetadata", function(e) {
                let width = videoTagRef.videoWidth;
                let height = videoTagRef.videoHeight;

                // if both are valid, proceed
                if (!isNaN(width) && !isNaN(height)) {
                    self.getAspectRatio(width, height);
                }
            });
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    // get the aspect ratio
    // used as a callback from the get dimensions function
    getAspectRatio = (width, height) => {
        // define ratio constants
        const squareRatio = 1.0;
        const widescreenRatio = 16.0 / 9.0;

        // aspect ratio
        let aspectRatio = width / height;

        // check the aspect ratio
        if (this.closelyMatchesNumber(squareRatio, aspectRatio)) {
            // square
            this.setState({ aspectRatioString: "square" });
        } else if (this.closelyMatchesNumber(widescreenRatio, aspectRatio)) {
            // widescreen
            this.setState({ aspectRatioString: "widescreen" });
        } else {
            // not a supported aspect ratio
            // discard
            Toast.error("The uploaded video has an aspect ratio which is not supported");
            Toast.error("Please upload either 16:9 or 1:1 videos");
            this.setState({ postType: null, isSending: false });
            // make the send button visible again
            this.props.parentObject.setSendButtonVisible(true);
        }
    }

    // determine whether two numbers match
    // one another
    closelyMatchesNumber(correctValue, numberToEvaluate) {
        const marginOfError = 0.01;

        let lowEnd = correctValue - marginOfError * numberToEvaluate;
        let highEnd = correctValue + marginOfError * numberToEvaluate;

        if (numberToEvaluate >= lowEnd && numberToEvaluate <= highEnd) {
            return true;
        }

        return false;
    }

    sendMessage = () => {
        // send has been clicked
        // therefore we should hide the send button again
        this.props.parentObject.setSendButtonVisible(false);

        let subject = document.getElementById("new_notice_subject").value;
        let message = document.getElementById("new_notice_message").value;

        // if the length of either subject or message is wrong
        if (!this.checkMessageLength(message) || !this.checkSubjectLength(subject)) {
            this.props.parentObject.setSendButtonVisible(true);
            return;
        }

        this.setState({
            isSending: true,
        });

        let data = null;

        const readerType = (this.state.sendingToClient) ? "client" : "employee";

        if (this.state.postType === "image") {
            data = {
                "postType": this.state.postType,
                "uuid": this.state.uuid,
                "subject": subject,
                "message": message,
                "url": this.state.s3UploadBucket,
                "readerType": readerType
            };
        } else if (this.state.postType === "video") {
            data = {
                "postType": this.state.postType,
                "uuid": this.state.uuid,
                "subject": subject,
                "message": message,
                "url": this.state.s3UploadBucket,
                "aspectRatio": this.state.aspectRatioString,
                "readerType": readerType
            };
        }

        this.props.sendCallback(subject, message, data, this.state.sendingToClient, this.onError);
        // once it is uploaded to the API...
        // it is available through the transcoder...
    };

    onError = () => {
        this.setState({
            isSending: false,
        });
    };

    checkSubjectLength = (subject) => {
        if (subject.length === 0) {
            Toast.error("You must include a subject.");
            return false;
        } else if (subject.length > 96) {
            Toast.error("Subject must be less than 96 characters.");
            return false;
        }
        return true;
    }

    checkMessageLength = (message) => {
        if (message.length === 0) {
            Toast.error("You must include a message.");
            return false;
        } else if (message.length > 1000) {
            Toast.error("Message must be less than 1000 characters.");
            return false;
        }
        return true;
    }

    render() {
        const { file, sendingToClient } = this.state;
        const { closeCallback } = this.props;

        return (
            <this.Container style={{ minWidth: "35rem", minHeight: "35rem" }}>
                <this.GridBody style={{ paddingBottom: "5%" }}>
                    <this.Header>
                        <p style={{ textAlign: "left" }}>New Post</p>
                        <p style={{ textAlign: "right" }}>To {sendingToClient ? "Clients" : "Employees"}</p>
                    </this.Header>

                </this.GridBody>

                <this.GridBody bordered style={{ minHeight: "85%", height: "85%" }}>
                    <this.OuterFlexBox style={{ minHeight: "85%", height: "100%" }}>
                        <this.SubjectMessageFlexBox style={{ maxHeight: "80%", minWidth: "45%", maxWidth: "45%", minHeight: "50%" }}>
                            <div>
                                <this.InputBody id="new_notice_subject" placeholder="Subject" first>

                                </this.InputBody>
                            </div>

                            <div style={{ height: "50%" }}>
                                <this.TextAreaBody id="new_notice_message" placeholder="Message" last style={{ height: "100%", minHeight: "50%", maxHeight: "100%" }}>

                                </this.TextAreaBody>
                            </div>
                        </this.SubjectMessageFlexBox>

                        <this.DropZoneBrowseFlexBox>
                            {((file.length === 0) || (this.state.fileTooLarge)) ?
                                <this.UploadContentBox style={{ minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }}>
                                    <p>Attach Image or Video: </p>

                                    <div style={{ minWidth: "50%", maxWidth: "70%", minHeight: "50%", maxHeight: "70%" }}>
                                        <DropZone className="DropZoneStyle"
                                            accept={this.context.accept}
                                            onDrop={this.onDrop.bind(this)}>
                                        </DropZone>
                                    </div>
                                </this.UploadContentBox>
                                : this.state.postType === "video" ?
                                    <this.UploadContentBox style={{ minWidth: "100%", maxWidth: "100%", minHeight: "90%", maxHeight: "90%",
                                        verticalAlign: "center", horizontalAlign: "center", borderRadius: "20px", overflow: "hidden" }}>
                                        <video id="videoPlayer" style={{ borderRadius: "20px" }} controls className="video">
                                            <source src={this.state.videoSourceBlob}
                                                type={this.state.videoSourceType}/>
                                        </video>
                                    </this.UploadContentBox>
                                    : this.state.postType === "image" && (!this.state.fileTooLarge) ?
                                        <this.UploadContentBox style={{ minWidth: "100%", maxWidth: "100%", minHeight: "90%", maxHeight: "90%", verticalAlign: "center", horizontalAlign: "center" }}>
                                            <img style={{ margin: "0px", maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
                                                src={file[this.state.file.length - 1].preview} alt={this.state.imageSourcePreview}/>
                                        </this.UploadContentBox>
                                        : <this.UploadContentBox style={{ minWidth: "100%", maxWidth: "100%", width: "100%", height: "100%" }}>
                                            <p>Attach Image or Video: </p>
                                            <div style={{ minWidth: "100%", maxWidth: "100%", height: "100%", width: "100%" }}>
                                                <DropZone className="DropZoneStyle"
                                                    accept={this.context.accept}
                                                    onDrop={this.onDrop.bind(this)}>
                                                </DropZone>
                                            </div>
                                        </this.UploadContentBox>
                            }
                        </this.DropZoneBrowseFlexBox>
                    </this.OuterFlexBox>
                </this.GridBody>
                <this.GridBody style={{ minHeight: "100%", height: "100%" }}>
                    <this.InnerFlexBoxSepartor />
                    <this.OuterFlexBox>
                        <this.InnerFlexBox>
                            <p style={{ margin:0}}>Send to</p>
                            <DropDown margin="0">
                                <SendingToButton style={{ backgroundColor: "#51315D", color: "#FFFFFF", marginTop: "30%"}}>
                                    To: {sendingToClient ? " Clients" : " Employees"} ▼
                                </SendingToButton>
                                <DropDownContent>
                                    <DropDownContentButton
                                        onClick={() => this.sendTo(true)}>Client</DropDownContentButton>
                                    <DropDownContentButton
                                        onClick={() => this.sendTo(false)}>Employee</DropDownContentButton>
                                </DropDownContent>
                            </DropDown>
                        </this.InnerFlexBox>
                        <this.InnerFlexBox alignItems="end">
                                <DropZone className="DropZoneStyle"
                                    accept={this.context.accept}
                                    onDrop={this.onDrop.bind(this)}>

                                    <this.UploadContentButton>
                                        Browse File
                                    </this.UploadContentButton>
                                </DropZone>
                        </this.InnerFlexBox>
                    </this.OuterFlexBox>
                    
                </this.GridBody>
                <this.GridBody>
                    <this.OuterFlexBox>
                        <this.InnerFlexBox>
                        </this.InnerFlexBox>
                        <this.InnerFlexBox alignItems="end">
                            <div style={{ display: "flex", flexDirection: "row", alignItems:"start"}}>
                            <this.CloseButton onClick={() => closeCallback()}>
                                Close
                            </this.CloseButton>

                            {this.props.parentObject.getSendButtonVisible() ?
                                <this.SendButton onClick={() => this.sendMessage()} style={{marginLeft: "30px"}}>
                                    Send
                                </this.SendButton>
                                :
                                <div>
                                    <LoadingIndicator height="" style={{ height: "10px", width: "10px", position: "relative", top: "100%" }}/>
                                </div>
                            }

                            </div>
                        </this.InnerFlexBox>
                    </this.OuterFlexBox>
                </this.GridBody>
            </this.Container>
        );
    }
}

const SortableColumns = {
    DATE: 0,
    POST: 1,
    READ: 2,
    FLAG_LENGTH: 3
};

const SortableColumnState = {
    INACTIVE: 0,
    DESCENDING: 1,
    ASCENDING: 2,
};

const serverlessImageHandlerUrl = "https://image.dailyfixme.com/";
const maxImageSize = 4194304; // 4 MB

class Noticeboard extends Component {
    state = {
        loading: false,
        currentForm: Forms.INITIAL,
        isNextClickable: true,

        postNew: false,
        isSendingNewPost: false,
        viewingClientBoard: false,
        clients: null,
        employees: null,
        totalNumber: null,
        noticeArray: [],
        sortableColumns: [],
        sortableIndex: 0,

        sendingToClient: false,
        isSending: false,

        sendButtonVisible: true,
    };

    setSendButtonVisible(value) {
        this.setState({ sendButtonVisible: value });
    }

    getSendButtonVisible() {
        return this.state.sendButtonVisible;
    }

    componentDidMount() {
        this.setState({
            loading: true,
            sortableColumns: [],
        });

        axios({
            method: "GET",
            url: `${API_URL}/company`,
            headers: {
                Authorization: "Bearer " + this.props.user.token,
            }
        }).then(response => {
            let totalNumber = response.data.totalNumber;
            let employees = response.data.employees;
            let clients = response.data.clients;
            let companyName = response.data.companyName;

            this.setState({
                totalNumber,
                employees,
                clients,
                companyName,
            }, this.getNotices);
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
        this.setColumns();
    }

    getImageUrl = (imageKey) => {
        // do transcoder stuff... generate a JSON object... base64 encode it... append that to the image.dailyfixme.com url
        // then use that url as the key for the <img> tag in the noticeboard class...
        let transcoderJsonObject = `{
                "bucket": "dailyfixme.content",
                "key":"` + imageKey + `",
                "edits": {
                    "resize": {
                        "width": 120,
                        "height": 63,
                        "fit": "inside"
                    }
                }
        }`;

        let Buffer = require("buffer/").Buffer;
        const encodedString = new Buffer(transcoderJsonObject).toString("base64");
        let fullImageUrl = serverlessImageHandlerUrl + encodedString;

        return fullImageUrl;
    }

    getNotices = () => {
        const { user } = this.props;
        this.setState({
            loading: true,
        });
        console.log(this.state.viewingClientBoard);
        axios({
            method: "GET",
            url: `${API_URL}/company/noticeboard/${this.state.viewingClientBoard ? "clients" : "employees"}`,
            headers: {
                Authorization: "Bearer " + this.props.user.token,
            }
        }).then(response => {
            let noticeArray = response.data.notices ? response.data.notices : [];

            let potentialReads = this.state.viewingClientBoard ? this.state.clients : this.state.employees;

            noticeArray = noticeArray.map(notice => {
                notice["readPercentage"] = potentialReads > 0 ?
                    Math.ceil((notice["numberOfReads"] / potentialReads) * 100) : 0;
                notice["read"] = `${notice["numberOfReads"]}/${potentialReads} (${notice["readPercentage"]}%)`;
                return notice;
            });

            // update the noticeArray[] with serverless images
            // and video previews
            let index = 0;
            for (index = 0; index < noticeArray.length; index++) {
                if (noticeArray[index]["postType"] === "image") {
                    // it is an image...
                    noticeArray[index]["serverlessImageHandlerUrl"] = this.getImageUrl(noticeArray[index]["image"]["imageKey"]);
                }
            }

            this.setState({
                noticeArray,
                loading: false
            }, () => this.sortColumns(this.state.sortableIndex, true));
        }).catch(error => {
            if (error.response) {
                if (error.response.data.error === "No employee account was found.") {
                    return Modal.open(
                        "Could Not Find The Primary Account!",
                        <div>
                            <h4>
                                We attempted to create an automatic association with this company account and an
                                employee account, but found none that shares your email ({user.email}).
                            </h4>
                            <p>
                                You can fix this by creating an employee account that has the same email as
                                the company account, or contact <a href="mailto:support@dailyfixme.com">support@dailyfixme.com</a>
                                if you believe something else is wrong.
                            </p>
                        </div>,
                        () => window.location.href = "/employList",
                        "Go to Employee List",
                        false
                    );
                } else {
                    return Toast.error(error.response.data.error);
                }
            } else {
                return Toast.error(error.message);
            }
        });
    };

    setColumns = () => {
        let columns = [];
        for (let i = 0; i < SortableColumns.FLAG_LENGTH; i++) {
            columns.push(SortableColumnState.INACTIVE);
        }

        this.setState({
            sortableColumns: columns,
        });
    };

    sortColumns = (columnIndex, refresh = false) => {
        const columns = this.state.sortableColumns;
        let fieldName = "";

        // Setting all other column values to inactive, this way they will always start as descending when clicked after another column,
        // while any subsequent clicks will toggle between descending and ascending sort.
        if (!refresh) {
            for (let i = 0; i < SortableColumns.FLAG_LENGTH; i++) {
                if (i === columnIndex) {
                    let value = columns[columnIndex];

                    switch (value) {
                    case SortableColumnState.DESCENDING:
                        value = SortableColumnState.ASCENDING;
                        break;
                    case SortableColumnState.ASCENDING:
                    case SortableColumnState.INACTIVE:
                    default:
                        value = SortableColumnState.DESCENDING;
                        break;
                    }

                    columns[columnIndex] = value;
                } else {
                    columns[i] = SortableColumnState.INACTIVE;
                }
            }
        }

        // Calling the sort for the particular column affected.
        switch (columnIndex) {
        case SortableColumns.DATE:
            fieldName = "date";
            break;
        case SortableColumns.POST:
            fieldName = "post";
            break;
        case SortableColumns.READ:
            fieldName = "readPercentage";
            break;
        default:
            this.sortStatus(columns[columnIndex]);
            break;
        }

        if (fieldName !== "")
            this.sortField(columns[columnIndex], fieldName);

        this.setState({
            sortableColumns: columns,
            sortableIndex: columnIndex,
        });
    };

    sortField = (sortableState, fieldName) => {
        const notices = this.state.noticeArray;

        if (sortableState === SortableColumnState.ASCENDING) {
            notices.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : -1);
        } else {
            notices.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : -1);
        }

        this.setState({
            noticeArray: notices
        });
    };

    deleteNotice = (uuid) => {
        Modal.open(
            "Are you sure?",
            <div>
                <h3>You are about to delete this notice which is irreversible.</h3>
                <p>If you are sure you want to proceed then click the button below to confirm. Otherwise,
                    click the cross up in the top-right corner to cancel this action.</p>
            </div>,
            () => {
                // For a nicer user experience, we'll just "remove" it immediately after a user hits the button.
                Modal.close();
                let notices = this.state.noticeArray.filter($notice => {
                    return $notice["uuid"] !== uuid;
                });

                this.setState({
                    noticeArray: notices,
                });

                axios({
                    method: "DELETE",
                    url: `${API_URL}/company/noticeboard/`,
                    headers: {
                        Authorization: "Bearer " + this.props.user.token,
                    },
                    data: {
                        "uuid": uuid,
                    }
                }).catch(error => {
                    if (error.response) {
                        return Toast.error(error.response.data.error);
                    } else {
                        return Toast.error(error.message);
                    }
                });
            },
            "Delete"
        );
    };

    /* updates the notice with an attachment */
    updateNotice = (data, sendingToClient, onErrorCallback) => {
        axios({
            method: "PUT",
            url: `${API_URL}/company/noticeboard`,
            headers: {
                Authorization: "Bearer " + this.props.user.token,
            }, data
        }).then(response => {
            let notices = this.state.noticeArray;
            if (sendingToClient === this.state.viewingClientBoard) {
                let notice = response.data.notice;

                let potentialReads = this.state.viewingClientBoard ? this.state.clients : this.state.employees;
                let numberOfReads = notice["numberOfReads"] ? notice["numberOfReads"] : 0;
                notice["readPercentage"] = potentialReads > 0 ?
                    Math.ceil((numberOfReads / potentialReads) * 100) : 0;
                notice["read"] = `${numberOfReads}/${potentialReads} (${notice["readPercentage"]}%)`;
                notice["date"] = notice["date"] ? notice["date"] : new Date();
                notices[notices.length] = notice;
            }

            this.setState({
                postNew: false,
                noticeArray: notices,
            }, () => this.sortColumns(this.state.sortableIndex, true));
        }).catch(error => {
            console.log(error);

            onErrorCallback();
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
    }

    sendMessage = (subject, message, data, sendingToClient, onErrorCallback) => {
        if (data !== null) {
            // if media we are just updating a shell with s3 data
            this.updateNotice(data, sendingToClient, onErrorCallback);
        } else {
            // otherwise create a new post
            axios({
                method: "POST",
                url: `${API_URL}/company/noticeboard/${sendingToClient ? "clients" : "employees"}`,
                headers: {
                    Authorization: "Bearer " + this.props.user.token,

                }, data: {
                    "subject": subject,
                    "message": message,
                }
            }).then(response => {
                let notices = this.state.noticeArray;
                if (sendingToClient === this.state.viewingClientBoard) {
                    let notice = response.data.notice;
                    let potentialReads = this.state.viewingClientBoard ? this.state.clients : this.state.employees;
                    let numberOfReads = notice["numberOfReads"] ? notice["numberOfReads"] : 0;
                    notice["readPercentage"] = potentialReads > 0 ?
                        Math.ceil((numberOfReads / potentialReads) * 100) : 0;
                    notice["read"] = `${numberOfReads}/${potentialReads} (${notice["readPercentage"]}%)`;
                    notice["date"] = notice["date"] ? notice["date"] : new Date();
                    notices[notices.length] = notice;
                }

                this.setState({
                    postNew: false,
                    noticeArray: notices,
                }, () => this.sortColumns(this.state.sortableIndex, true));
            }).catch(error => {
                console.log(error);

                onErrorCallback();
                if (error.response) {
                    return Toast.error(error.response.data.error);
                } else {
                    return Toast.error(error.message);
                }
            });
        }
    };

    setBoardView = (client) => {
        this.setState({
            viewingClientBoard: client,
        }, this.getNotices);
    };

    openNewNoticeModal = () => {
        this.setState({
            postNew: true,
        });

        // make sure the send button is visible
        // on a new notice modal window
        this.setSendButtonVisible(this, true);
    };

    closeNewNoticeModal = () => {
        this.setState({
            postNew: false,
        });
    };

    setFileStates = (name, value) => {
        this.setState({
            ...this.state,
            [name]: value,
        });
    };

    nextAvailable = (url) => {
        this.setState({
            isNextClickable: true,
            assetUrl: url
        });
    };

    handleChange = (name, value) => {
        this.setState({
            ...this.state,
            [name]: value,
        });
    };

    isUndefined = (something) => {
        if (typeof something === "undefined") {
            return true;
        }
    }

    render() {
        const {
            loading, viewingClientBoard,
            employees, clients, postNew,
            companyName, sortableColumns,
            noticeArray, isSendingNewPost,
            file, assetUrl, currentForm
        } = this.state;

        // Generate empty lines when there are less than 10 notices.
        let emptyList;
        if (noticeArray.length < 5) {
            emptyList = Array.apply(0, Array(5 - noticeArray.length)).map((value, index) => {
                return (
                    <Tr key={index} style={{ height: "46px" }}>
                        <Td width={"15%"}/>
                        <Td width={"40%"}/>
                        <Td width={"20%"}/>
                        <Td width={"15%"}/>
                        <Td width={"10%"}/>
                    </Tr>
                );
            });
        }
        return (
            <FlexContainer>
                { postNew &&
                <div>
                    <Backdrop />
                    <ModalContainer>
                        <NoticeModalContext.Provider value={{
                            file: file,
                            assetUrl: assetUrl,
                            currentForm: currentForm,
                            token: this.props.user.token,
                            setFileStates: this.handleChange,
                        }}>
                            <NoticeModal
                                sendingToClient={viewingClientBoard}
                                isSendingNewPost={isSendingNewPost}
                                sendCallback={this.sendMessage}
                                closeCallback={this.closeNewNoticeModal}
                                user={this.props.user}
                                nextAvailable={this.nextAvailable}
                                parentObject={this}
                            />
                        </NoticeModalContext.Provider>
                    </ModalContainer>
                </div>
                }

                <Text color="black" size="40px" margin="0">
                    {viewingClientBoard ? "Client" : "Employee"} Noticeboard
                </Text>
                <GridHeader>
                    <GridHeaderChild>
                        <DifferentFonts 
                            size="20px"
                            mediumText={companyName + ":"}
                            color="#23262D"
                            regularText={(viewingClientBoard ? clients : employees) + (viewingClientBoard ? " Clients" : " Employees")}
                            bottom="0"
                            marginTop="40px"
                        />
                    </GridHeaderChild>
                    <GridHeaderChild>
                        <DropDownTop>
                            <DropDownButton style={{ position: "relative" }}>
                                <div> {viewingClientBoard ? "Client ▼" : "Employee ▼"} </div>
                            </DropDownButton>
                            { !loading &&
                            <DropDownContentTop>
                                <DropDownButtonTop
                                    onClick={() => this.setBoardView(true)}>Client
                                </DropDownButtonTop>
                                <DropDownButtonTop
                                    onClick={() => this.setBoardView(false)}>Employee
                                </DropDownButtonTop>
                            </DropDownContentTop>
                            }
                        </DropDownTop>
                    </GridHeaderChild>
                    <GridHeaderChild>
                        <NewPostButton onClick={this.openNewNoticeModal}>
                            <div style={{ verticalAlign: "center", margin: "auto" }}>{loading ? "Loading..." : "New Post ➕"}</div>
                        </NewPostButton>
                    </GridHeaderChild>

                </GridHeader>

                { loading ?
                    <LoadingIndicator/>
                    :
                    <div>
                        <HeaderContainer>
                            <Table>
                                <thead>
                                    <Tr>
                                        <Th onClick={() => this.sortColumns(SortableColumns.DATE)} width={"15%"} style={{
                                            cursor: "pointer"
                                        }}>
                                            <ColumnHeader
                                                heading="Date Posted"
                                                sortDirection={sortableColumns ? sortableColumns[SortableColumns.DATE] : null}
                                            />
                                        </Th>
                                        <Th onClick={() => this.sortColumns(SortableColumns.POST)} width={"40%"} style={{
                                            cursor: "pointer"
                                        }}>
                                            <ColumnHeader
                                                heading="Subject / Description"
                                                sortDirection={sortableColumns ? sortableColumns[SortableColumns.POST] : null}
                                            />
                                        </Th>

                                        <Th onClick={() => this.sortColumns(SortableColumns.POST)} width={"20%"} style={{
                                            cursor: "pointer"
                                        }}>
                                            <ColumnHeader
                                                heading="Image / Video"
                                                sortDirection={sortableColumns ? sortableColumns[SortableColumns.POST] : null}
                                            />
                                        </Th>

                                        <Th onClick={() => this.sortColumns(SortableColumns.READ)} width={"15%"} style={{
                                            cursor: "pointer"
                                        }}>
                                            <ColumnHeader
                                                heading="Read %"
                                                sortDirection={sortableColumns ? sortableColumns[SortableColumns.READ] : null}
                                            />
                                        </Th>
                                        <Th onClick={() => {}} width={"10%"} style={{
                                            cursor: "pointer"
                                        }}>
                                            <ColumnHeader
                                                heading="Delete"
                                                sortDirection={""}
                                            />
                                        </Th>
                                    </Tr>
                                </thead>
                            </Table>
                        </HeaderContainer>

                        <ScrollContainer>
                            <Table>
                                <tbody>
                                    { noticeArray.map((notice, index) => {
                                        return (
                                            <Tr key={ notice.uuid }>
                                                <Td width={"15%"}>
                                                    <Text color="black">
                                                        {
                                                            moment(notice["date"], ["L", moment.HTML5_FMT.DATE])
                                                                .format("L")
                                                        }
                                                    </Text>
                                                </Td>
                                                <Td width={"40%"}>
                                                    <Text margin="10px" align="left" color="black">
                                                        { notice["subject"] }
                                                    </Text>
                                                    <Text margin="10px" align="left" color="black">
                                                        { notice["message"] }
                                                    </Text>
                                                </Td>

                                                {(this.isUndefined(notice["image"]) && (this.isUndefined(notice["video"]))) ?
                                                    <Td width={"20%"} height={"100%"}> <div style={{ textAlign: "center" }}> None </div> </Td>

                                                    : (!this.isUndefined(notice["image"])) ?
                                                        <Td width={"20%"} style={{ textAlign:"center"}}>
                                                            <img align="center" style={{ height: notice["height"], width: notice["width"], maxWidth: "90%", maxHeight: "90%", padding: "0px", marginTop: "10%", marginBottom: "10%" }}
                                                                src={notice["serverlessImageHandlerUrl"]}
                                                                alt={notice["serverlessImageHandlerUrl"]} />
                                                            
                                                        </Td>

                                                        : !this.isUndefined(notice["video"]) &&
                                                    <Td width={"20%"} style={{ textAlign:"center"}}>
                                                        <img align="center" style={{ height: notice["height"], width: notice["width"], maxWidth: "90%", maxHeight: "90%", marginTop: "5%", marginBottom: "5%" }}
                                                            src={notice["video"]["previewImageUrl"]}
                                                            alt={notice["video"]["previewImageUrl"]} />
                                                        
                                                    </Td>
                                                }

                                                <Td width={"15%"}>
                                                    {(this.isUndefined(notice["read"])) ?
                                                        <p/>
                                                        :
                                                        <Text color="black"> {notice["read"]}</Text>
                                                    }
                                                </Td>
                                                <Td style={{ width: "10%", height: "20%" }}>
                                                    <DeleteButton style={{ marginTop: "20%", marginLeft: "25%", marginBottom: "20%" }} onClick={() => this.deleteNotice(notice["uuid"])}>
                                                        <Text style={{ lineHeight: "2.1rem", color: "white", margin: "0%" }}>
                                                            Delete
                                                        </Text>
                                                    </DeleteButton>

                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                    { emptyList }
                                </tbody>
                            </Table>
                        </ScrollContainer>
                    </div>
                }
            </FlexContainer>
        );
    }
}

export default Noticeboard;
