import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_URL } from "App";
import Text from "components/Text";
import FormField from "components/FormField";
import NextButton from "components/NextButton";
import Form from "components/Form";
import FlexContainer from "components/FlexContainer";
import LoadingIndicator from "LoadingIndicator";

const LoginError = {
    EMPTY_EMAIL: 1,
    EMPTY_PWD: 2,
};

const ErrorStrings = {
    [LoginError.EMPTY_EMAIL]: "* Please enter an email address",
    [LoginError.EMPTY_PWD]: "* Please enter a password",
};

class Login extends Component {
    state = {
        email: "",
        password: "",
        inputErrors: [],
        errorMessage: "",
        isLoginClickable: true,
        loading: false,
    }

    componentDidMount() {
        this.setState({
            isLoginClickable: true
        });
    }

    login = (e) => {
        const { email, password } = this.state;

        e.preventDefault();
        let errorArray = [];

        if (email.trim() === "") {
            errorArray.push(LoginError.EMPTY_EMAIL);
        }

        if (password.trim() === "") {
            errorArray.push(LoginError.EMPTY_PWD);
        }

        this.setState({
            inputErrors: errorArray,
            errorMessage: ""
        });
        if (errorArray.length === 0 && this.state.isLoginClickable) {
            this.setState({
                isLoginClickable: false,
                loading: true,
            });

            axios.post(`${API_URL}/company/login`, {
                email: email,
                password: password,
            })
                .then(response => {
                    this.setState({ loading: false });
                    this.props.login(response.data.account);
                })
                .catch(error => {
                    if (error.response) {
                        this.setState({
                            errorMessage: error.response.data.error,
                            isLoginClickable: true,
                            loading: false
                        });
                    }
                });
        }
    };

    render = () => {
        const { inputErrors, errorMessage, loading } = this.state;
        const emailError = inputErrors.filter(error => error === LoginError.EMPTY_EMAIL).map(error => ErrorStrings[error]);
        const passwordError = inputErrors.filter(error => error === LoginError.EMPTY_PWD).map(error => ErrorStrings[error]);

        return (
            <FlexContainer alignItems="center" justifyContent="center" flexGrow="1">
                <Form>
                    <Text color="red"><strong>{errorMessage}</strong></Text><br/>
                    <Text size="18px" weight="500" color="white" shadow="0px 0px 20px #FFFFFF">
                        <strong>Hello, again.</strong>
                    </Text>
                    <br/>
                    <FormField
                        placeholder="Email Address"
                        onChange={(e) => this.setState({ email: e.target.value }) }
                        errors={emailError}
                    />
                    <FormField
                        placeholder="Password"
                        type="password"
                        onChange={(e) => this.setState({ password: e.target.value }) }
                        errors={passwordError}
                    />
                    <FlexContainer width="100%" height="4em" justifyContent="center">
                        { loading ?
                            <LoadingIndicator height="7px" width="7px"/>
                            :
                            <NextButton
                                alt="Login"
                                onClick={this.login}
                            />
                        }
                    </FlexContainer>
                    <Text color="white">Oh no, &nbsp;
                        <Link to="/forgotPassword" style={{ color: "white" }}>
                            <strong>forgot your password?</strong>
                        </Link>
                    </Text>
                </Form>
            </FlexContainer>
        );
    }
}

export default Login;