import React, { Component } from "react";
import styled from "styled-components";
import { Screens } from "Customise";

import ImgBackground from "../../../img/customise/ImgBackground.png";
import ImgPhoneFrame from "../../../img/customise/ImgPhoneFrame.png";
import ImgYourLogoHere from "../../../img/customise/ImgYourLogoHere.png";

import ImgQuotesOverlay from "../../../img/customise/ImgQuotesOverlay.png";
import ImgQuotesPrimary from "../../../img/customise/ImgQuotesPrimary.png";
import ImgQuotesSecondary from "../../../img/customise/ImgQuotesSecondary.png";

import ImgBackgroundNoticeboard from "../../../img/customise/ImgBackgroundNoticeboard.png";
import ImgNoticeboardBackgroundOverlay from "../../../img/customise/ImgNoticeboardBackgroundOverlay.png";
import ImgNoticeboardPrimary from "../../../img/customise/ImgNoticeboardPrimary.png";

import ImgBackgroundPlaylists from "../../../img/customise/ImgBackgroundPlaylists.png";
import ImgPlaylistsBackgroundOverlay from "../../../img/customise/ImgPlaylistsBackgroundOverlay.png";
import ImgPlaylistsOverlay from "../../../img/customise/ImgPlaylistsOverlay.png";
import ImgPlaylistsPrimary from "../../../img/customise/ImgPlaylistsPrimary.png";

import ImgGoalArticle from "../../../img/customise/ImgGoalArticle.png";
import ImgGoalTrackerPrimary from "../../../img/customise/ImgGoalTrackerPrimary.png";
import ImgGoalTrackerSecondary from "../../../img/customise/ImgGoalTrackerSecondary.png";

import ImgSplashPrimary from "../../../img/customise/ImgSplashPrimary.png";
import ImgSplashSecondary from "../../../img/customise/ImgSplashSecondary.png";

const LayerSizing = {
    FRAME_WIDTH: 252,
    FRAME_HEIGHT: 512,
    SCREEN_WIDTH: 232,
    SCREEN_HEIGHT: 492
};

const WidthContainer = styled.div`
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
`;

const ZeroSizeSvg = styled.svg`
    width: 0px;
    height: 0px;
    position: absolute;
`;

const PlainImageLayer = styled.div`
    width: ${p => p.layout.width}px;
    height: ${p => p.layout.height}px;
    position: absolute;
    left: ${p => p.layout.left}px;
    top: ${p => p.layout.top}px;
    background-image: url(${ p => p.src });
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

const PlainImageTextLayer = styled.div`
    width: ${p => p.layout.width}px;
    height: ${p => p.layout.height}px;
    position: absolute;
    left: ${p => p.layout.left}px;
    top: ${p => p.layout.top}px;
    background-image: url(${ p => p.src });
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &:after {
        content: '${p => p.content || "Company Name"}';
        position: absolute; 
        top: 0; 
        left: 0; 
        bottom: 0; 
        right: 0;
        margin-top: 40px; 
        z-index: 99999; 
        font-size: 16px; 
        color: #FFFFFF;
        text-align: center;
        text-shadow: 0px 0px 5px black;
        padding: 5px;
    }
`;

const BackgroundLayer = styled(PlainImageLayer)`
    filter: url(#filter-background);
`;

const PrimaryLayer = styled(PlainImageLayer)`
    filter: url(#filter-primary);
`;

const SecondaryLayer = styled(PlainImageLayer)`
    filter: url(#filter-secondary);
`;

class SvgFilter extends Component {
    render() {
        const { filterId, rgbValues } = this.props;
        const matrix = `0 0 0 0 ${rgbValues[0]} 0 0 0 0 ${rgbValues[1]} 0 0 0 0 ${rgbValues[2]} 0 0 0 1 0`;
        return (
            <filter id={filterId} colorInterpolationFilters="sRGB">
                <feColorMatrix type="matrix" values={matrix} />
            </filter>
        );
    }
}

class FilteredPngStack extends Component {
    renderLayers(currentScreen, logoUrl, companyName) {
        const logoAspect = 3.43;
        const logoSize = {
            width: LayerSizing.FRAME_WIDTH / 2,
            height: Math.floor(LayerSizing.FRAME_WIDTH / 2 / logoAspect)
        };
        const phoneLayout = {
            width: LayerSizing.FRAME_WIDTH,
            height: LayerSizing.FRAME_HEIGHT,
            left: 0,
            top: 0
        };
        const screenLayout = {
            width: LayerSizing.SCREEN_WIDTH,
            height: LayerSizing.SCREEN_HEIGHT,
            left: (LayerSizing.FRAME_WIDTH - LayerSizing.SCREEN_WIDTH) / 2,
            top: (LayerSizing.FRAME_HEIGHT - LayerSizing.SCREEN_HEIGHT) / 2
        };
        const logoLayout = {
            width: logoSize.width,
            height: logoSize.height,
            left: (LayerSizing.FRAME_WIDTH - logoSize.width) / 2,
            top: (currentScreen === Screens.MAIN ? LayerSizing.FRAME_HEIGHT / 10 : LayerSizing.FRAME_HEIGHT / 4) - logoSize.height / 2
        };

        const hasCompanyLogo = logoUrl.charAt(0) !== "?";
        const imageLayers = [];
        switch (currentScreen) {
        case Screens.MAIN:
            imageLayers.push(<BackgroundLayer layout={screenLayout} src={ImgBackground} />);
            imageLayers.push(<PrimaryLayer layout={screenLayout} src={ImgQuotesPrimary} />);
            imageLayers.push(<SecondaryLayer layout={screenLayout} src={ImgQuotesSecondary} />);
            imageLayers.push(<PlainImageLayer layout={screenLayout} src={ImgQuotesOverlay} />);
            imageLayers.push(<PlainImageTextLayer layout={logoLayout} src={hasCompanyLogo ? logoUrl : ImgYourLogoHere} content={companyName} />);
            imageLayers.push(<PlainImageLayer layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        case Screens.NOTICEBOARD:
            imageLayers.push(<PlainImageLayer layout={screenLayout} src={ImgBackgroundNoticeboard} />);
            imageLayers.push(<BackgroundLayer layout={screenLayout} src={ImgNoticeboardBackgroundOverlay} />);
            imageLayers.push(<PrimaryLayer layout={screenLayout} src={ImgNoticeboardPrimary} />);
            imageLayers.push(<PlainImageLayer layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        case Screens.PLAYLISTS:
            imageLayers.push(<PlainImageLayer layout={screenLayout} src={ImgBackgroundPlaylists} />);
            imageLayers.push(<BackgroundLayer layout={screenLayout} src={ImgPlaylistsBackgroundOverlay} />);
            imageLayers.push(<PrimaryLayer layout={screenLayout} src={ImgPlaylistsPrimary} />);
            imageLayers.push(<PlainImageLayer layout={screenLayout} src={ImgPlaylistsOverlay} />);
            imageLayers.push(<PlainImageLayer layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        case Screens.GOALS:
            imageLayers.push(<BackgroundLayer layout={screenLayout} src={ImgBackground} />);
            imageLayers.push(<PrimaryLayer layout={screenLayout} src={ImgGoalTrackerPrimary} />);
            imageLayers.push(<SecondaryLayer layout={screenLayout} src={ImgGoalTrackerSecondary} />);
            imageLayers.push(<PlainImageLayer layout={screenLayout} src={ImgGoalArticle} />);
            imageLayers.push(<PlainImageLayer layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        case Screens.SPLASH:
            imageLayers.push(<BackgroundLayer layout={screenLayout} src={ImgBackground} />);
            imageLayers.push(<PrimaryLayer layout={screenLayout} src={ImgSplashPrimary} />);
            imageLayers.push(<SecondaryLayer layout={screenLayout} src={ImgSplashSecondary} />);
            imageLayers.push(<PlainImageLayer layout={logoLayout} src={hasCompanyLogo ? logoUrl : ImgYourLogoHere} />);
            imageLayers.push(<PlainImageLayer layout={phoneLayout} src={ImgPhoneFrame} />);
            break;
        default:
            break;
        }

        return imageLayers;
    }

    render() {
        const { currentScreen, colourPalette, logoUrl, companyName } = this.props;

        const imageLayers = this.renderLayers(currentScreen, logoUrl, companyName);
        const { primaryColor, secondaryColor, backgroundColor } = colourPalette;

        return (
            <WidthContainer>
                <ZeroSizeSvg>
                    <SvgFilter filterId="filter-primary" rgbValues={primaryColor} />
                    <SvgFilter filterId="filter-secondary" rgbValues={secondaryColor} />
                    <SvgFilter filterId="filter-background" rgbValues={backgroundColor} />
                </ZeroSizeSvg>
                {imageLayers}
            </WidthContainer>
        );
    }
}

export default FilteredPngStack;
