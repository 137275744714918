import React from "react";
import styled from "styled-components";

const StyledCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

function CenterContainer(props) {
    const { children, ...styleProps } = props;
    return (
        <StyledCenter {...styleProps} >
            {children}
        </StyledCenter>
    );  
}

export default CenterContainer;