import React, { Component } from "react";
import styled, { css } from "styled-components";
import LeftArrow from "img/arrow-left.svg";
import RightArrow from "img/arrow-right.svg";
import { Forms } from "Customise";
import { ScreenSwitch } from "../forms/SelectColor";
import Text from "components/Text";
import Button from "components/Button";
import FilteredPngStack from "Customise/components/FilteredPngStack";

const FlexContainer = styled.div`
    display: flex;
    flex-direction: ${p => p.direction || "row"};
    width: ${p => p.width || "100%"};
    align-items: center;
`;

const WidthContainer = styled.div`
    box-sizing: ${p => p.boxSizing};
    display: flex;
    height: ${p => p.height || "100%"};
    width: ${p => p.width || "10%"};
    align-self: center;
    align-items: center;
    justify-content: center;
    flex-direction: ${p => p.direction};
    margin-top: ${p => p.marginTop};
    min-height: ${p => p.minHeight};
    padding: ${p => p.padding};
    position: ${p => p.position};
`;

const Dot = styled.span`
    cursor: pointer;
    height: 5px;
    width: 5px;
    margin: 0 2px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;

    ${p => p.isHighlighted && css`
        height: 10px;
        width: 10px;
    `}
`;

class ImageSlider extends Component {
    /**
     * @param {int} currentScreen
     * @param {int[]} screenIds
     * @return {Dot[]}
     */
    renderDotsForSlides(currentScreen, screenIds) {
        return screenIds.map((id, index) =>
            <Dot key={index} isHighlighted={id === currentScreen} />
        );
    }

    render() {
        const { currentScreen, leftScreen, rightScreen, currentForm, imageWidth,
            screenIds, colourPalette, companyName, assetUrl } = this.props;
        const dots = this.renderDotsForSlides(currentScreen, screenIds);

        return (
            <FlexContainer direction="column">

                <FlexContainer>
                    <WidthContainer>
                        <Button onClick={leftScreen} padding="0 10px 0 0">
                            <img src={LeftArrow} alt={LeftArrow} width="20px" />
                        </Button>
                    </WidthContainer>
                    
                    {/* Images container */}
                    <WidthContainer width={ imageWidth || "80%" } direction="column">
                        <WidthContainer width={"252px"} height={"512px"} direction="column" padding="40px"
                            boxSizing="border-box" position="relative" minHeight="500px">
                            { currentForm !== Forms.FINISH &&
                                <Text weight="500" color="black" size="16px">
                                    Your {ScreenSwitch(currentScreen)}
                                </Text>
                            }
                            <FilteredPngStack
                                margin={"auto"}
                                colourPalette={colourPalette}
                                currentScreen={currentScreen}
                                logoUrl={assetUrl}
                                companyName={companyName} />
                        </WidthContainer>

                        {/* Displayed dots below images*/}
                        <WidthContainer width="100%" height="auto" boxSizing="border-box" padding="8px">
                            <WidthContainer direction="row" width="100%">
                                {dots}
                            </WidthContainer>
                        </WidthContainer>
                    </WidthContainer>
                    <WidthContainer>
                        <Button onClick={rightScreen} padding="0 0 0 10px">
                            <img src={RightArrow} alt={RightArrow} width="20px" />
                        </Button>
                    </WidthContainer>
                </FlexContainer>
            </FlexContainer>
        );
    }
}

export default ImageSlider;