import React, { Component } from "react";
import styled from "styled-components";
import { SketchPicker } from "react-color";
import Button from "components/Button";
import { AnimationColors } from "../Customise";

const ColorPop = styled.div`
    position: absolute;
    z-index: 2;
`;

const ColorCover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const ColorCircle = styled.span`
    width: 80px;
    height: 82px;
    display: inline-block;
    border-radius: 50%;
    background-color: ${ p => p.color};
`;

class ColorPicker extends Component {
    state = {
        isColorClicked: false,
    }

    handleClick = () => {
        this.setState({ isColorClicked: !this.state.isColorClicked });
    }

    handleClose = () => {
        this.setState({ isColorClicked: false });
    }

    handleColorChange = (color) => {
        this.props.handleColorChange(color);
    } 

    render() {
        const { color, colorImg, isComplete } = this.props;
        const { isColorClicked } = this.state;

        return (
            <Button>
                {!isColorClicked ? 
                    <div>
                        {!isComplete && (JSON.stringify(color) === JSON.stringify(AnimationColors.BACKGROUND)
                            || JSON.stringify(color) === JSON.stringify(AnimationColors.PRIMARY)
                            || JSON.stringify(color) === JSON.stringify(AnimationColors.SECONDARY)) ?
                            <img height="82px" width="80px" src={colorImg} alt={colorImg} onClick={this.handleClick} />
                            : <ColorCircle color={ color } onClick={this.handleClick} />}
                    </div>
                    :
                    <ColorPop>
                        <ColorCover onClick={this.handleClose}/>
                        <SketchPicker color={color} onChange={this.handleColorChange} />
                    </ColorPop>
                }
            </Button>
        );
    } 
}

export default ColorPicker;