import React from "react";
import styled from "styled-components";
import Button from "components/Button";
import Text from "components/Text";

const StyledButton = styled(Button)`
    padding: ${ p => p.padding || "5px 10px"};
    width: ${p => p.width || "100px"};
    background-color: #000000;
    color: #FFFFFF;
    border: 1px #2D3037 solid;
    border-radius: 20px;    
    height: ${ p => p.height || "40px"};
    margin-right: ${p => p.right};
    margin-top: ${p => p.marginTop};
`;

const StyledLeftText = styled(Text)`
    size: ${p => p.size || "14px"};
    color: ${p => p.color || "#23262D"}; 
    text-align: ${p => p.align || "left"};
    margin: ${p => p.margin || "30px 0"};
    line-height: ${p => p.lineHeight};  
    width: ${p => p.width};
    font-family: ${p => p.family};
    min-width: ${p => p.minWidth};
`;

function TermsConditionModal({ acceptTC, show, children }) {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    return (
        <div className={showHideClassName}>
        <section className="modal-main">
          {children}
            <div className="modal-body">
                <StyledLeftText size="30px">Subscription & Cancellation Terms</StyledLeftText>
                <StyledLeftText size="20px">Subscription Terms:</StyledLeftText>
                <StyledLeftText>
                    Your subscription begins as soon as your initial payment is processed. Your subscription will automatically renew annually or monthly without notice until you cancel. 
                    You authorise us to store your payment method(s) and to automatically charge your payment method(s) annually or monthly until you cancel. We will automatically charge 
                    you the then-current rate for your plan, plus applicable taxes (such as GST if the rate does not include it), every month of your annual contract until you cancel.
                </StyledLeftText>
                <StyledLeftText>
                    We may change your plan’s rate each annual renewal term, and we will notify you of any rate change with the option to cancel. If the applicable GST rate 
                    (or other included tax or duty) changes during your one-year term, we will accordingly adjust the tax-inclusive price for your plan mid-term on your next billing date.
                </StyledLeftText>    
                <StyledLeftText>
                    If your primary payment method fails, you authorise us to charge any other payment method in your account. If you have not provided us a backup payment method(s) and you 
                    fail to provide payment, or if all payment methods in your account fail, we may suspend your subscription. You can edit your payment information anytime in your 
                    Me Account page.
                </StyledLeftText>
                <StyledLeftText>
                    For European Economic Area customers, your bank may require you to authenticate your initial purchase using a password, a one-time code sent to your mobile number, or 
                    biometric recognition. When you authenticate, you also authorise us to charge your payment method for your additional purchases without providing us further payment 
                    information or other instructions (i.e., we will initiate future payments independently). Such additional purchases may occur when we automatically charge your payment method 
                    in connection with a recurring subscription or when you add or change licenses or products.
                </StyledLeftText>
                <StyledLeftText size="20px">Cancellation Terms:</StyledLeftText>
                <StyledLeftText>
                    You can cancel your subscription anytime via your Me account page or by contacting Customer Support. Should you cancel your monthly subscription, you’ll be charged 
                    a lump sum amount of 50% of your remaining 12 month contract obligation and your service will continue until the end of that month’s billing period.
                </StyledLeftText>
            
            <StyledButton color="#000000" onClick={acceptTC}>
                Close
            </StyledButton>
            </div>
        </section>
      </div>
    );
}

export default TermsConditionModal;