import React, { Component } from "react";
import styled from "styled-components";
import axios from "axios";
import { API_URL } from "App";
import Initial from "./forms/Initial";
import UploadLogo from "./forms/UploadLogo";
import SelectColor from "./forms/SelectColor";
import Email from "./forms/Email";
import Finish from "./forms/Finish";
import { CustomiseContext } from "./CustomiseContext";
import LoadingIndicator from "LoadingIndicator";
import Toast from "Toast";

export const Forms = {
    INITIAL: 0,
    UPLOAD: 1,
    COLOR: 2,
    FINISH: 3,
    EMAIL: 4,
};

export const Screens = {
    SPLASH: 0,
    MAIN: 1,
    GOALS: 2,
    NOTICEBOARD: 3,
    PLAYLISTS: 4
};

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

//default values inside json files need to be updated
export const AnimationColors = {
    BACKGROUND: [0, 0, 0, 1],
    PRIMARY: [1, 1, 1, 1],
    SECONDARY: [0.850980392157, 0.850980392157, 0.850980392157, 1]
};

class Customise extends Component {
    state = {
        loading: false,
        currentForm: Forms.INITIAL,
        isNextClickable: true,
        file: [],
        assetUrl: "",
        backGroundColor: AnimationColors.BACKGROUND,
        currentScreen: Screens.MAIN,
        primaryColor: AnimationColors.PRIMARY,
        secondaryColor: AnimationColors.SECONDARY,
        errorMessage: "",
        companyName: this.props.user.companyName,
    };

    componentDidMount() {
        if (this.props.user.isCustomComplete) {
            this.setState({
                isNextClickable: true,
                currentForm: Forms.FINISH,
                loading: true
            });

            axios.get(`${API_URL}/company/settings`, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token
                }
            }).then(res => {
                const { logoUrl, backgroundColor, primaryColor, secondaryColor, companyName } = res.data;
                this.setState({
                    assetUrl: logoUrl,
                    isNextClickable: true,
                    currentForm: Forms.FINISH,
                    backGroundColor: backgroundColor,
                    primaryColor: primaryColor,
                    secondaryColor: secondaryColor,
                    companyName: companyName,
                    loading: false
                });
            }).catch(error => {
                return Toast.error(error.message);
            });
        }
    }

    hexToRgbaPercent = (hex) => {
        //  Only do the conversion if a colour has been selected (is a hex value)
        if (typeof hex !== "string" || hex.charAt(0) !== "#" || (hex.length !== 4 && hex.length < 7)) {
            return hex;
        }

        hex = hex.replace("#", "");
        let r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
        let g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
        let b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
        return [ r / 255, g / 255, b / 255, 1];
    }

    nextAvailable = (url) => {
        this.setState({
            isNextClickable: true,
            assetUrl: url
        });
    };

    leftScreen = () => {
        const { currentScreen, currentForm } = this.state;
        const screenIds = this.getSlidesForScreen(currentForm);
        const lastScreenIndex = screenIds.length - 1;

        if (currentScreen <= 0) {
            this.setState({
                currentScreen: lastScreenIndex,
            });
        } else {
            // Add -1 to the index
            this.setState({
                currentScreen: currentScreen - 1,
            });
        }
    };

    rightScreen = () => {
        const { currentScreen, currentForm } = this.state;
        const screenIds = this.getSlidesForScreen(currentForm);
        const lastScreenIndex = screenIds.length - 1;

        // Move to the next screen until reaching the end
        if (currentScreen < lastScreenIndex) {
            // Add +1 to the index
            this.setState({
                currentScreen: currentScreen + 1,
            });
        } else {
            this.setState({
                currentScreen: 0,
            });            
        }
    };

    nextClick = () => {
        const { assetUrl, backGroundColor, primaryColor, secondaryColor } = this.state;
        const backgroundColour = backGroundColor === AnimationColors.BACKGROUND ?
            "#000000" : backGroundColor;
        const primaryColour = primaryColor === AnimationColors.PRIMARY ?
            "#FFFFFF" : primaryColor;
        const secondaryColour = secondaryColor === AnimationColors.SECONDARY ?
            "#D9D9D9" : secondaryColor;

        if (this.state.isNextClickable) {
            if (this.state.currentForm === Forms.INITIAL) {
                this.setState({
                    currentForm: Forms.UPLOAD,
                    isNextClickable: false,
                });
            } else if (this.state.currentForm === Forms.UPLOAD) {
                this.setState({
                    currentScreen: Screens.NOTICEBOARD,
                    currentForm: Forms.COLOR
                });
            } else if (this.state.currentForm === Forms.COLOR) {
                if (this.props.user.isCustomComplete) {
                    axios.put(`${API_URL}/company/settings`, {
                        logoUrl: assetUrl,
                        backgroundColor: backgroundColour,
                        primaryColor: primaryColour,
                        secondaryColor: secondaryColour,
                    }, {
                        headers: {
                            Authorization: "Bearer " + this.props.user.token
                        }
                    })
                        .then(res => {
                            this.setState({ currentForm: Forms.FINISH });
                        })
                        .catch(error => {
                            return Toast.error(error.message);
                        });
                } else {
                    axios.post(`${API_URL}/company/settings`, {
                        logoUrl: assetUrl,
                        backgroundColor: backgroundColour,
                        primaryColor: primaryColour,
                        secondaryColor: secondaryColour,
                    }, {
                        headers: {
                            Authorization: "Bearer " + this.props.user.token
                        }
                    })
                        .then(res => {
                            this.setState({
                                currentForm: Forms.EMAIL,
                                backGroundColor: backgroundColour,
                                primaryColor: primaryColour,
                                secondaryColor: secondaryColour,
                            });
                            this.props.completeCustomize();
                        })
                        .catch(error => {
                            return Toast.error(error.message);
                        });
                }
            } else if (this.state.currentForm === Forms.EMAIL) {
                this.setState({ currentForm: Forms.FINISH });
            }
        }
    };

    previousClick = () => {
        this.setState({
            currentScreen: Screens.MAIN,
            currentForm: Forms.UPLOAD
        });
    };

    handleChange = (name, value) => {
        this.setState({
            ...this.state,
            [name]: value,
        });
    };

    setColor = (color) => {
        //detect if this color comes from color picker,  or converted hex color result when loading
        if (color.rgb) {
            return [color.rgb.r / 255, color.rgb.g / 255, color.rgb.b / 255, 1];
        } else {
            return color;
        }
    };

    updateBackgroundColor= (color) => {
        if (color.hex !== this.state.primaryColor && color.hex !== this.state.secondaryColor) {
            this.setState({
                backGroundColor: color.hex
            });
        }
    };

    updatePrimaryColor = (color) => {
        if (color.hex !== this.state.backGroundColor && color.hex !== this.state.secondaryColor) {
            this.setState({
                primaryColor: color.hex
            });
        }
    };

    updateSecondaryColor = (color) => {
        if (color.hex !== this.state.backGroundColor && color.hex !== this.state.primaryColor) {
            this.setState({
                secondaryColor: color.hex
            });
        }
    };

    /**
     * @param {int} currentForm
     * @return {int[]}
     */
    getSlidesForScreen(currentForm) {
        switch (currentForm) {
        case Forms.UPLOAD:
            return [ Screens.SPLASH, Screens.MAIN ];
        default:
            return [ Screens.SPLASH, Screens.MAIN, Screens.GOALS, Screens.NOTICEBOARD, Screens.PLAYLISTS ];
        }
    }

    /**
     * Reads colours from state. If any one (or more) is still in hex format #RRGGBB it will be
     * converted to an RGBA arrays of floats.
     * @returns {{backgroundColor: float[], primaryColor: float[], secondaryColor: float[]}}
     */
    buildColourPalette = () => {
        let { primaryColor, secondaryColor, backGroundColor } = this.state;
        primaryColor = this.hexToRgbaPercent(primaryColor);
        secondaryColor = this.hexToRgbaPercent(secondaryColor);
        backGroundColor = this.hexToRgbaPercent(backGroundColor);
        return {
            primaryColor: primaryColor,
            secondaryColor: secondaryColor,
            backgroundColor: backGroundColor
        };
    };

    render() {
        const { loading, currentForm, file, currentScreen, isNextClickable, backGroundColor, primaryColor,
            secondaryColor, assetUrl, companyName } = this.state;
        const screenIds = this.getSlidesForScreen(currentForm);

        if (loading) {
            return <LoadingIndicator />;
        }

        return (
            <Container>
                <CustomiseContext.Provider value={{
                    nextAvailable: this.nextAvailable,
                    nextClick: this.nextClick,
                    file: file,
                    assetUrl: assetUrl,
                    currentForm: currentForm,
                    screenIds: screenIds,
                    token: this.props.user.token,
                    leftScreen: this.leftScreen,
                    rightScreen: this.rightScreen,
                    currentScreen: currentScreen,
                    uploadFile: this.handleChange,
                    isNextClickable: isNextClickable,
                    previousClick: this.previousClick,
                    backGroundColor: backGroundColor,
                    primaryColor: primaryColor,
                    secondaryColor: secondaryColor,
                    colorChange: this.handleChange,
                    editClick: this.previousClick,
                    companyName: companyName,
                    colourPalette: this.buildColourPalette(),
                    updateBackgroundColor: this.updateBackgroundColor,
                    updatePrimaryColor: this.updatePrimaryColor,
                    updateSecondaryColor: this.updateSecondaryColor,
                    isComplete: this.props.user.isCustomComplete,
                    email: this.props.user.email
                }}>
                    { currentForm === Forms.INITIAL &&
                        <Initial />}
                    { currentForm === Forms.UPLOAD &&
                        <UploadLogo />}
                    { currentForm === Forms.COLOR &&
                        <SelectColor />}
                    { currentForm === Forms.EMAIL &&
                        <Email />}
                    { currentForm === Forms.FINISH &&
                        <Finish />}
                </CustomiseContext.Provider>
            </Container>
        );
    }
}

export default Customise;