import React, { useState, useRef } from "react";
import Text from "components/Text";
import styled, { css } from "styled-components";

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 75% 10%;

    height: 100%;
    width: 50%;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #00000029;
    border-radius: 48px;

    :nth-child(0) {
        grid-column: 1;
        grid-row: 1;
    }

    :nth-child(1) {
        grid-column: 1;
        grid-row: 1;
    }

    :nth-child(2) {
        grid-column: 1;
        grid-row: 1;
    }
`;

const GridBody = styled.div`
    transform: translateX(12%);
    width: 80%;
    ${p => p.borderedTop && css`
        border-top: 1px solid black;
    `}
    ${p => p.borderedBottom && css`
        border-bottom: 1px solid black;
    `}
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;

    transform: translateY(20%);
    height: 100%;
    user-select: none;
    pointer-events: none;

    font-size: 1.8rem;
    font-weight: 500;
    font-family: Affogato;
    letter-spacing: 0.75px;
    color: #23262D;

    :nth-child(0) {
        grid-column: 1;
        grid-row: 1;
    }

    :nth-child(1) {
        grid-column: 1;
        grid-row: 1;
    }
`;

const GenericButtonStyle = css`
    height: 2.5rem;    
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;

    font-family: Affogato;
    letter-spacing: 0.35px;
    line-height: 2.5rem;
    color: white;
    text-align: center;

    user-select: none;
    cursor: pointer;
`;

const CloseButton = styled.div`
    width: 9.375rem;
    display: inline-block;
    ${GenericButtonStyle}
    background: #000000 0% 0% no-repeat padding-box;
`;

const OuterFlexBox = styled.div`
    display: flex;
    flex-direction: column;

    min-width: 100%;
    min-height: 100%;
`;

const InnerFlexBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: ${p => p.alignItems || 'start'};
    margin-top: 30px;
`;

function CancellationTermsModal(props) {
    const { closeCallback } = props;    

    return(
        <Container>
            <GridBody>
                <Header>
                    <Text align="left" color="#000000" size="30px" weight="500">Subscription & Cancellation Terms</Text>
                </Header>
            </GridBody>
            <GridBody bordered style={{ minHeight: "100%", height: "100%", marginBottom: "20px" }}>
                <OuterFlexBox style={{ minHeight: "100%", height: "100%"}}>
                    <Text align="left" color="#000000" size="20px">
                        Subscription Terms:
                    </Text>
                    <Text align="left" color="#000000">                    
                        Your subscription begins as soon as your initial payment is processed. Your subscription will automatically renew annually or 
                        monthly without notice until you cancel. You authorise us to store your payment method(s) and to automatically charge your 
                        payment method(s) annually or monthly until you cancel.
                    </Text>
                    <Text align="left" color="#000000">
                        We will automatically charge you the then-current rate for your plan, plus applicable taxes (such as GST if the rate does not 
                        include it), every month of your annual contract until you cancel. We may change your plan’s rate each annual renewal term, and 
                        we will notify you of any rate change with the option to cancel. If the applicable GST rate (or other included tax or duty) changes 
                        during your one-year term, we will accordingly adjust the tax-inclusive price for your plan mid-term on your next billing date.
                    </Text>
                    <Text align="left" color="#000000">
                        If your primary payment method fails, you authorise us to charge any other payment method in your account. If you have not provided 
                        us a backup payment method(s) and you fail to provide payment, or if all payment methods in your account fail, we may suspend your 
                        subscription. You can edit your payment information any time in your Daily Fix Me Account page.
                    </Text>
                    <Text align="left" color="#000000">
                        For European Economic Area customers, your bank may require you to authenticate your initial purchase using a password, a one-time code 
                        sent to your mobile number, or biometric recognition. When you authenticate, you also authorise us to charge your payment method for your 
                        additional purchases without providing us further payment information or other instructions (i.e., we will initiate future payments independently). 
                        Such additional purchases may occur when we automatically charge your payment method in connection with a recurring subscription or when you add or 
                        change licenses or products. 
                    </Text>
                    
                    <Text align="left" color="#000000" size="20px">Cancellation Terms:</Text>
                    <Text align="left" color="#000000">
                        You can cancel your subscription any time via your Daily Fix Me account page or by contacting Customer Support. Should you cancel your 
                        monthly subscription, you’ll be charged a lump sum amount of 50% of your remaining 12 month contract obligation and your service will 
                        continue until the end of that month’s billing period.
                    </Text>
                </OuterFlexBox>
            </GridBody>
            <GridBody>
                <OuterFlexBox>
                    <InnerFlexBox alignItems="start">
                        <div style={{ display: "flex", flexDirection: "row", alignItems:"start"}}>
                        <CloseButton onClick={() => closeCallback()}>Close</CloseButton>
                        </div>
                    </InnerFlexBox>
                </OuterFlexBox>
            </GridBody>
        </Container>
    );
}

export default CancellationTermsModal;