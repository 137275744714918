import React from "react";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { UserContext } from "App/UserContext";
import SingleLogoGlow from "img/Logo.png";
import LogoutImg from "img/logout.svg";
import BackgroundContainer from "components/BackgroundContainer";
import Text from "components/Text";
import FlexContainer from "components/FlexContainer";

const Container = styled(FlexContainer)`
    flex-direction: row;
    flex-shrink: 0;
    background-image: linear-gradient(to bottom, #f4f4f4, #ffffff);
    height: auto;
    width: 300px;
`;

const GradientContainer = styled(BackgroundContainer)`
    background: linear-gradient(150deg, #BE8BDC, #86E3C3);
`;

const Logo = styled.img`
    width: 126px;
    height: 60px;
`;

const LoggedInLink = styled(Link)`
    padding: 12px 20px;
    width: 160px;
    border: 1px solid transparent;
    border-radius: 20px;
    color: #51315D;
    margin-top: ${p => p.margintop || "30px"};
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    ${p => Boolean(p.ishighlighted) && `
        background-color: #51315D;
        color: white;
        text-shadow: 0 0 6px white;
    `}
    ${p => Boolean(p.isclickable) || `
        pointer-events: none;
        cursor: default;
        color: #51315D;
        opacity: 0.4;
    `}
`;

function LeftNavigation(props) {
    const { location } = props;

    /**
     * Using straight booleans as attribute values in components causes errors to be logged. A simple workaround is to
     * use integer values that will evaluate to truthy/falsy later. Discussed on a Stack Overflow thread:
     * https://stackoverflow.com/questions/31163693/how-do-i-conditionally-add-attributes-to-react-components
     * @param {boolean} boolValue
     * @returns {number}
     */
    const asInteger = (boolValue) => boolValue ? 1 : 0;

    const currentPageIs = path => asInteger(location.pathname === path);
    
    const greeting = (() => {
        let now = moment().format("HH");
        let greeting;
        let splitAfternoon = 12;
        let splitEvening = 17;
        if (now >= splitAfternoon && now < splitEvening) {
            greeting = "Afternoon";
        } else if (now >= splitEvening) {
            greeting = "Evening";
        } else {
            greeting = "Morning";
        }
        return greeting;
    });

    const logout = (() => {
        props.logout();
    });

    return (
        <UserContext.Consumer>
            {user => {
                if (user !== null) {
                    return (
                        <Container>
                            <GradientContainer height="100%" location="left" width="100%">
                                <FlexContainer alignItems="center" minHeight="100%" padding="60px 0">
                                    <FlexContainer alignItems="center">
                                        <Logo src={SingleLogoGlow} />
                                        <Text size="25px" color="white" weight="500" margin="0">
                                            {greeting()}, <br/> {user.firstName} {user.lastName}
                                        </Text>
                                    </FlexContainer>
                                    
                                    <FlexContainer height="40%" margin="auto" width="100%" alignItems="center">
                                        <LoggedInLink margintop="0" to="/active" ishighlighted={currentPageIs("/active")} isclickable={1}>
                                            Subscription
                                        </LoggedInLink>
                                        <LoggedInLink to="/customise" ishighlighted={currentPageIs("/customise")}
                                            isclickable={asInteger(user.isActive)}>
                                            My Me App
                                        </LoggedInLink>
                                        <LoggedInLink to="/employList" ishighlighted={currentPageIs("/employList")}
                                            isclickable={asInteger(user.isActive && user.isCustomComplete)}>
                                                Employees
                                        </LoggedInLink>
                                        <LoggedInLink to="/noticeboard" ishighlighted={currentPageIs("/noticeboard")}
                                            isclickable={asInteger(user.isActive && user.isCustomComplete)}>
                                                Noticeboard
                                        </LoggedInLink>
                                        <LoggedInLink to="/statistics" ishighlighted={currentPageIs("/statistics")}
                                            isclickable={asInteger(user.isActive && user.isCustomComplete)}>
                                                Statistics
                                        </LoggedInLink>
                                        <LoggedInLink to="/setting" ishighlighted={currentPageIs("/setting")}
                                            isclickable={asInteger(user.isActive)}>
                                                Account Settings
                                        </LoggedInLink>
                                    </FlexContainer>
                                    
                                    <FlexContainer alignItems="center" justifyContent="center" marginTop="32px">
                                        <LoggedInLink to="/login" ishighlighted={0} isclickable={1} margintop="0" style={{ display: "inline-flex", justifyContent: "center" }}
                                            onClick={logout}>
                                            <Text margin="0 5px 0 0" padding="0" color="#51315D">Logout</Text>
                                            <img src={LogoutImg} alt="LOGOUT" style={{ height: "20px" }}/>
                                        </LoggedInLink>
                                    </FlexContainer>
                                </FlexContainer>
                            </GradientContainer>
                        </Container>
                    );
                }
            }}
        </UserContext.Consumer>
    );
}

export default withRouter(LeftNavigation);