import React, { Component } from "react";
import styled from "styled-components";
import FlexContainer from "components/FlexContainer";
import Text from "components/Text";
import LeftText from "../components/LeftText";
import Checkbox from "components/Checkbox";
import Button from "components/Button";
import BusinessSubcriptionPhone from "img/BusinessSubcriptionPhone.png";
import { SubscriptionContext } from "../SubscriptionContext";

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: auto;
`;

const CheckboxContainer = styled.div`
    display: flex;
`;

export const SideContainer = styled(FlexContainer)`
    width: ${p => p.width || "40%"};
    min-width: 400px;
    margin-left: ${p => p.left || "10%" };
    align-items: start;
    margin-top: ${p => p.top};
    margin-right: ${p => p.right};
    margin-bottom: ${p => p.bottom};
`;

export const StyledButton = styled(Button)`
    color: white;
    background-color: #51315D;
    border-radius: 20px;
    font-weight: 500;
    border: 1px solid #292C33;
    padding: 10px 40px;
    margin-top: ${p => p.marginTop};
    &:focus::-webkit-input-placeholder {
        opacity: 0;
        transform: translate(70%);
        -webkit-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }
`;

const Image = styled.img`
    width: 600px;
    
`;

class Initial extends Component {
    state = {
        errorMessage: "",
        checked: false,
    };

    static contextType = SubscriptionContext;

    continue = () => {
        if (this.context.acceptToc) {
            // Facebook Pixel Tracking
            window.fbq && window.fbq("track", "InitiateCheckout");

            this.context.nextClick();
        } else {
            this.setState({
                errorMessage: "Please accept terms and conditions first."
            });
        }
    };

    checkBox = () => {
        this.setState({
            checked: !this.state.checked
        });

        this.context.updateValue("acceptToc", true);
    };

    render() {
        return (
            <Container>
                <FlexContainer width="600px">
                    <LeftText style={{ fontSize: "40px", margin: "0 0 26px 0"}}>
                        Business Subscription
                    </LeftText>
                    <LeftText style={{ fontSize: "14px", margin: 0, maxWidth: "530px", lineHeight: "1.3" }}>
                        Get a daily fix of motivation that will encourage your workplace to perform at
                        their absolute best. Start by customising the Me app for your business today 
                        and experience the benefits!
                    </LeftText>
                    <LeftText size="14px" color="#51315D" margin="0">
                        <ul>
                            <li>General Quotes</li>
                            <li>Quote Articles</li>
                            <li>Daily Quote Reminders</li>
                            <li>Create & Submit Your Own Quotes</li>
                            <li>Goal Tracker</li>
                            <li>Employee/Client Noticeboard</li>
                            <li>Facts & Videos</li>
                            <li>Me Playlists</li>
                            <li>Add Your Company Playlist</li>
                            <li>Customised Me App</li>
                            <li>Access To All New Premium Features As Released</li>
                            <li>No Ads</li>
                        </ul>
                    </LeftText>
                    <LeftText size="40px" color="#23262D" align="left" weight="400" margin="0">
                        <p style={{ color: "#51315D", fontWeight: "500",
                            display: "inline-block", margin: 0 }}>
                            $3.49 AUD
                        </p>/mo per user
                    </LeftText>
                    <LeftText size="14px" margin="0 0 20px 0" style={{ fontWeight: "500" }}>
                        *Fixed 12 Month Subscription - Excluding government charges
                    </LeftText>
                    <CheckboxContainer align="baseline">
                        <label>
                            <Checkbox
                                checked={this.state.checked}
                                onChange={this.checkBox} />
                        </label>
                        <Text color="#23262D" margin="0 0 30px 10px">
                                <b>I accept the</b>&nbsp;
                            <a
                                href="https://s3-ap-southeast-2.amazonaws.com/dailyfixme.content/Terms+and+Conditions.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#23262D" }}>
                                <b>Terms and Conditions</b>
                            </a>
                        </Text>
                    </CheckboxContainer>
                    <StyledButton marginRight="auto" onClick={this.continue}>
                        GET STARTED
                    </StyledButton>
                    <Text color="red">
                        {this.state.errorMessage}
                    </Text>
                </FlexContainer>
                <Image src={BusinessSubcriptionPhone} alt=""/>
            </Container>
        );
    }
}

export default Initial;