import { isValidPhoneNumber, formatPhoneNumberIntl, parsePhoneNumber } from "react-phone-number-input";

const ErrorList = {
    EMPTY_NAME: 1,
    MOBILE_FORMAT: 2,
    EMPTY_MOBILE: 3,
    EMPTY_BIRTHDATE: 4,
    EMPTY_COMPANY_NAME: 5,
    DIFF_PASS: 6,
    EMAIL_FORMAT: 7,
    EMPTY_EMAIL: 8,
    EMPTY_FIRST_NAME: 9,
    EMPTY_PASS: 10,
    EMPTY_REPASS: 11,
    MIN_LENGTH: 12,
};

const ErrorStrings = {
    [ErrorList.EMPTY_NAME]: "* Please enter your name",
    [ErrorList.EMPTY_BIRTHDATE]: "* Please enter your date of birth",
    [ErrorList.MOBILE_FORMAT]: "* Please make sure mobile number format is correct",
    [ErrorList.EMPTY_COMPANY_NAME]: "* Please enter your company name",
    [ErrorList.DIFF_PASS]: "* Please make sure passwords are same",
    [ErrorList.EMAIL_FORMAT]: "* Please make sure Email format is correct",
    [ErrorList.EMPTY_EMAIL]: "* Please enter an email address",
    [ErrorList.EMPTY_FIRST_NAME]: "* Please enter a first name",
    [ErrorList.EMPTY_PASS]: "* Please enter a password",
    [ErrorList.EMPTY_REPASS]: "* Please enter a re-enter password",
    [ErrorList.MIN_LENGTH]: "* Please enter a password at least at least six characters length",
};

function FormValidation(name, value) {
    const errors = {};

    switch (name) {
        case "fullName":
            if (value.length == 0) {
                Object.assign(errors, {
                    fullName: ErrorStrings[ErrorList.EMPTY_NAME]
                });
            } 
        break;

        case "dob":
            if(value.length == 0) {
                Object.assign(errors, {
                    dob: ErrorStrings[ErrorList.EMPTY_BIRTHDATE]
                });
            }
        break;

        case "mobileNumber":
            if (value && value.length > 0) {
                let phoneStr = (value.toString()).replace(/\s+/g, "");
                //phoneStr = phoneStr[0] !== "+" ? `+${this.state.countryCallingCode}${phoneStr}` : phoneStr;
                if(!isValidPhoneNumber(phoneStr)) {
                    Object.assign(errors, {
                        mobileNumber: ErrorStrings[ErrorList.MOBILE_FORMAT]
                    });
                }
            } else if (value.length == 0) {
                Object.assign(errors, {                    
                    mobileNumber: ErrorStrings[ErrorList.EMPTY_MOBILE]
                });
            }
        break;
        
        case "companyName":
            if(value.length < 6) {
                Object.assign(errors, {
                    businessName: ErrorStrings[ErrorList.EMPTY_COMPANY_NAME]
                });
            }
        break;

        case "email":
            if (
                !new RegExp(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ).test(value)
            ) {
                Object.assign(errors, {
                    email: "* Please enter a valid email address"
                });
            }
            break;

        case "password":
            if (
                !new RegExp(/^.{6,}$/).test(value.newInputValue) || !new RegExp(/^.{6,}$/).test(value.retypedInputValue)
            ) {
                // regex for A-Z: (?=.*?[A-Z]) 
                // regex for a-z: (?=.*?[a-z])
                // regex for 0-9: (?=.*?[0-9])
                // regex for 8 char or longer: .{8,}
                //Password should be atleast 8 charaters and containing uppercase,lowercase and numbers
                Object.assign(errors, {
                    password: ErrorStrings[ErrorList.MIN_LENGTH]
                });
            }
            else if ( value.newInputValue !== value.retypedInputValue ) {
                Object.assign(errors, {
                    password: ErrorStrings[ErrorList.DIFF_PASS]
                });
            }
            break;

        default:
            break;
    }

    return errors;
}

export default FormValidation;