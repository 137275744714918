import React from "react";
import styled from "styled-components";
import FlexContainer from "components/FlexContainer";

const StyleContainer = styled(FlexContainer)`
    flex-direction: row;
    flex: 1;
    padding-left: ${p => p.left};
    margin-top: ${p => p.top};
`;

function PlanContainer(props) {
    const { children, ...styleProps } = props;
    return (
        <StyleContainer {...styleProps}>
            {children}
        </StyleContainer>
    );
}

export default PlanContainer;