import React, { Component } from "react";
import styled from "styled-components";
import FlexContainer from "components/FlexContainer";
import LeftText from "../../Subscription/components/LeftText";
import { StyledButton } from "Subscription/forms/Initial";
import SubscriptionPhone from "img/CustomizeInitial.png";
import { CustomiseContext } from "../CustomiseContext";

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: auto;
`;

const Image = styled.img`
    width: 500px;
    height: auto;
    left: -100px;
    position: relative;
`;

class Initial extends Component {
    static contextType = CustomiseContext;

    render() {
        const { nextClick } = this.context;
        return (
            <Container>
                <FlexContainer minWidth="500px" alignItems="center">
                    <LeftText size="2.5em" weight="500" margin="0 ">
                        My Me App
                    </LeftText>
                    <LeftText size="1em" margin="0.8em 0 2em" align="center" weight="500" lineHeight="1.5">
                        Let’s get started! Create an inclusive team environment by personalising <br/>
                        the app to include your company colours and logo. Please note that <br/>
                        additional changes can be made once customisation has been completed.
                    </LeftText>
                    <Image src={SubscriptionPhone} alt=""/>
                    <StyledButton onClick={nextClick} fontWeight="500" marginTop="0.5rem">
                        Get Started!
                    </StyledButton>
                </FlexContainer>
            </Container>
        );
    }
}

export default Initial;