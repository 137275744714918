import React, { Component } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import FlexContainer from "components/FlexContainer";

/**
 * Background component for non-home, logged-out routes - /signUp, /login, /forgotPassword, /resetPassword.
 * Gradient background using DFM evening theme.
 */
class SignUpContainer extends Component {
    render() {
        const { children } = this.props;

        return (
            <FlexContainer image="linear-gradient(119deg, #BE8BDC, #86E3C3)" alignItems="center" minHeight="100vh">
                <Header />
                {children}
                <Footer />
            </FlexContainer>
        );
    }
}

export default SignUpContainer;