import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex: 1;
    align-items: ${p => p.items || "center"};
    justify-content: ${p => p.content || "center"};
    width: ${ p => p.width || "100%"};
    height: ${p => p.height || "100vh"};
    background: url(${p => p.url }) no-repeat 
        ${p => p.position || "center"} ${p => p.location || "top"};
    background-size: ${p => p.size || "cover"};
    background-image: url(${p => p.image});
    flex-direction: ${p => p.direction};
    background-color: ${p => p.color};
    min-width: ${p => p.minWidth};
    margin-top: ${p => p.top};
    margin-bottom: ${p => p.bottom};
`;

function BackgroundContainer(props) {
    const { children, ...styleProps } = props;
    return (
        <Container {...styleProps} >
            {children}
        </Container>
    );
}

export default BackgroundContainer;