import React, { Component } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { API_URL } from "App";
import Text from "components/Text";
import FormField from "components/FormField";
import NextButton from "components/NextButton";
import Form from "components/Form";
import FlexContainer from "components/FlexContainer";
import LoadingIndicator from "LoadingIndicator";

const ForgotError = {
    EMPTY_EMAIL: 1,
    EMAIL_FORMAT: 2,
};

const ErrorStrings = {
    [ForgotError.EMPTY_EMAIL]: "* Please enter an email address",
    [ForgotError.EMAIL_FORMAT]: "* Please make sure email format is correct",
};

const NavigationBackToLogIn = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 2.4rem;
    background-color: white;
    border: none;
    border-radius: 1.2rem;
    padding: 0 50px;
    text-decoration: none;
`;

const CentredText = styled.span`
    color: #51315D;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
`;

const ContentForm = styled(Form)`
    width: 48rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

class ForgotPassword extends Component {
    state = {
        email: "",
        inputErrors: [],
        isComplete: false,
        errorMessage: "",
        loading: false,
    }

    continue = (e) => {
        const { email } = this.state;

        e.preventDefault();
        let errorArray = [];

        if (email.trim() === "") {
            errorArray.push(ForgotError.EMPTY_EMAIL);
        } else if (! /^(.+)@(.+)\.(.+)$/.test(email)) {
            errorArray.push(ForgotError.EMAIL_FORMAT);
        } else {
            this.setState({ loading: true });

            axios.post(`${API_URL}/company/forgotPassword`, {
                email: email
            })
                .then(res => {
                    this.setState({ 
                        isComplete: true,
                        loading: false
                    });
                })
                .catch(error => {
                    if (error.response) {
                        this.setState({
                            errorMessage: error.response.data.error,
                            loading: false
                        });
                    } else {
                        this.setState({
                            errorMessage: "Unknown error",
                            loading: false
                        });
                    }
                });
        }

        this.setState({ inputErrors: errorArray });
    }

    render() {
        const { errorMessage, inputErrors, isComplete, email, loading } = this.state;
        const emailError = inputErrors.filter(error => error === ForgotError.EMPTY_EMAIL 
            || error === ForgotError.EMAIL_FORMAT).map(error => ErrorStrings[error]);

        return (
            <FlexContainer alignItems="center" justifyContent="center" flexGrow="1">
                { !isComplete ?
                    <ContentForm>
                        <Text color="red"><strong>{errorMessage}</strong></Text><br/>
                        <Text size="18px" weight="500" color="white" shadow="0px 0px 20px #FFFFFF">
                            <strong>Forgot your password?</strong>
                        </Text>
                        <Text margin="10px 0 30px" color="white">
                            Please enter the email address you provided during the registration process.
                        </Text>
                        <FormField
                            placeholder="Email Address"
                            align="center"
                            onChange={(e) => this.setState({ email: e.target.value })}
                            errors={emailError}
                        />
                        <FlexContainer width="100%" height="4em" justifyContent="center">
                            { loading ?
                                <LoadingIndicator height="7px" width="7px"/>
                                :
                                <NextButton alt="Forgot" onClick={this.continue} />
                            }
                        </FlexContainer>
                        <FlexContainer marginTop="17px" alignItems="center">
                            <Link to="/login" style={{ color: "white" }}>
                                Back to <b>login</b>
                            </Link>
                        </FlexContainer>
                    </ContentForm>
                    :
                    <ContentForm>
                        <Text size="18px" weight="600" color="white" shadow="0px 0px 20px #FFFFFF">
                            Your password reset has been sent to you!
                        </Text>
                        <Text margin="0.5rem 0 0" weight="500" size="15px" color="white">
                            An email has been sent to {email}.
                        </Text>
                        <Text margin="0 0 3rem" weight="500" size="15px" color="white">
                            Follow the instructions in the email to reset your password.
                        </Text>
                        <NavigationBackToLogIn to="/login">
                            <CentredText>
                                Back to login
                            </CentredText>
                        </NavigationBackToLogIn>
                    </ContentForm>
                }
            </FlexContainer>
        );
    }
}

export default ForgotPassword;