import React from "react";
import styled from "styled-components";
import Text from "components/Text";

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-template-rows: 100%;
    text-align: ${p => p.align || "left"};
`;

const GridChild = styled.div`
    margin: 0 10px 0 10px;
`;

const LeftText = styled(Text)`
    text-align: ${p => p.align || "left"};
    color: #000000;
    font-size: 14px;
`;

function GridRow(props) {
    const { mediumText, regularText, marginTop, size, color, 
        self, bottom, reverse, middleText, labelName, labelWeight, labelNameLight, labelNameLightColor, labelNameLightWeight, labelText, labelTextWeight } = props;
    return (
        <GridContainer>
            <GridChild>
                <LeftText weight={labelWeight}>{labelName} {labelNameLight && <span style={{color: labelNameLightColor, fontWeight: labelNameLightWeight}}>{" "+ labelNameLight}</span>}</LeftText>
            </GridChild>
            <GridChild>
            <LeftText weight={labelTextWeight?labelTextWeight:"normal"}>{labelText}</LeftText>
            </GridChild>
        </GridContainer>
    );
} 

export default GridRow;