import React, { useState, useRef } from "react";
import Checkbox from "components/Checkbox";
import Button from "components/Button";
import Text from "components/Text";
import styled, { css } from "styled-components";
import { CSVReader } from "react-papaparse";

import EmployeeTemplate from "template/EmployeeTemplate.csv";
import ClientTemplate from "template/ClientTemplate.csv";

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 15% 70% 25%;

    height: 75%;
    width: 60%;

    margin: 20%;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #00000029;
    border-radius: 48px;

    :nth-child(0) {
        grid-column: 1;
        grid-row: 1;
    }

    :nth-child(1) {
        grid-column: 1;
        grid-row: 1;
    }

    :nth-child(2) {
        grid-column: 1;
        grid-row: 1;
    }
`;

const GridBody = styled.div`
    transform: translateX(8.235%);
    width: 87%;
    ${p => p.borderedTop && css`
        border-top: 1px solid black;
    `}
    ${p => p.borderedBottom && css`
        border-bottom: 1px solid black;
    `}
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;

    transform: translateY(35%);
    height: 100%;
    user-select: none;
    pointer-events: none;

    font-size: 1.8rem;
    font-weight: 500;
    font-family: Affogato;
    letter-spacing: 0.75px;
    color: #23262D;

    :nth-child(0) {
        grid-column: 1;
        grid-row: 1;
    }

    :nth-child(1) {
        grid-column: 2;
        grid-row: 1;
    }
`;

const GenericButtonStyle = css`
    height: 2.5rem;
    width: 9.375rem;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;

    font-family: Affogato;
    letter-spacing: 0.35px;
    line-height: 2.5rem;
    color: white;
    text-align: center;

    user-select: none;
    cursor: pointer;
`;

const StyledButton = styled(Button)`
    border: 1px solid #000000;
    color: ${p => p.color || "white"};
    padding: 10px 30px 10px;
    border-radius: 20px;
    background-color: ${ p => p.backgroundColor || "#2e3036"};
    width: 227px;
`;

const FinishButton = styled.div`
    display: inline-block;
    ${GenericButtonStyle}
    background: #006CFF 0% 0% no-repeat padding-box;
    font-weight: 400;
    color: #FFFFFF;
`;

const UploadButton = {
    opacity: 0,
    position: "relative",
    top: "-35px",    
    width: "222px",
    height: "33px"
};

const CloseButton = styled.div`
    display: inline-block;
    ${GenericButtonStyle}
    background: #2D3037 0% 0% no-repeat padding-box;
`;

const UploadContentButtonStyle = css`
    height: 2.5rem;
    border-radius: 20px;

    font-family: Affogato;
    letter-spacing: 0.35px;
    line-height: 2.5rem;
    color: black;
    text-align: center;

    user-select: none;
    cursor: pointer;
    border: 1px black solid;
`;

const UploadContentButton = styled.div`
    ${UploadContentButtonStyle}
    font-family: Affogato, Medium;
    background: #000000;
    border-radius: 20px;
    color: #FFFFFF;
`;

const OuterFlexBox = styled.div`
    display: flex;
    flex-direction: column;

    min-width: 100%;
    min-height: 100%;
`;

const InnerFlexBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: ${p => p.alignItems || 'start'};
    margin-top: 10px;
`;

const DirtyState = {
    FALSE: 0,
    TRUE: 1,
    UNKNOWN: 2,
};

function AccessCodeModal(props) {
    const { closeCallback, generate, user } = props;    
    const [confirm, setConfirm] = useState(null);
    const fileInput = useRef(null);
    
    return(
        <Container>
            <GridBody style={{ paddingBottom: "10%" }}>
                <Header>
                    <Text align="left" color="#000000" size="30px">Generate New Access Code</Text>
                </Header>
            </GridBody>
            <GridBody bordered style={{ minHeight: "100%", height: "100%", marginBottom: "20px" }}>
                <OuterFlexBox style={{ minHeight: "100%", height: "100%", borderBottom: "1px solid #000" }}>
                    <Text align="left" color="#000000">
                        Are you sure you would like to generate a new code for this user:
                    </Text>
                    <Text align="left" color="#000000" weight="600">
                        {user.email}
                    </Text>
                    <Text align="left" color="#51315D">
                        *Once a new code has been generated the selected user will be notified by email with the new code. 
                        New codes will also be updated in the employee/client tables. Users logged in with old codes will be signed out of the app.
                    </Text>
                    <Text align="left" color="#000000" weight="600">
                        <label>
                        <Checkbox readOnly
                        checked={confirm}
                        onChange={() => setConfirm(true)} />
                        </label> I would like to generate a new code for this user.
                    </Text>
                </OuterFlexBox>
            </GridBody>
            <GridBody>
                <OuterFlexBox>
                    <InnerFlexBox alignItems="end">
                        <div style={{ display: "flex", flexDirection: "row", alignItems:"start"}}>
                        <CloseButton onClick={() => closeCallback()}>Close</CloseButton>
                        <FinishButton onClick={() => generate(user.uuid)} style={{marginLeft: "20px"}}>Generate</FinishButton>
                        </div>
                    </InnerFlexBox>
                </OuterFlexBox>
            </GridBody>
        </Container>
    );
}

export default AccessCodeModal;