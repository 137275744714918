export const YearFormat = {
    AMERICAN: 0, // mm-dd-yyyy
    INTL: 1, // dd-mm-yyyy
    ISO_8601: 2, // yyyy-mm-dd
    UNKNOWN: 3, // Whatever is typed.
};

const DEFAULT_COUNTRY_CODE = "US";

class FormatAsYouType {
    static dob = (value, countryCode = DEFAULT_COUNTRY_CODE) => {
        let dob = value ? value.replace(/[-/]/g, "") : "";
        let section = [];
        let divider = "/";

        switch (FormatAsYouType.formatType(dob, countryCode)) {
        case YearFormat.AMERICAN: // mm-dd-yyyy
        case YearFormat.INTL: // dd-mm-yyyy
            section = [
                dob.substr(0, 2),
                dob.length > 2 ? divider : "",
                dob.length > 2 ? dob.substr(2, 2) : "",
                dob.length > 4 ? divider : "",
                dob.length > 4 ? dob.substr(4, dob.length - 4) : "",
            ];
            break;
        case YearFormat.ISO_8601: // yyyy-mm-dd
            section = [
                dob.substr(0, Math.min(4, dob.length)),
                dob.length > 4 ? divider : "",
                dob.length > 4 ? dob.substr(4, 2) : "",
                dob.length > 6 ? divider : "",
                dob.length > 6 ? dob.substr(6, dob.length - 2) : "",
            ];
            break;
        case YearFormat.UNKNOWN: // Whatever is typed.
        default:
            return value;
        }

        return section.join("");
    };

    static formatType(value, countryCode = DEFAULT_COUNTRY_CODE) {
        let type = YearFormat.UNKNOWN;
        let dob = value ? value.replace(/[-/]/g, "") : "";

        if (dob && dob.length > 2) {
            let start = dob.substr(0, 2);

            // Can only be either ISO or INTL
            if (parseInt(start) === 19 || parseInt(start) === 20) {
                // Example; User has typed 192, which for INTL would mean month 20 something.
                if (parseInt(dob.charAt(2)) >= 2) {
                    type = YearFormat.ISO_8601;
                } else {
                    type = YearFormat.UNKNOWN;

                    // Example; User has typed 20012, which for ISO would mean month 20 something.
                    if (dob.length > 4 && parseInt(dob.charAt(4)) >= 2) {
                        type = YearFormat.INTL;
                    } else if (dob.length > 5) {
                        // This is a bit of a case where any number except 0, 1, and 2 are
                        // valid for ISO_8601, however, only 9 is valid for INTL.
                        // Everything is is invalid/unknown.
                        // Example; User has typed 200119 (20/01/19), which for ISO would mean month 19.
                        if (parseInt(dob.charAt(5)) === 9) {
                            type = YearFormat.INTL;
                        } else if (
                            parseInt(dob.charAt(5)) === 0 ||
                            parseInt(dob.charAt(5)) === 1 ||
                            parseInt(dob.charAt(5)) === 2
                        ) {
                            type = YearFormat.ISO_8601;
                        } else {
                            type = YearFormat.UNKNOWN;
                        }
                    }
                }
            }
            // Otherwise can only be AMERICAN or INTL
            else {
                // We base that on what their country code is, unless the first two digits are larger than 12.
                // If we don't have one, and the inital value isn't larger than 12, we will assume American.
                if (parseInt(start) > 12 || (countryCode && countryCode !== "US")) {
                    type = YearFormat.INTL;
                } else {
                    type = YearFormat.AMERICAN;
                }
            }
        }

        return type;
    }
}

export default FormatAsYouType;
