import React, { useState, useContext } from 'react';
import { FormContext } from './myForm';
import styled from "styled-components";
import Text from "components/Text";
import Button from "components/Button";
import CloseButton from "img/CloseButton.png";
import FormValidation from './FormValidation';

const BorderFormField = styled.input`
    opacity: 0.6;
    width: 100%;
    height: 2.4rem;
    padding: 10px 20px 10px 20px;
    border-radius: 1.2rem;
    border: 1px solid #000000;
    margin: 5px 0;
    box-sizing: border-box;
    font-size: 14px;
    overflow: hidden;
    &:focus {
        background-color: #EFEFEF;
    }
    ::placeholder {
        opacity: initial;
        color: #000000;
        font-weight: 400;
    };
    ::-webkit-inner-spin-button { display: none; }
    ::-webkit-datetime-edit-text { 
        color: #000000; 
        padding: 0 0.3rem; 
    }
    ::-webkit-datetime-edit-month-field { color: #000000; }
    ::-webkit-datetime-edit-day-field { color: #000000; }
    ::-webkit-datetime-edit-year-field { color: #000000; }   
    ::-webkit-calendar-picker-indicator { background: #000000; }
    border: 1px solid #000;
`;

const LeftText = styled(Text)`
    text-align: ${p => p.align || "left"};
    color: #000000;
    font-size: 14px;
`;
const ValidationError = styled(Text)`
    margin: 0 10px;
    color: #FF4B4B;
    font-size: 12px;
    text-align: left;
`;

const EditButton = styled(Button)`
    background: #000000;
    color: #ffffff;
    border-radius: 20px;
    width: 135px;
    height: 2.4rem;
    margin: 5px 0;
`
const SaveButton = styled(Button)`
    background: #51315D;
    color: #ffffff;
    border-radius: 20px;
    width: 135px;
    height: 2.4rem;
    margin: 5px 0;
`;

const DisabledSaveButton = styled(Button)`
    background: #2D3037;
    color: #ffffff;
    border-radius: 20px;
    width: 135px;
    height: 2.4rem;
    margin: 5px 0;
`;

const CancelButton = styled(Button)`
    width: 2.4rem;
    height: 2.4rem;
    background-color: transparent;
    background: url(${CloseButton}) no-repeat right transparent;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-temnplate-rows: 100%;
`;

const GridChild = styled.div`
    margin: 0 10px 0 10px;
`;

function FormInput(props) {
  const { label, name, value, type } = props;

  const formContext = useContext(FormContext);
  const { form, handleFormChange, handleEdit, submit } = formContext;
  
  const [editButton, setEditButton] = useState('');
  const [initialValue, setInitialValue] = useState(value)
  const [errors, setErrors] = useState('');
  
  const handleCancel = (name, initialValue) => {
    setEditButton('');
    handleEdit(name, initialValue);
  }

  const handleSave = (form, name, value) => {
    setErrors(FormValidation(name, value));
    if(Array.isArray(errors) == false || errors.length == 0) {
        setEditButton('');
        setInitialValue(value);
        submit(form, name, value);
    }
  }

  return (
    <GridContainer>
        <GridChild>
            <LeftText>{label}</LeftText>
        </GridChild>
        <GridChild>
            <BorderFormField
                type={type}
                name={name}
                value={form[name] || ''}
                onChange={handleFormChange}/>
                {Object.keys(errors).map((key, i) => (<ValidationError key={i}>{errors[key]}</ValidationError>)) }
        </GridChild>
        {(editButton !== name && form[name] === initialValue) ?
            <GridChild>
                <EditButton onClick={(e) => {setEditButton(name)}}>edit</EditButton>
            </GridChild>
            :
            <GridChild>
                {form[name].length === 0 ? <DisabledSaveButton disabled>save</DisabledSaveButton>
                :
                <SaveButton onClick={(e) => {handleSave(form, name, form[name])}}>save</SaveButton>
                }
                <CancelButton onClick={(e) => {handleCancel(name, initialValue)}}>&nbsp;</CancelButton>
            </GridChild>
        }
    </GridContainer>
  )
}

export default FormInput;