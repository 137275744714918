import React, { useState, useContext } from "react";
import { API_URL } from "App";
import axios from "axios";
import LoadingIndicator from "LoadingIndicator";
import FlexContainer from "components/FlexContainer";
import Toast from "Toast";
import Form from '../components/SecurityForm';
import FormInputGroup from '../components/FormInputGroup';

import { SettingContext } from "../SettingContext";

function Security() {    
    const { email,  getCompanyDetails, token } = useContext(SettingContext);
    const [loading, setLoading] = useState(false);

    const initialValues = {
        email: email,
        password: null
    }

    const [form, setForm] = useState(initialValues);

    const submitHandler = (form, name, value) => {
        setForm({
            ...form,
            [name]: value
        });

        if(name == "email") {
            saveEmail(value);
        } else {
            console.log(value);
            savePassword(value);
        }
    }

    const savePassword = (password) => {
        setLoading(true);
        axios.post(`${API_URL}/company/changePassword`, {
            oldPassword: password.oldPassword,
            newPassword: password.newPassword
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(() => {
            setLoading(false);
            return Toast.info("Your password was successfully changed.");
        }).catch(function (error) {
            setLoading(false);
            return Toast.error(error.response.data.error);
        });
        return null;
    }

    const saveEmail = async (email) => {
        setLoading(true);
        try {
            let res = axios.put(`${API_URL}/company/updateCompanyAccountEmail`, {
                email: email
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            });

            let result = await res;
            if(result) {
                getCompanyDetails();
                setLoading(false);
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    if (loading) {
        return <LoadingIndicator />;
    }

    return(
        <FlexContainer>
            <Form submit={submitHandler} initialValues={initialValues}>
                <FormInputGroup
                    label="Email"
                    name="email"
                    type="email"
                    value={initialValues.email}/>
                <FormInputGroup
                    label="Password"
                    name="password"
                    type="password"
                    value={initialValues.password}/>
            </Form>
        </FlexContainer>
    );
}

export default Security;