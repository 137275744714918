import React, { useState } from 'react';

export const FormContext = React.createContext({
    form: {}
});

function myForm(props) {
  const { children, submit = () => {}, initialValues } = props;

  const [form, setForm] = useState(initialValues);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleEdit = (name, initialValue) => {
    setForm({
      ...form,
      [name]: initialValue
    });
  }

  return (
    <form className="Form" onSubmit={e => e.preventDefault()}>
      <FormContext.Provider value={{
        form,
        handleFormChange,
        handleEdit,
        submit
      }}>
        {children}
      </FormContext.Provider>
    </form>
  );
}

export default myForm;