import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import { TextInput } from "components/FormField";
import Button from "components/Button";
import Text from "components/Text";
import { API_URL } from "App";
import CenterContainer from "components/CenterContainer";
import FlexContainer from "components/FlexContainer";
import LoadingIndicator from "LoadingIndicator";
import Toast from "Toast";
import SecurityAndLogin from "img/SecurityAndLogin.png";
import PaymentsAndBilling from "img/PaymentsAndBilling.png";
import GeneralAccountSettings from "img/GeneralAccountSettings.png";
import RightArrow from "img/RightArrow.png";

import ScreenHeader from "components/ScreenHeader";
import General from "./forms/General";
import Payments from "./forms/Payments";
import Security from "./forms/Security";
import CancelSubscription from "./forms/Subscription";
import { SettingContext } from "./SettingContext";
import Subscription from "../Subscription/Subscription";

export const Forms = {
    INITIAL: 0,
    GENERAL: 1,
    PAYMENTS: 2,
    SECURITY: 3,
    SUBSCRIPTION: 4
};

export const FormTitles = {
    0: "Account Settings",
    1: "General Account Settings",
    2: "Payments and Billing",
    3: "Security and Login",
    4: "Cancel Subscription"
}

const BorderButton = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    border: 2px solid #2e3036;
    border-radius: 42px;
    line-height: 40px;
    height: 83px;
    width: 512px;
    color: black;
    margin: 20px auto;
    background-color: transparent;
    background: url(${RightArrow}) no-repeat right transparent;
    background-position-x: 95%;
    background-position-y: center;
    cursor: pointer;
    font-size: 20px;
    color: #51315D;
    font-weight: 500;
`;

const CancelButton = styled.div`
    align-items: center;
    text-align: center;;
    line-height: 40px;
    color: #000000;
    margin: 20px auto;
    background-color: transparent;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
`

const Hr = styled.hr`
    margin: 20px 0;
`;

const Img = styled.img`
    margin: 0 20px;
`;

class Setting extends Component {
    state = {
        loading: true,
        fulName: "",
        companyName: "",
        dob: "",
        mobileNumber: "",
        subscriptionStart: null,
        subscriptionPeriod: null,
        subscriptionStatus: null,
        oldPassword: "",
        newPassword: "",
        reNewPassword: "",
        isNameChanging: false,
        isPasswordChanging: false,
        currentForm: 0
    };

    getCompanyDetails = () => {
        axios.get(`${API_URL}/company`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            const companyName = res.data.companyName;
            const fullName = res.data.firstName.concat(" ", res.data.lastName);
            
            const date = this.getSubscriptionEnd(res.data.subscriptionStart, res.data.subscriptionPeriod);
            const subscriptionEnd = new Intl.DateTimeFormat('default', {        
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }).format(date);

            this.setState({
                loading: false,
                fullName: fullName,
                companyName: companyName,
                email: res.data.email,
                dob: moment(res.data.birthDate).format("YYYY-MM-DD"),
                mobileNumber: res.data.phoneNumber,
                isCompanyAustralian: res.data.isCompanyAustralian,
                subscriptionStart: res.data.subscriptionStart,
                subscriptionEnd: subscriptionEnd,
                subscriptionPeriod: res.data.subscriptionPeriod,
                subscriptionStatus: res.data.isCancelled? "Inactive":"Active",
                purchasedUser: res.data.totalNumber
            });
        }).catch(error => {
            return Toast.error(error.message);
        });
    }

    getSubscriptionEnd = (date, period) => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + period);
        
        return newDate;
    }
    componentDidMount() {
        this.getCompanyDetails();
        this.loadingSquareJS();
    }

    loadingSquareJS = () => {
        // Add Square to our project
        // Previously referenced https://medium.com/square-corner-blog/online-payments-form-react-9ecf164880bf
        // Now using Web Payments SDK: https://developer.squareup.com/docs/web-payments/overview
        let that = this;
        let sqPaymentScript = document.createElement("script");
        sqPaymentScript.src = (window.location.hostname === "business.dailyfixme.com") ?
            "https://web.squarecdn.com/v1/square.js" :
            "https://sandbox.web.squarecdn.com/v1/square.js";
        sqPaymentScript.type = "text/javascript";
        sqPaymentScript.async = false;
        sqPaymentScript.onload = () => {
            that.setState({
                loaded: true,
                isProcessClickable: true
            });
        };
        document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    };

    updateBusinessName = () => {
        if (!this.state.isNameChanging) {
            this.setState({ isNameChanging: true });
            axios.put(`${API_URL}/company`, {
                companyName: this.state.companyName
            }, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token
                }
            })
                .then(res => {
                    this.setState({ isNameChanging: false });
                    return Toast.info("Business name has been updated successfully");
                })
                .catch(error => {
                    this.setState({ isNameChanging: false });
                    return Toast.error(error.message);
                });
        }
    };

    retrievePassword = () => {
        axios.post(`${API_URL}/company/forgotPassword`, {
            email: this.props.user.email
        })
            .then(res => {
                return Toast.info("An reset password email has been sent");
            })
            .catch(error => {
                return Toast.error(error.message);
            });
    };

    updatePassword = () => {
        const { oldPassword, newPassword, reNewPassword, isPasswordChanging } = this.state;

        if (!isPasswordChanging) {
            if (newPassword === reNewPassword) {
                this.setState({
                    isPasswordChanging: true
                });

                axios.post(`${API_URL}/company/changePassword`, {
                    oldPassword: oldPassword,
                    newPassword: newPassword
                }, {
                    headers: {
                        Authorization: "Bearer " + this.props.user.token
                    }
                })
                    .then(res => {
                        this.setState({
                            isPasswordChanging: false
                        });
                        return Toast.info("Password has been updated successfully");
                    })
                    .catch(err => {
                        this.setState({
                            isPasswordChanging: false
                        });
                        return Toast.error(err.message);
                    });
            } else {
                return Toast.info("Please make sure new passwords typed are equal to each other");
            }
        }
    };

    setForm = (form) => {
        this.setState({currentForm: form});
    }

    saveAccountHandler = (form) => {
        const name = form.name.split(" ");
        const firstName = name[0];
        const lastName = name[1];
        axios.put(`${API_URL}/updatecompany`, {
            firstName: firstName,
            lastName: lastName,
            birthDate: form.dob,
            phoneNumber: form.mobileNumber
            }, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token
                }
            })
            .then(res => {
                this.setState({ isNameChanging: false });
                return Toast.info("Business information has been updated successfully");
            })
            .catch(error => {
                this.setState({ isNameChanging: false });
                return Toast.error(error.message);
            });

    }
    
    render() {
        const { fullName, companyName, email, dob, mobileNumber, loading, isCompanyAustralian, subscriptionStart, subscriptionEnd, subscriptionPeriod, subscriptionStatus, purchasedUser, isNameChanging, isPasswordChanging, currentForm } = this.state;
        if (loading) {
            return <LoadingIndicator />;
        }
        
        return (
            <FlexContainer flexGrow="1" width="900px" margin="0 auto">
                <SettingContext.Provider value={{
                    fullName: fullName,
                    companyName: companyName,
                    dob: dob,
                    mobileNumber: mobileNumber,
                    isCompanyAustralian: isCompanyAustralian,
                    subscriptionStart: subscriptionStart,
                    subscriptionEnd: subscriptionEnd,
                    subscriptionPeriod: subscriptionPeriod,
                    subscriptionStatus: subscriptionStatus,
                    purchasedUser: purchasedUser,
                    email: email,
                    token: this.props.user.token,
                    setForm: this.setForm,
                    saveAccount: this.saveAccountHandler,
                    getCompanyDetails: this.getCompanyDetails,
                }}>
                    <ScreenHeader setForm={this.setForm} currentForm={currentForm}>{FormTitles[currentForm]}</ScreenHeader>
                    {currentForm === Forms.INITIAL ?
                        <FlexContainer justifyContent="center" marginTop="60px">
                            <BorderButton onClick={(e) => this.setForm(Forms.GENERAL)}>
                                <Img src={GeneralAccountSettings}/>
                                General Account Settings
                            </BorderButton>
                            <BorderButton onClick={(e) => this.setForm(Forms.PAYMENTS)}>
                                <Img src={PaymentsAndBilling} />
                                Payments and Billing
                            </BorderButton>
                            <BorderButton onClick={(e) => this.setForm(Forms.SECURITY)}>
                                <Img src={SecurityAndLogin} />
                                Security and Login
                            </BorderButton>
                            <CancelButton onClick={(e) => this.setForm(Forms.SUBSCRIPTION)}>                       
                                Cancel Subscription
                            </CancelButton>
                        </FlexContainer>
                    :
                        <Hr />}
                    {currentForm === Forms.GENERAL && <General />}
                    {currentForm === Forms.PAYMENTS && <Payments />}
                    {currentForm === Forms.SECURITY && <Security />}
                    {currentForm === Forms.SUBSCRIPTION && <CancelSubscription />}
                </SettingContext.Provider>
            </FlexContainer>
        );
    }
}

export default Setting;