import React, { Component } from "react";
import styled from "styled-components";
import FlexContainer from "components/FlexContainer";
import Text from "components/Text";
import PlanContainer from "../components/PlanContainer";
import ProceedButton from "../components/ProceedButton";
import { SubscriptionContext } from "../SubscriptionContext";
import IntroductionSetting from "img/IntroductionSetting.png";
import IntroductionSetting2x from "img/IntroductionSetting2x.png";
import IntroductionEmployee from "img/IntroductionEmployee.png";
import IntroductionEmployee2x from "img/IntroductionEmployee2x.png";
import IntroductionApp from "img/IntroductionApp.png";
import IntroductionApp2x from "img/IntroductionApp2x.png";

const SideContainer = styled(FlexContainer)`
    width: 33%;
    min-height: 400px;
    min-width: 400px;
    align-items: ${p => p.align || "flex-start"};
`;

class Introduction extends Component {
    static contextType = SubscriptionContext;

    render() {
        const { nextClick } = this.context;

        return (
            <FlexContainer margin="auto">
                <Text size="60px" color="#23262D">
                    Let’s Get Started!
                </Text>
                <PlanContainer top="0">
                    <SideContainer>
                        <Text color="#2D3037" weight="500" 
                            size="20px" margin="0 50px 20px" align="left">
                            Step 1: <br/>
                            Customise your<br/>
                            Me App.
                        </Text>
                        <Text color="#000000" size="14px" maxWidth="275px" align="left"
                            lineHeight="25px" margin="0 50px 40px" height="102px">
                            Get started customising the app by 
                            uploading your business logo! You also 
                            have the option to select 3 colours that 
                            fit your brand.
                        </Text>
                        <img srcSet={IntroductionSetting2x} src={IntroductionSetting} width="350px" alt="Setting" />
                    </SideContainer>
                    <SideContainer>
                        <Text color="#2D3037" weight="500" 
                            size="20px" margin="0 50px 20px" align="left">
                            Step 2: <br/>
                            Invite your Employees<br/>
                            & Clients.
                        </Text>
                        <Text color="#000000" size="14px" maxWidth="275px" align="left"
                            lineHeight="25px" margin="0 50px 70px">
                            Add your personal list of employees & 
                            clients and send them an app invite to 
                            start using your custom Me app. 
                        </Text>
                        <img srcSet={IntroductionEmployee2x} src={IntroductionEmployee} width="350px" alt="Employee" />
                        <FlexContainer alignItems="center" width="370px">
                            <ProceedButton left="0" onClick={nextClick} textColor="#FFFFFF"
                                marginTop="50px" color="#2D3037" width="95px">
                                    Next
                            </ProceedButton>
                        </FlexContainer>
                    </SideContainer>
                    <SideContainer>
                        <Text color="#2D3037" weight="500" align="left"
                            size="20px" margin="0 50px 20px">
                            Step 3: <br/>
                            Receive your code & <br/>
                            download the app.
                        </Text>
                        <Text color="#000000" size="14px" maxWidth="275px" align="left"
                            lineHeight="25px" margin="0 50px 40px" height="102px">
                            You will receive an email containing a 
                            unique code to enter once you have  
                            download the Me app, to   
                            convert it to your custom version.
                        </Text>
                        <img srcSet={IntroductionApp2x} src={IntroductionApp} width="300px" alt="App" />
                    </SideContainer>
                </PlanContainer>
            </FlexContainer>
        );
    }
}

export default Introduction;