import React, { Component } from "react";
import styled from "styled-components";
import FlexContainer from "components/FlexContainer";
import Text from "components/Text";
import Button from "components/Button";
import { SubscriptionContext } from "../SubscriptionContext";
import Tick from "img/tick.png";

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 20px auto;
`;

export const StyledText = styled(Text)`
    font-size: 40px;
    color: #000000;
    margin: 0 auto;
`;

export const StyledButton = styled(Button)`
    color: white;
    background-color: #51315D;
    border-radius: 20px;
    font-weight: 500;
    border: 1px solid #292C33;
    padding: 10px 40px;
    margin: 20px auto;
    &:focus::-webkit-input-placeholder {
        opacity: 0;
        transform: translate(70%);
        -webkit-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }
`;

class Pending extends Component {
    static contextType = SubscriptionContext;
    render() {
        const { nextClick } = this.context;


        return (
            <Container>
                <FlexContainer margin="auto">
                    <img src={Tick} alt="" width="97px" style={{margin: "0 auto 35px auto"}}/>
                    <StyledText>
                        Thank you!
                    </StyledText>
                    <StyledText>
                        We have received your submission.
                    </StyledText>
                    <FlexContainer>
                        <Text size="14px" color="#000000">
                        You have requested to sign up and purchase more than 1000+ users for your<br/>
                        Me business app. A Me App team member will be in touch <br/>
                        with you to assist you with your business subscription and account <br/>
                        creation.
                        </Text>
                        <Text color="#51315D">
                        Please contact us directly if you would<br/>like further assistance.
                        </Text>
                        <StyledButton width="160px">
                            Call Us
                        </StyledButton>
                    </FlexContainer>
                </FlexContainer>
            </Container>
        );
    }
}

export default Pending;