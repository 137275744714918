import React, { Component } from "react";
import styled from "styled-components";
import FlexContainer from "components/FlexContainer";
import Text from "components/Text";
import FormField, { ErrorText } from "components/FormField";
import FormText, { ErrorTextArea } from "components/FormText";
import LeftText from "../components/LeftText";
import { TextInput } from "components/FormField";
import Form from "components/Form";
import ButtonContainer from "../components/ButtonContainer";
import CancelButton from "../components/CancelButton";
import ProceedButton from "../components/ProceedButton";
import { SubscriptionContext } from "../SubscriptionContext";

const StyledTextInput = styled(TextInput)`
    font-family: 'Affogato', Verdana, Geneva, sans-serif;
    display: block;
    padding: ${p => p.padding || "5px 40px"};
    width: ${p => p.width || "-webkit-fill-available"};
    height: ${p => p.height || "60px"};
    background-color: transparent;
    color: #2A2B2A;
    margin: ${p => p.margin || "15px 0"};
    font-weight: 500;
    font-size: ${p => p.size || "30px"};
    text-align: ${p => p.textAlign || "left"};
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #2D3037;
    border-radius: ${p => p.borderRadius || "60px"};
    ::placeholder {
        color: #2A2B2A;
    }
`;

const Hr = styled.hr`
    margin: 0 64px;
`;

const ErrorList = {
    EMPTY_FULL_NAME: 1,
    EMAIL_FORMAT: 2,
    EMPTY_EMAIL: 3,
    MOBILE_FORMAT: 4,
    EMPTY_MOBILE: 5,
    EMPTY_BUSINESS_NAME: 6,
    EMPTY_MESSAGE: 7
};

const ErrorStrings = {
    [ErrorList.EMPTY_FULL_NAME]: "* Please enter your full name ",
    [ErrorList.EMAIL_FORMAT]: "* Please make sure email format is correct ",
    [ErrorList.EMPTY_EMAIL]: "* Please enter an email address ",
    [ErrorList.MOBILE_FORMAT]: "* Format is invalid ",
    [ErrorList.EMPTY_MOBILE]: "* Please enter a mobile number ",
    [ErrorList.EMPTY_BUSINESS_NAME]: "* Please enter your business name ",
    [ErrorList.EMPTY_MESSAGE]: "* Please enter your message ",
};

class MaximumUsers extends Component {
    static contextType = SubscriptionContext;
    state = {
        fullName: "",
        emailAddress: "",
        mobileNumber: "",
        businessName: "",
        message: "",
        inputErrors: []
    };
    
    /**
     * @param {string} email
     */
    validEmail(email) {
        return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    submitForm = (e) =>  {
        e.preventDefault();
        const { fullName, emailAddress, mobileNumber, businessName, message} = this.state;
        const { sendSubscriptionRequest } = this.context;

        let errorArray = [];

        
        if (fullName.trim() === "") {
            errorArray.push(ErrorList.EMPTY_FULL_NAME);
        }
        
        if (!this.validEmail(emailAddress)) {
            errorArray.push(ErrorList.EMAIL_FORMAT)
        }

        if (mobileNumber.trim() === "") {
            errorArray.push(ErrorList.EMPTY_MOBILE)
        }
        
        if (!(mobileNumber.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && ! (mobileNumber.match(/0{5,}/))) ) {
            errorArray.push(ErrorList.MOBILE_FORMAT);
        }
        
        if (businessName.trim() === "") {
            errorArray.push(ErrorList.EMPTY_BUSINESS_NAME);
        } 
        
        if (message.trim() === "") {
            errorArray.push(ErrorList.EMPTY_MESSAGE)
        }
        
        if (errorArray.length === 0) {
            sendSubscriptionRequest(fullName, emailAddress, mobileNumber, businessName, message)
        } else {
            this.setState({
                inputErrors: errorArray,
            });
        }
    }

    render() {
        const { totalNumber, currentForm, updatePrice, backToPrevious } = this.context;

        const { inputErrors } = this.state;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

        const fullNameError = getErrorStrings(ErrorList.EMPTY_FULL_NAME);
        const mobileNumberError = getErrorStrings(ErrorList.EMPTY_MOBILE, ErrorList.MOBILE_FORMAT);
        const emailAddressError = getErrorStrings(ErrorList.EMPTY_EMAIL, ErrorList.EMAIL_FORMAT);
        const businessNameError = getErrorStrings(ErrorList.EMPTY_BUSINESS_NAME);
        const messageError = getErrorStrings(ErrorList.EMPTY_MESSAGE);
        
        return (
            <FlexContainer flexGrow="1">                
                <Text size="40px" color="#23262D" margin="0 0 100px 0">
                    Subscription Details
                </Text>
                <FlexContainer margin="auto" justifyContent="center" direction="row">
                    <FlexContainer minHeight="400px" alignItems="flex-end">
                        <FlexContainer width="400px" >
                            <LeftText size="30px" margin="0 0 10px">
                                Contact Us for Direct Sign Up
                            </LeftText>
                            <LeftText style={{ fontSize: "14px", margin: 0, maxWidth: "530px", lineHeight: "1.3" }}>
                                If you are requesting to purchase <b>1000+</b> users to add to your
                                Me Business Subscription please contact us by entering 
                                your details in the form and a team member will be in touch 
                                with to assist you with your account creation.
                            </LeftText>
                            <LeftText size="20px" margin="30px 0 10px">
                                Enter the total number of<br/> required users here:<br/>
                            </LeftText>
                            <StyledTextInput
                                type="number"
                                width="280px"
                                margin="0 0 40px"
                                textAlign="center"                        
                                placeholder={ totalNumber }
                                onChange={(e) => updatePrice(currentForm, e.target.value)}>
                            </StyledTextInput>
                            <LeftText margin="0" color="#C70099" weight="500" size="14px">
                                **Please note that you will be able to purchase additional
                                users after your account has been activated.
                            </LeftText>
                        </FlexContainer>
                    </FlexContainer>
                    <Hr/>
                    <FlexContainer minHeight="400px">
                        <LeftText size="30px"  margin="0 0 10px">
                            Get In Touch!
                        </LeftText>
                        <Form width="-webkit-fill-available">
                            <FormField borderRadius="26px" size="14px" padding="10px 20px" height="45px"
                                placeholderColor="#000000" border="1px solid #2D3037"
                                width="-webkit-fill-available"
                                type="text"
                                placeholder="Enter your full name"
                                onChange={(e) => this.setState({fullName: e.target.value})}
                                errors={fullNameError}
                                >
                            </FormField>
                            <FormField borderRadius="26px" size="14px" padding="10px 20px" height="45px"
                                placeholderColor="#000000" border="1px solid #2D3037"
                                width="-webkit-fill-available"
                                type="text"
                                placeholder="Enter your email address"
                                onChange={(e) => this.setState({emailAddress: e.target.value})}
                                errors={emailAddressError}
                                >
                            </FormField>
                            <FormField borderRadius="26px" size="14px" padding="10px 20px"  height="45px"
                                placeholderColor="#000000" border="1px solid #2D3037"
                                type="text"
                                placeholder="Mobile number"
                                onChange={(e) => this.setState({mobileNumber: e.target.value})}
                                errors={mobileNumberError}
                                >
                            </FormField>
                            <FormField borderRadius="26px" size="14px" padding="10px 20px"  height="45px"
                                placeholderColor="#000000" border="1px solid #2D3037"
                                type="text"
                                placeholder="Business name"
                                onChange={(e) => this.setState({businessName: e.target.value})}
                                errors={businessNameError}
                                >
                            </FormField>
                            <FormText
                                border="1px solid #2D3037"
                                onChange={(e) => this.setState({message: e.target.value})}
                                placeholder="Message.."
                                errors={messageError}>
                            </FormText>  
                            <ButtonContainer top="20px">
                                <CancelButton onClick={() => backToPrevious()}>
                                    Back
                                </CancelButton>
                                <ProceedButton color="#51315D" onClick={this.submitForm}>
                                    Submit
                                </ProceedButton>
                            </ButtonContainer>
                        </Form>
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        );
    }
}

export default MaximumUsers;