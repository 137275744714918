import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "App";
import Text from "components/Text";
import FormField from "components/FormField";
import NextButton from "components/NextButton";
import Form from "components/Form";
import FlexContainer from "components/FlexContainer";

const ResetError = {
    EMPTY_PASSWORD: 1,
    EMPTY_REENTER_PASSWORD: 2,
    PASSWORD_NOT_MATCH: 3,
};

const ErrorStrings = {
    [ResetError.EMPTY_PASSWORD]: "* Please enter a password!",
    [ResetError.EMPTY_REENTER_PASSWORD]: "* Please re-enter the password!",
    [ResetError.PASSWORD_NOT_MATCH]: "* Please ensure both passwords are the same!",
};

class ResetPassword extends Component {
    state = {
        password: "",
        rePassword: "",
        userToken: "",
        inputErrors: [],
        errorMessage: "",
    };

    componentDidMount() {
        //remove first character as it is the : instead of value
        const token = this.props.match.params.token;
        this.setState({
            userToken: token.substring(1)
        });
    }
    
    reset = (e) => {
        const { password, rePassword, userToken } = this.state;

        e.preventDefault();
        let errorArray = [];

        if (password.trim() === "") {
            errorArray.push(ResetError.EMPTY_PASSWORD);
        }

        if (rePassword.trim() === "") {
            errorArray.push(ResetError.EMPTY_REENTER_PASSWORD);
        }

        if (rePassword !== password) {
            errorArray.push(ResetError.PASSWORD_NOT_MATCH);
        }
        if (errorArray.length === 0) {
            axios.put(`${API_URL}/company/resetPassword`, {
                password: password,
                token: userToken
            })
                .then(res => {
                    window.location.href = "./login";
                })
                .catch(error => {
                    if (error.response) {
                        this.setState({
                            errorMessage: error.response.data.error
                        });
                    }
                });
        } else {
            this.setState({ 
                inputErrors: errorArray,
            });
        }
    }

    render() {
        const { inputErrors, errorMessage } = this.state;
        const passwordError = inputErrors.filter(error => error === ResetError.EMPTY_PASSWORD 
                || error === ResetError.PASSWORD_NOT_MATCH).map(error => ErrorStrings[error]);
        const rePasswordError = inputErrors.filter(error => error === ResetError.EMPTY_REENTER_PASSWORD
                || error === ResetError.PASSWORD_NOT_MATCH).map(error => ErrorStrings[error]);

        return (
            <FlexContainer height="90%" alignItems="center" justifyContent="center">
                <Form>
                    <Text color="red"><strong>{errorMessage}</strong></Text><br/>
                    <Text weight="500">
                        <b>Reset your password?</b>
                    </Text>
                    <FormField
                        placeholder="Password"
                        type="password"
                        onChange={(e) => this.setState({ password: e.target.value })}
                        errors={passwordError}
                    />
                    <FormField
                        placeholder="Re-Enter Password"
                        type="password"
                        onChange={(e) => this.setState({ rePassword: e.target.value })}
                        errors={rePasswordError}
                    />
                    <NextButton
                        alt="Reset" 
                        onClick={this.reset} 
                    />
                </Form>
            </FlexContainer>
        ); 
    }
}

export default ResetPassword;