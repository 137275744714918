import React, { Component } from "react";
import styled from "styled-components";
import axios from "axios";
import { API_URL } from "App";
import moment from "moment";
import countdown from "countdown";
import Initial from "./forms/Initial";
import Company from "./forms/Company";
import Plan from "./forms/Plan";
import MaximumUsers from "./forms/MaximumUsers"
import Pending from "./forms/Pending"
import Review from "./forms/Review";
import Success from "./forms/Success";
import Introduction from "./forms/Introduction";
import Active from "./forms/Active";
import Edit from "./forms/Edit";
import { SubscriptionContext } from "./SubscriptionContext";
import LoadingIndicator from "LoadingIndicator";
import Toast from "Toast";

export const Forms = {
    INITIAL: 0,
    COMPANY_NAME: 1,
    PLAN: 2,
    REVIEW: 3,
    SUCCESS: 4,
    INTRODUCTION: 5,
    ACTIVE: 6,
    PURCHASE: 7,
    CHECKOUT: 8,
    PAYMENT_SUCCESS: 9,
    EDIT: 10,
    MAX_USER: 11,
    SUBSCRIPTION_PENDING: 12
};

export const SUBSCRIPTION_COST = 120;
export const GST_RATE = 0.1;
export const PRICE_PER_USER = 3.49;
const SubscriptionPeriod = "12";

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    flex-grow: 1;
`;

const StorageKeys = {
    COMPANY_NAME: "companyName",
    SUBSCRIPTION_PERIOD: "subscriptionPeriod",
    TOTAL_NUMBER: "totalNumber",
    IS_SUBSCRIBED: "isSubscribed",
    IS_ADD_MORE_USER: "isAddMoreUser",
    MORE_USERS: "moreUsers",
    MORE_TOTAL: "moreTotal",
    IS_AWAITING_ACTIVATION: "isAwaitingActivation"
};

class Subscription extends Component {
    state = {
        loading: false,
        currentForm: Forms.INITIAL,
        isNextClickable: false,
        acceptToc: false,
        companyName: "",
        subscriptionPeriod: SubscriptionPeriod,
        totalNumber: 1,
        userCost: 41.88,
        gstCost: 4.12,
        totalCost: 46.07,
        timezone: "",
        quoteSendTime: "",
        method: "paypal",
        remainDays: "",
        employees: 0,
        clients: 0,
        moreUsers: 1,
        moreCost: 41.88,
        moreGst: 4.12,
        moreTotal: 46.07,
        subscriptionStartDate: "",
        subscriptionEndDate: "",
        // We create moment library objects from our start date and end date
        //    to enable easier date comparison (see usage of the .diff method)
        startDayMomentType: "",
        endDayMomentType: "",
        cards: [],
        selectedCard: "",
        isCancelled: false,
        loadingStatus: false,
        hideResult: true,
        resultMessage: "",
        isPaymentFailing: true,
        awaitingActivation: false
    };

    async getCompanyRegistrationStatus() {
        this.setState({
            loading: true
        });
        try {
            let response = await axios.get(`${API_URL}/companyregistration`, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token
                }
            });
            if(response && response.data.company_registration.awaitingActivation) {
                this.setState({
                    currentForm: Forms.SUBSCRIPTION_PENDING,
                    awaitingActivation: true,
                });
                localStorage.setItem(StorageKeys.IS_AWAITING_ACTIVATION, response.data.company_registration.awaitingActivation);
            }
        } catch (error) {
            console.log(error)
        } finally {
            this.setState({
                loading: false
            });
        }
    }
    
    //we just provide return url from paypal and thse local storage will help us to determine which
    //page should front-end display to the user correctly.
    componentDidMount() {
        //check if user has existing subscription request (for subscribers with more than 1000 users)
        if(!this.props.user.isActive) {
            localStorage.hasOwnProperty(StorageKeys.IS_AWAITING_ACTIVATION) ? 
                this.setState({currentForm: Forms.SUBSCRIPTION_PENDING}) : this.getCompanyRegistrationStatus();
        }
        //if the company has been actived or it comes from redirection of paypal after add more users        
        if (this.props.user.isActive || JSON.parse(localStorage.getItem(StorageKeys.IS_ADD_MORE_USER))) {
            //get cards method includes loading attribute, so it doesn't need to set loading attribute before this method is called
            this.getAllCards();

            this.setState({
                loading: true
            });

            axios.get(`${API_URL}/company`, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token
                }
            })
                .then(res => {
                    let startDate = res.data.subscriptionStart;
                    let subscriptionPeriod = res.data.subscriptionPeriod;
                    let startDateMoment = moment(startDate);
                    let expiredDate = moment(startDate).add(subscriptionPeriod, "months");
                    let remainDays = countdown(moment(), expiredDate, countdown.DAYS).toString();
                    let totalNumber = res.data.totalNumber;
                    let employees = res.data.employees;
                    let clients = res.data.clients;
                    let companyName = res.data.companyName;
                    let isCancelled = res.data.isCancelled;
                    let isPaymentFailing = res.data.isPaymentFailing;

                    let formatStartDay = new Intl.DateTimeFormat().format(moment(startDate));
                    let formatExpireDay = new Intl.DateTimeFormat().format(expiredDate);

                    //redirection comes from employee list `add more users`
                    if (JSON.parse(localStorage.getItem("redirect"))) {
                        localStorage.removeItem("redirect");
                        this.setState({
                            currentForm: Forms.PURCHASE
                        });
                    } else {
                        //comes from paypal add more users' redirection
                        if (JSON.parse(localStorage.getItem(StorageKeys.IS_ADD_MORE_USER))) {
                            this.setState((prevState) => {
                                return {
                                    ...prevState,
                                    currentForm: Forms.PAYMENT_SUCCESS,
                                    moreUsers: localStorage.getItem(StorageKeys.MORE_USERS),
                                    moreTotal: localStorage.getItem(StorageKeys.MORE_TOTAL),
                                };
                            });
                        } else {
                            this.setState((prevState) => {
                                return {
                                    ...prevState,
                                    currentForm: Forms.ACTIVE,
                                };
                            });
                        }

                        this.setState((prevState) => {
                            return {
                                ...prevState,
                                companyName: companyName,
                                remainDays: remainDays,
                                totalNumber: totalNumber,
                                employees: employees,
                                clients: clients,
                                isNextClickable: true,
                                subscriptionStartDate: formatStartDay,
                                subscriptionEndDate: formatExpireDay,
                                isCancelled: isCancelled,
                                startDayMomentType: startDateMoment,
                                endDayMomentType: expiredDate,
                                isPaymentFailing: isPaymentFailing
                            };
                        });
                    }

                    //release these values since they are stored in state
                    localStorage.removeItem(StorageKeys.TOTAL_NUMBER);
                    localStorage.removeItem(StorageKeys.IS_ADD_MORE_USER);
                    localStorage.removeItem(StorageKeys.MORE_USERS);
                    localStorage.removeItem(StorageKeys.SUBSCRIPTION_PERIOD);
                    localStorage.removeItem(StorageKeys.MORE_TOTAL);

                    this.setState({
                        loading: false
                    });
                })
                .catch(error => {
                    this.setState({
                        isNextClickable: true,
                    });
                    return Toast.error(error.message);
                });
        }

        //determine if current request comes from first time subscription
        if (JSON.parse(localStorage.getItem(StorageKeys.IS_SUBSCRIBED))) {
            this.setState({
                loading: true
            });

            //determine if the company successfully created to see if payment and company created successfully
            axios.get(`${API_URL}/company/isActive`, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token
                }
            }).then (res => {
                //get these information to display page accurately
                let totalNumber = localStorage.getItem(StorageKeys.TOTAL_NUMBER);
                let subscriptionPeriod = localStorage.getItem(StorageKeys.SUBSCRIPTION_PERIOD);
                let companyName = localStorage.getItem(StorageKeys.COMPANY_NAME);

                //recalculate price based on stored number of users, used to reset prices in case
                //payment calculation errors
                let userCost = PRICE_PER_USER * totalNumber * subscriptionPeriod;
                let gstCost = GST_RATE * userCost;
                let totalCost = userCost + gstCost;

                // get current time and set it as subscription start time in stead of getting from
                // API side for this time only due to this request comes from
                // first time purchse
                let startDateMoment = moment();
                let formatStartDay = new Intl.DateTimeFormat().format(moment());
                let expiredDate = moment().add(subscriptionPeriod, "months");
                let formatExpireDay = new Intl.DateTimeFormat().format(expiredDate);

                if (res.data.isActive) {
                    this.setState({
                        ...this.state,
                        currentForm: Forms.SUCCESS,
                        totalNumber: totalNumber,
                        subscriptionPeriod: subscriptionPeriod,
                        companyName: companyName,
                        isNextClickable: true,
                        subscriptionStartDate: formatStartDay,
                        subscriptionEndDate: formatExpireDay,
                        startDayMomentType: startDateMoment,
                        endDayMomentType: expiredDate,
                    });

                    this.props.setUserToActive();
                } else {
                    //if payment not success or user doesn't agree with agreement
                    this.setState({
                        ...this.state,
                        currentForm: Forms.REVIEW,
                        totalNumber: totalNumber,
                        subscriptionPeriod: subscriptionPeriod,
                        companyName: companyName,
                        isNextClickable: true,
                        userCost: userCost,
                        gstCost: gstCost,
                        totalCost: totalCost,
                    });
                }

                //clear local storage
                localStorage.removeItem(StorageKeys.IS_SUBSCRIBED);
                localStorage.removeItem(StorageKeys.TOTAL_NUMBER);
                localStorage.removeItem(StorageKeys.SUBSCRIPTION_PERIOD);
                localStorage.removeItem(StorageKeys.COMPANY_NAME);

                this.setState({
                    loading: false
                });
            }).catch (error => {
                localStorage.removeItem(StorageKeys.IS_SUBSCRIBED);
                return Toast.error(error.message);
            });
        }

        // The payment cannot fail if the user 
        // account is not even active yet
        if (!this.props.user.isActive) {
            this.setState({
                isPaymentFailing: false
            });
        }
    }

    nextClick = () => {
        if (this.state.isNextClickable) {
            if (this.state.currentForm == Forms.PLAN && this.state.totalNumber > 1000) {
                this.setState({
                    currentForm: Forms.MAX_USER,
                    hideResult: true,
                });
            } else if (this.state.currentForm === Forms.REVIEW) {
                this.props.setUserToActive();
                this.setState({
                    currentForm: Forms.SUCCESS,
                    hideResult: true,
                });
            } else if (this.state.currentForm === Forms.PAYMENT_SUCCESS) {
                this.setState({
                    currentForm: Forms.ACTIVE,
                    hideResult: true
                });
            } else if (this.state.currentForm === Forms.INTRODUCTION) {
                let startDayMoment = moment();
                let formatStartDay = new Intl.DateTimeFormat().format(moment());
                let expiredDate = moment().add(parseInt(SubscriptionPeriod), "months");
                let formatExpireDay = new Intl.DateTimeFormat().format(expiredDate);
                let remainDays = countdown(moment(), expiredDate, countdown.DAYS).toString();

                this.setState({
                    subscriptionStartDate: formatStartDay,
                    subscriptionEndDate: formatExpireDay,
                    remainDays: remainDays,
                    hideResult: true,
                    startDayMomentType: startDayMoment,
                    endDayMomentType: expiredDate,
                });

                window.location.href = "/customise";
            } else if (this.state.currentForm === Forms.CHECKOUT) {
                let totalNumber = parseInt(this.state.totalNumber) + parseInt(this.state.moreUsers);
                this.setState({
                    totalNumber: totalNumber,
                    hideResult: true,
                });
                this.setState({
                    currentForm: Forms.PAYMENT_SUCCESS,
                    hideResult: true,
                });
            } else {
                const newForm = this.state.currentForm + 1;
                this.setState({
                    currentForm: newForm,
                    hideResult: true,
                });
            }
        }
    };

    getAllCards = () => {
        //make sure all cards information refreshed successfully before a page is displayed
        this.setState({
            loading: true
        });

        //get all saved cards
        axios.get(`${API_URL}/company/square/cards`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            let cards = res.data.cards;
            let selectedCard = "";
            cards.forEach(card => {
                if (card.isDefault) {
                    selectedCard = card.cardId;
                }
            });
            this.setState({
                cards: cards,
                selectedCard: selectedCard,
                loading: false
            });
        }).catch(error => {

        });
    };

    updateValue = (name, value) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                isNextClickable: true,
                [name]: value,
            };
        });
    };

    updateCompanyValue = (name, value) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                isNextClickable: true,
                [name]: value,
            };
        }, this.nextClick);
    };

    setSubscriptionEmail = (name, value) => {
        this.setState({
                [name]: value,
        });
    };

    updatePrice = (form, value) => {
        //minimal user number is 1
        if (value > 0) {
            //when purchase more users, it is charged by one month, while setup payment is subscribed to 12 months
            let userCost = Math.abs(PRICE_PER_USER * value * parseInt(SubscriptionPeriod));
            let moreUserCost = Math.abs(PRICE_PER_USER * value * 1.1).toFixed(2);
            let totalCost = (userCost * 1.1).toFixed(2);
            let gst = GST_RATE * totalCost;
            let userGst = GST_RATE * moreUserCost;

            if (form === Forms.PLAN) {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        totalNumber: value,
                        userCost: userCost,
                        gstCost: gst,
                        totalCost: totalCost,
                    };
                });
            } else {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        moreUsers: value,
                        moreCost: moreUserCost,
                        moreGst: userGst,
                        moreTotal: moreUserCost,
                    };
                });
            }
        } else if (value !== "") {
            return Toast.info("The minimal number is 1");
        }
    };

    backToInitial = (form) => {
        let newForm;
        if (form === Forms.PLAN || form === Forms.REVIEW) {
            newForm = Forms.INITIAL;
        } else {
            newForm = Forms.ACTIVE;
        }
        this.setState({
            ...this.state,
            currentForm: newForm,
        });
    };

    backToPrevious = () => {
        let newForm;
        if (this.state.currentForm === Forms.MAX_USER) {
            newForm = 2
        } else {
            newForm = this.state.currentForm - 1;
        }

        this.setState({
            ...this.state,
            currentForm: newForm,
        });
    };

    editPaymentMethod = () => {
        this.setState({
            currentForm: Forms.EDIT
        });
    };

    savePaymentMethod = () => {
        this.setState({
            currentForm: Forms.ACTIVE
        });
        this.getAllCards();
    };

    updateSelectedCard = (value) => {
        this.setState({
            selectedCard: value
        });
    };

    alterSubscription = () => {
        this.setState({
            loadingStatus: true
        });

        if (this.state.isCancelled) {
            axios.put(`${API_URL}/company/reactivate`, {}, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token
                }
            }).then (res => {
                this.setState({
                    isCancelled: res.data.isCancelled,
                    loadingStatus: false,
                    hideResult: false,
                    resultMessage: "Your subscription has been reactivated."
                });
            }).catch(error => {
                this.setState({
                    loadingStatus: false,
                    hideResult: false,
                    resultMessage: "Some errors occurred, please try later"
                });
            });
        } else {
            axios.put(`${API_URL}/company/cancel`, {}, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token
                }
            }).then (res => {
                this.setState({
                    isCancelled: res.data.isCancelled,
                    loadingStatus: false,
                    hideResult: false,
                    resultMessage: "Your subscription has been cancelled."
                });
            }).catch(error => {
                this.setState({
                    loadingStatus: false,
                    hideResult: false,
                    resultMessage: "Some errors occurred, please try later"
                });
            });
        }
    };

    sendSubscriptionRequest = (fullName, emailAddress, mobileNumber, businessName, message)=> {
    const { uuid, totalNumber } = this.state;
        axios.post(`${API_URL}/companyregistration`, {
            "totalNumber": totalNumber,
            "uuid": uuid,
        }, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            // Send subscription request email
            axios.post(`${API_URL}/businesssubscription/sendmail`, {
                "fullName": fullName, 
                "emailAddress": emailAddress, 
                "mobileNumber": mobileNumber,
                "businessName": businessName, 
                "message": message
            }, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token
                }
            }).then(res => {
                this.setState({
                    currentForm: Forms.SUBSCRIPTION_PENDING,
                    awaitingActivation: true
                });
                localStorage.setItem(StorageKeys.IS_AWAITING_ACTIVATION, true)
            }).catch(error => {
                this.showError(error.message);
            });
        }).catch(error => {
            this.showError(error.message);
        });
    }

    showError = (error) => {
        this.setState({
            resultMessage: error
        });
        return Toast.error(error);
    }

    render() {
        const { loading, currentForm, acceptToc, companyName, totalNumber, subscriptionPeriod, isCancelled, hideResult,
            userCost, gstCost, totalCost, method, remainDays, employees, clients, cards, selectedCard, loadingStatus, resultMessage,
            moreUsers, moreCost, moreGst, moreTotal, subscriptionStartDate, subscriptionEndDate, startDayMomentType, endDayMomentType,
            isPaymentFailing } = this.state;

        if (loading) {
            return <LoadingIndicator />;
        }

        return (
            <Container>
                <SubscriptionContext.Provider value={{
                    updateValue: this.updateValue,
                    updateCompanyValue: this.updateCompanyValue,
                    sendSubscriptionRequest: this.sendSubscriptionRequest,
                    acceptToc: acceptToc,
                    nextClick: this.nextClick,
                    companyName: companyName,
                    finishSubmission: this.finishSubmission,
                    updatePrice: this.updatePrice,
                    totalNumber: totalNumber,
                    backToInitial: this.backToInitial,
                    backToPrevious: this.backToPrevious,
                    gstCost: gstCost,
                    userCost: userCost,
                    totalCost: totalCost,
                    currentForm: currentForm,
                    moreUsers: moreUsers,
                    moreGst: moreGst,
                    moreTotal: moreTotal,
                    moreCost: moreCost,
                    method: method,
                    subscriptionPeriod: subscriptionPeriod,
                    isActive: this.props.user.isActive,
                    employees: employees,
                    clients: clients,
                    remainDays: remainDays,
                    email: this.props.user.email,
                    token: this.props.user.token,
                    isAustralian: this.props.user.isAustralian,
                    alterSubscription: this.alterSubscription,
                    isCancelled: isCancelled,
                    editPaymentMethod: this.editPaymentMethod,
                    savePaymentMethod: this.savePaymentMethod,
                    subscriptionStartDate: subscriptionStartDate,
                    subscriptionEndDate: subscriptionEndDate,
                    startDayMomentType: startDayMomentType,
                    endDayMomentType: endDayMomentType,
                    cards: cards,
                    selectedCard: selectedCard,
                    updateSelectedCard: this.updateSelectedCard,
                    getAllCards: this.getAllCards,
                    loadingStatus: loadingStatus,
                    resultMessage: resultMessage,
                    hideResult: hideResult,
                    isPaymentFailing: isPaymentFailing
                }}>
                    { currentForm === Forms.INITIAL &&
                        <Initial /> }
                    { currentForm === Forms.COMPANY_NAME &&
                        <Company /> }
                    { currentForm === Forms.PLAN &&
                        <Plan /> }
                    { currentForm === Forms.MAX_USER &&
                        <MaximumUsers /> }
                    { currentForm === Forms.SUBSCRIPTION_PENDING &&
                        <Pending /> }
                    { currentForm === Forms.REVIEW &&
                        <Review /> }
                    { currentForm === Forms.SUCCESS &&
                        <Success /> }
                    { currentForm === Forms.INTRODUCTION &&
                        <Introduction />}
                    { currentForm === Forms.ACTIVE &&
                        <Active /> }
                    { currentForm === Forms.PURCHASE &&
                        <Plan /> }
                    { currentForm === Forms.CHECKOUT &&
                        <Review /> }
                    { currentForm === Forms.PAYMENT_SUCCESS &&
                        <Success /> }
                    { currentForm === Forms.EDIT &&
                        <Edit />}
                </SubscriptionContext.Provider>
            </Container>
        );
    }
}

export default Subscription;