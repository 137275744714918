import styled, { css } from "styled-components";

const Tooltip = styled.div`
    ${ p => p.active && css`
        position: relative;
        display: inline-block;
        white-space: nowrap;

        &::before {
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid ${ p => p.color };
            border-bottom: 8px solid transparent;
            right: 42%;
            bottom: 16px;
            
            ${ p => (p.index === 0 || p.index === "New") && css`
                bottom: -8px;
                transform: rotate(180deg);
            `}
        }

        &::after {
            content: '${ p => p.text || "Tooltip Text" }';
            position: absolute;
            padding: 6px 12px;
            background: ${ p => p.color };
            color: white;
            font-size: 12px;
            border-radius: 4px;
            pointer-events: none;
            left: 50%;
            transform: translate(-49%, calc(-100% - 8px));
            top: 0;
            
            ${ p => p.index === 0 && css`
                transform: translate(-49%, calc(100% + 4px));
            `}
            
            ${ p => p.index === "New" && css`
                transform: translate(-49%, calc(100%));
            `}
        }
    `}
`;

export default Tooltip;
