import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
    display: flex;
    flex-direction: ${p => p.direction || "column"};
    height: ${p => p.height};
    width: ${p => p.width};
    align-items: ${p => p.alignItems };
    justify-content: ${p => p.justifyContent};
    margin: ${p => p.margin};
    margin-left: ${p => p.marginLeft};
    margin-right: ${p => p.marginRight};
    margin-top: ${p => p.marginTop};
    margin-bottom: ${p => p.marginBottom};
    padding: ${p => p.padding};
    box-sizing: border-box;
    min-height: ${p => p.minHeight};
    position: ${p => p.position};
    background-color: ${p => p.color};
    top: ${p => p.top};
    bottom: ${p => p.bottom};
    right: ${p => p.right};
    left: ${p => p.left};
    background-image: ${p => p.image};
    overflow-x: ${p => p.overflowX};
    flex-grow: ${p => p.flexGrow};
    min-width: ${p => p.minWidth};
    overflow-y: ${p => p.overflowY};
    border: ${p => p.border};
`;

function FlexContainer(props) {
    const { children, ...styleProps } = props;
    return (
        <StyledContainer {...styleProps}>
            {children}
        </StyledContainer>
    );
}

export default FlexContainer;