import React from "react";
import { Link, withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import LogoImg from "Logo";
import AppleImg from "img/Apple.svg";
import GoogleImg from "img/Google.svg";

// Media Queries

const tablet = (...args) => css`
    @media (min-width: 38em) {
        ${css(...args)}
    }
`;
const desktop = (...args) => css`
    @media (min-width: 64em) {
        ${css(...args)}
    }
`;

// Styled components

const Container = styled.header`
    background: ${p => p.url ? `url(${p.url}) no-repeat center ${p.location || "top"}` : null};
    background-color: ${p => p.transparent ? "transparent" : p.backgroundColor };
    flex: 0;
    top: ${p => p.top};
    transition: background-color 0.3s;
    width: 100%;
`;

const Content = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 32px;
    width: 100%;
    text-align: center;
    
    ${desktop`
        flex-direction: row;
    `};
`;

const Logo = styled.div`
    width: 18em;
    height: 3em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    ${desktop`
        justify-content: flex-start;
    `};
`;

const Navigation = styled.nav`
    flex: 1;
    display: inline-flex;
    flex-grow: 2;
    align-items: center;
    justify-content: center;
    padding-top: 1em;
    ${desktop`
        padding-top: 0;
    `};
`;

const LoggedOutNavigationLink = styled(Link)`
    font-size: 14px;
    font-weight: 500;
    margin-left: ${p => p.marginleft || "4vw"};
    text-decoration: none;
    padding: 10px 40px;
    color: ${p => p.color || "black"};
    align-items: flex-end;
    border: 0.2em solid white;
    border-radius: 20px;
    color: ${props => props.ishighlighted === "true" ? "#51315D" : "white"};
    ${props => props.ishighlighted === "true" && css`
        background-color: white;
    `};
`;

const StoreButtonContainer = styled.div`
    justify-content: flex-end;
    align-items: center;
    z-index: 7;
    
    display: none;
    ${tablet`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        width: 100%;
    `};
    
    ${desktop`
        flex-direction: row;
        position: initial;
        width: 18em;
    `};
`;

const StoreButton = styled.img`
    height: 35px;
    margin: 0 0.6em 0;
`;

function Header(props) {
    const { location, top, url, transparent } = props;
    const currentPageIs = path => location.pathname === path;

    const linkColor = currentPageIs("/") ? "white" : null;

    return (
        <Container transparent={transparent} top={top} url={url} backgroundColor="transparent">
            <Content>
                <Logo>
                    <a href="https://dailyfixme.com" style={{ textDecoration: "none" }}>
                        <LogoImg />
                    </a>
                </Logo>
                <Navigation>
                    <LoggedOutNavigationLink to="/signUp" marginleft="0" ishighlighted={currentPageIs("/signUp").toString()} color={linkColor}>
                        Sign Up
                    </LoggedOutNavigationLink>
                    <LoggedOutNavigationLink to="/login" ishighlighted={currentPageIs("/login").toString()} color={linkColor}>
                        Log In
                    </LoggedOutNavigationLink>
                </Navigation>
                <StoreButtonContainer>
                    <a href="https://apps.apple.com/au/app/daily-fix-me-motivation/id1445382703">
                        <StoreButton src={AppleImg} alt="Download on the App Store"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.onpoint_holdings.dailyfixme">
                        <StoreButton src={GoogleImg} alt="Get it on Google Play"/>
                    </a>
                </StoreButtonContainer>
            </Content>
        </Container>
    );
}

export default withRouter(Header);