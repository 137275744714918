import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import axios from "axios";
import moment from "moment";
import "moment/min/locales";
import countdown from "countdown";
import { API_URL } from "App";
import Backdrop from "Backdrop";
import CSVModal from "./components/CSVModal";
import AccessTokenModal from "./components/AccessCodeModal";
import { CSVReader } from "react-papaparse";
import FlexContainer from "components/FlexContainer";
import Checkbox from "components/Checkbox";
import Text from "components/Text";
import Button from "components/Button";
import DifferentFonts from "components/DifferentFonts";
import LoadingIndicator from "LoadingIndicator";
import Tooltip from "./components/Tooltip";
import FormatAsYouType, { YearFormat } from "./components/FormatAsYouType";
import { AsYouType, getExampleNumber } from "libphonenumber-js";
import { isValidPhoneNumber, formatPhoneNumberIntl, parsePhoneNumber } from "react-phone-number-input";
import examples from "libphonenumber-js/examples.mobile.json";
import Toast from "Toast";
import Modal from "Modal";

//assets
import DeleteNewImg from "img/DeleteNew.svg";
import Correct from "img/Correct.svg";
import ArrowDown from "img/ArrowDown.svg";
import ArrowUp from "img/ArrowDown.svg";
import AddIcon from "img/add.png"

export const PageType = {
    EMPLOYEE: 1,
    CLIENT: 2
};

const HeaderContainer = styled.div`
    margin-top: 20px;
    overflow: auto;
    max-height: 7vh;
    min-height: 50px;   
    scrollbar-width: thin;
    scrollbar-color: #000 #000;
    ::-webkit-scrollbar {
        opacity: 0;
        background-color: #fff;
        width: .3em;
    }
    ::-webkit-scrollbar-track {
        opacity: 0;
    }
     
    ::-webkit-scrollbar-thumb {
        opacity: 0;
    }
`;

const ScrollContainer = styled.div`
    flex: 1;
    overflow: auto;
    max-height: 50vh;
    scrollbar-width: thin;
    ::-webkit-scrollbar {
        width: .3em;
    }
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }
     
    ::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
    
`;

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const Th = styled.th`    
    background-color: #000000;
    color: white;
    padding: 20px 10px;
    font-size: 13px;
    font-weight: 100;
    width: ${p => p.width || "5rem"};
    border-left:1px solid #FFFFFF;
`;

const Tr = styled.tr`
    :nth-child(even) {
        background-color: white;
    }
    :nth-child(odd) {
        background-color: #f3f3f4;
    }
`;

const Td = styled.td`
    width: ${p => p.width || "5rem"};
    border-right: ${p => p.borderRight || "1px solid #2e3036"};
    text-align: center;
    padding: 10px;
    font-size: 13px;
`;

const TextInput = styled.input`
    text-align: center;
    border: none;
    font-family: "Affogato";
    font-size: 14px;
    background-color: transparent;
    width: 100%;
    padding: 6px 0;
    ${ p => p.isHighlightred && css`
        border: 1px solid #FF4E4E;
        border-radius: 4px;
    `};
`;

const StyledButton = styled(Button)`
    color: white;
    padding: 10px 30px 10px;
    border-radius: 20px;
    background-color: ${ p => p.backgroundColor || "#51315D"};
`;

const NewUserButton = styled(StyledButton)`
    border: 1px solid #000000;
`;

const RemoveSelectedButton =styled(Button)`
background-color: #ff6464;
padding: 10px 20px 10px;
border-radius: 20px;
color: #FFFFFF;
position: relative;
cursor: pointer;
margin-right: 10px;
width: 180px; 
`;

const SendSMSInviteButton = styled(StyledButton)`
    ${ p => p.progress && css`
        animation-timing-function: linear;
        animation-name: sweep;
        animation-duration: 15s;
        animation-iteration-count: infinite;
        background: linear-gradient(to left, #002D6A, #002D6A 20%, #004db7 50%, #002D6A 80%, #002D6A);
        background-size: 500% 100%;

        @keyframes sweep {
            from {
                background-position: 0% 50%;
            }
            to {
                background-position: 500% 50%;
            }
        }
    ` }
`;

const DeleteButton = styled(Button)`
    padding: 0;
`;

const SendEmailInviteButton = styled(StyledButton)`
    ${ p => p.progress && css`
        animation-timing-function: linear;
        animation-name: sweep;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        background: linear-gradient(to left, #006CFF, #006CFF 20%, #004db7 50%, #006CFF 80%, #006CFF);
        background-size: 500% 100%;

        @keyframes sweep {
            from {
                background-position: 0% 50%;
            }
            to {
                background-position: 500% 50%;
            }
        }
    ` }
`;

const UploadButton = {
    opacity: 0,
    position: "relative",
    top: "-5px",
    left: "-130px",
    width: "130px",
    height: "33px"
};

const TextInTable = styled(Text)`
    margin: 0;
    text-align: center;
    border: none;
    color: ${p => p.color || "black"};
    font-family: "Affogato";
    size: "13px";
`;

const ValidatableTextInTable = styled(TextInTable)`
    ${ p => p.invalid && css`
        border: 2px solid #FF4E4E;
        border-radius: 4px;
    `}
`;

const ValidatableTextInput = styled(TextInput)`
    ${ p => p.invalid && css`
        border: 1px solid #FF4E4E;
        border-radius: 4px;
    `}
`;

const ModalContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100vw - 424px);
    height: calc(100vh - 256px);

    z-index: 500;
`;

const BorderContainer = styled.div`
    color: white;
    padding: 10px 30px 10px;
    border-radius: 20px;
    background-color: ${ p => p.backgroundColor || "#51315D"};
    text-align: center;
`;

const InputSelector = styled.select`
    background: #51315D;
    border:0;
    text-decoration: none;
    color: white;
`;

const ColumnHeader = (({ heading, sortDirection }) => {
    const Container = styled.div`
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: center;

        text-align: center;
        user-select: none;
        cursor: pointer;
    `;

    const SortIndicator = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding-left: 8px;
    `;

    return (
        <Container>
            {heading}
            <SortIndicator hidden={sortDirection === SortableColumnState.INACTIVE}>
                {(sortDirection === SortableColumnState.DESCENDING) &&
                    <img width="10px" src={ArrowDown} alt="SortArrow"/>}
                {(sortDirection === SortableColumnState.ASCENDING) &&
                    <img width="10px" src={ArrowUp} alt="SortArrow" style={{ transform: "rotate(180deg)" }} />}
            </SortIndicator>
        </Container>
    );
});

const ErrorList = {
    EMPTY_FIRST_NAME: 0,
    BIRTH_ERROR: 1,
    MOBILE_ERROR: 2,
    EMAIL_ERROR: 3,
    EMAIL_EXISTING: 4,
};

const ErrorStrings = {
    [ErrorList.EMAIL_EXISTING]: "* Sorry, email address already taken",
};

const SortableColumns = {  
    FIRST_NAME: 0,
    EMAIL: 1,
    MOBILE: 2,
    DEPARTMENT: 3,
    JOB_TITLE: 4,
    STATUS: 5,
    NOTICEBOARD_PERMISSION: 6,
    FLAG_LENGTH: 6,
    USER_CODE: 7,
};

const SortableColumnState = {
    INACTIVE: 0,
    DESCENDING: 1,
    ASCENDING: 2,
};

const DirtyState = {
    FALSE: 0,
    TRUE: 1,
    UNKNOWN: 2,
};

const returnErrorMessages = {
    PHONE_ERROR: "Please enter a valid phone number!"
};

class EmployeeList extends Component {
    state = {
        fetching: 0,
        employeeArray: [],
        companyName: "",
        companyPhoneNumber: "",
        isCompanyAustralian: false,
        remainDays: "",
        subscriptionPeriod: "",
        totalUsers: 125,
        existedUsers: 0,
        totalEmployees: 0,
        newLine: false,
        newFirstName: "",
        newLastName: "",
        newTitle: "",
        newDepartment: "",
        newBirthday: "",
        newMobile: "",
        newEmail: "",
        newPermission: false,
        redirect: false,
        isSentSmsInvitation: false,
        isSentEmailInvitation: false,
        errorList: [],
        sortableColumns: [],
        dirtyRows: 0,
        countryCallingCode: "",
        isLocaleSet: false,
        emailInviteInProgress: false,
        smsInviteInProgress: false,
        countryCode: "", // TODO: This is handled better in another un-landed diff.
        removeSelectedUsers: [],
        showRemoveSelectedButton: false,
        checkAllUsers: false,
        editUser: null,
        showCSVModal: false,
        showAccessTokenModal: false,
        userNewToken: {},
        userType: PageType.EMPLOYEE
    };

    componentDidMount() {
        this.getCountryCallingCode();
        this.getCompany();
        this.setState(state => ({
            fetching: state.fetching + 1
        }), () => this.safeToInit());
        this.setColumns();
    }

    safeToInit = () => {
        setTimeout(() => {
            if (this.state.isLocaleSet) {
                this.getEmployees();
                this.setState(state => ({
                    fetching: state.fetching - 1
                }));
            } else {
                this.safeToInit();
            }
        }, 25);
    };

    // No need to set state fetching, this function is only setting up the sortable columns array.
    setColumns = () => {
        let columns = [];
        for (let i = 0; i < SortableColumns.FLAG_LENGTH; i++) {
            columns.push(SortableColumnState.INACTIVE);
        }

        this.setState(state => ({
            sortableColumns: columns,
        }));
    };

    getCompany = () => {
        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        axios.get(`${API_URL}/company`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            this.setState(state => ({
                fetching: state.fetching - 1,
                companyName: res.data.companyName,
                totalEmployees: res.data.totalNumber,
                isCompanyAustralian: res.data.isAustralian,
                companyPhoneNumber: res.data.phoneNumber,
            }));
        }).catch(error => {
            return Toast.error(error.message);
        });
    };

    getSuffix = () => {
        const { isClient } = this.props;

        //detect if it is coming from employee or client
        let suffix;
        if (isClient) {
            suffix = "clients";
        } else {
            suffix = "employees";
        }

        return suffix;
    };

    initialEmployeeFieldValidation = () => {
        for (let i = 0; i < this.state.employeeArray.length; i++) {
            this.validateBirthDate(i);
            this.validateEmployeePhoneNumber(i);
        }
    };

    markFieldInvalid = (index, dataName) => {
        const employee = this.state.employeeArray[index];
        employee.invalidData[dataName] = "";
    };

    clearInvalidField = (index, dataName) => {
        const employee = this.state.employeeArray[index];
        if (dataName in employee.invalidData)
            delete employee.invalidData[dataName];
    };

    doesEmployeeByIndexHaveError = (index) => {
        const employee = this.state.employeeArray[index];

        if (employee.invalidData != null && Object.keys(employee.invalidData).length > 0) {
            return true;
        }

        return false;
    };

    doesEmployeeHaveError = (employee) => {
        if (employee.invalidData != null && Object.keys(employee.invalidData).length > 0) {
            return true;
        }

        return false;
    };

    showInvalidPhoneNumberTooltip = (index) => {
        if (!document.activeElement.className.includes("ValidationPhoneNumber" + index)) {
            return false;
        }

        return !this.validateEmployeePhoneNumber(index);
    };

    showInvalidBirthDateTooltip = (index) => {
        if (!document.activeElement.className.includes("ValidationBirthDate" + index)) {
            return false;
        }

        return !this.validateBirthDate(index);
    };

    validateEmployeePhoneNumber = (index) => {
        if (index === "New") {
            const phoneNumber = this.state.newMobile;
            let phoneStr = phoneNumber ? phoneNumber.replace(/\s+/g, "") : "";
            let valid = true;
            if (phoneStr && phoneStr.length > 0) {
                phoneStr = phoneStr[0] !== "+" ? `+${this.state.countryCallingCode}${phoneStr}` : phoneStr;
                valid = isValidPhoneNumber(phoneStr);
            }
            return valid;
        }

        const employees = this.state.employeeArray;
        const phoneNumber = employees[index]["phoneNumber"];
        if (!phoneNumber) {
            this.clearInvalidField(index, "phoneNumber");
            return true;
        } else {
            // Users can store spaces in their phone numbers, but any regex is less complicated with them removed temporarily.
            let phoneStr = phoneNumber ? phoneNumber.replace(/\s+/g, "") : "";
            let valid = true;
            if (phoneStr && phoneStr.length > 0) {
                phoneStr = phoneStr[0] !== "+" ? `+${this.state.countryCallingCode}${phoneStr}` : phoneStr;
                valid = isValidPhoneNumber(phoneStr);
            }

            if (!valid) {
                this.markFieldInvalid(index, "phoneNumber");
            } else {
                this.clearInvalidField(index, "phoneNumber");
            }

            return valid;
        }
    };

    validatePhoneNumber = (phoneNumber) => {
        if (!phoneNumber) {
            return true;
        } else {
            // Users can store spaces in their phone numbers, but any regex is less complicated with them removed temporarily.
            let phoneStr = (phoneNumber.toString()).replace(/\s+/g, "");
            phoneStr = phoneStr[0] !== "+" ? `+${this.state.countryCallingCode}${phoneStr}` : phoneStr;
            return isValidPhoneNumber(phoneStr);
        }
    };

    validateEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    }

    validateBirthDate = (index) => {
        const employees = this.state.employeeArray;
        let birthStr = "";
        if (index !== "New") {
            birthStr = employees[index]["dateOfBirth"];
        } else {
            birthStr = this.state.newBirthday;
        }
        let valid = !birthStr || birthStr.length === 0;

        if (FormatAsYouType.formatType(birthStr, this.state.countryCode) !== YearFormat.UNKNOWN &&
            birthStr &&
            birthStr.replace(/[-/]/g, "").length === 8) {
            let birthDate = moment(birthStr, ["L", moment.HTML5_FMT.DATE]);
            if (birthDate.isValid()) {
                valid = birthDate.isSameOrAfter(new Date("1900/01/01"));
            }
        }

        if (index !== "New") {
            if (!valid) {
                this.markFieldInvalid(index, "dateOfBirth");
            } else {
                this.clearInvalidField(index, "dateOfBirth");
            }
        }

        return valid;
    };

    /**
     * 3-step process setting a default country code.
     * 1. Asking the API what our calling code should be based on IP.
     * 2. Seeing what country code the company account's phone number has.
     * 3. Assume Australia (+61) if company 'isAustralian' or American (+1) if company is not.
     */
    getCountryCallingCode = () => {
        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        // Getting the country code of the user.
        axios.get(`${API_URL}/callingCode`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            // Step 1 (Get calling code from API)
            let callingCode = res.data.callingCode;
            let countryCode = res.data.countryCode;

            if (callingCode == null) {
                let phoneNumber = parsePhoneNumber(this.state.companyPhoneNumber);

                // Step 2 (Checking the code of the company phone)
                if (phoneNumber) {
                    callingCode = phoneNumber.countryCallingCode;

                // Step 3 (Predefined Assumption)
                } else {
                    callingCode = this.state.isCompanyAustralian ? "61" : "1";
                }
            }

            this.setState({
                countryCallingCode: callingCode,
                countryCode: countryCode,
            }, () => {
                if (this.state.countryCode) {
                    moment.locale(this.state.countryCode);
                } else {
                    moment.locale(navigator.language);
                }
            });
        }).finally(() => {
            this.setState(state => ({
                fetching: state.fetching - 1,
                isLocaleSet: true,
            }));
        }).catch(error => {
            return Toast.error(error.message);
        });
    };

    asYouTypeAssist = (phoneNumber) => {
        if (phoneNumber.length === 0) {
            return "";
        }

        let hasCallingCode = phoneNumber[0] === "+";
        let formattedNumber = phoneNumber;

        if (!hasCallingCode) {
            formattedNumber = `+${this.state.countryCallingCode}${formattedNumber}`;
        }

        // Special: Remove the 0 at the start in an Australian phonen number (0475 555 555) -> (475 555 555).
        let keepLeadingZero = false;
        if (formattedNumber.substr(0, 4) === "+610" && formattedNumber.toString().length > 4) {
            if (!hasCallingCode) {
                keepLeadingZero = true;
            }
            formattedNumber = formattedNumber.replace(/\+610/g, "+61");
        }

        formattedNumber = new AsYouType().input(formattedNumber);

        // Special: If a zero is written for an Australian number in a national format, add it back in.
        if (keepLeadingZero) {
            formattedNumber = formattedNumber.replace(/\+61 /g, "+610");
        }

        if (!hasCallingCode) {
            const regex = new RegExp("\\+" + this.state.countryCallingCode, "g");
            formattedNumber = formattedNumber.replace(regex, "");
        }

        return formattedNumber;
    };

    getEmployees = () => {
        const { isClient } = this.props;
        let suffix = this.getSuffix();

        this.setState(state => ({
            fetching: state.fetching + 1,
            dirtyRows: 0
        }));

        axios.get(`${API_URL}/${suffix}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            let companyName = res.data.companyName;
            let totalUsers = res.data.totalUsers;
            let existedUsers = res.data.existedUsers;

            if (isClient) {
                //calculate remaining days
                let startDate = res.data.subscriptionStart;
                let subscriptionPeriod = res.data.subscriptionPeriod;
                let expiredDate = moment(startDate).add(subscriptionPeriod, "months");
                let remainDays = countdown(moment(), expiredDate, countdown.DAYS).days;
                let allClients = res.data.clients;

                allClients && allClients.forEach(element => {
                    let formattedNumber = "";
                    if (element.phoneNumber && element.phoneNumber.length > 0) {
                        formattedNumber = formatPhoneNumberIntl(element.phoneNumber);
                    }
                    element.phoneNumber = formattedNumber.length > 0 ? formattedNumber : element.phoneNumber;
                    element.isDirty = DirtyState.FALSE;
                    element.invalidData = {};
                    if (element.dateOfBirth) {
                        element.dateOfBirth =
                            moment(element.dateOfBirth, ["L", moment.HTML5_FMT.DATE])
                                .format("L");
                    }
                });

                this.setState(state => ({
                    companyName: companyName,
                    remainDays: remainDays,
                    subscriptionPeriod: subscriptionPeriod,
                    totalUsers: totalUsers,
                    existedUsers: existedUsers,
                    employeeArray: allClients
                }));
            } else {
                let allEmployees = res.data.employees;

                allEmployees && allEmployees.forEach(element => {
                    let formattedNumber = "";
                    if (element.phoneNumber && element.phoneNumber.length > 0) {
                        formattedNumber = formatPhoneNumberIntl(element.phoneNumber);
                    }
                    element.phoneNumber = formattedNumber.length > 0 ? formattedNumber : element.phoneNumber;
                    element.isDirty = DirtyState.FALSE;
                    element.invalidData = {};
                    if (element.dateOfBirth) {                        
                        const formatter = Intl.DateTimeFormat('default', { year: 'numeric', month: '2-digit', day: '2-digit'});
                        const date = new Date(element.dateOfBirth);                        
                        element.dateOfBirth = formatter.format(date);
                    }
                });

                this.setState(state => ({
                    companyName: companyName,
                    totalUsers: totalUsers,
                    existedUsers: existedUsers,
                    employeeArray: allEmployees
                }));
            }
            // Initially sort list based on application signup status and field errors.
            this.initialEmployeeFieldValidation();
            let sortable = this.state.sortableColumns;
            sortable[SortableColumns.STATUS] = SortableColumnState.ASCENDING;
            this.setState({
                sortableColumns: sortable,
            });
            this.sortStatus(this.state.sortableColumns[SortableColumns.STATUS]);
        }).finally(() => {
            this.setState(state => ({
                fetching: state.fetching - 1,
            }));
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
    };

    upload = (items) => {
        const { isClient } = this.props;

        let inputs = items.data;

        //make sure email is filled
        inputs = inputs.filter(input => input["Email Address"] && (input["Email Address"].length > 0));

        let data = [];
        inputs.forEach(element => {
            let display = {};
            display["newUser"] = true;
            display["firstName"] = element["Employee First Name"] ? element["Employee First Name"] : element["Client First Name"];
            display["lastName"] = element["Last Name"];
            display["dateOfBirth"] = element["Birth Date"].replace(/[ /]/g, "-");
            if (display["dateOfBirth"]) {
                display["dateOfBirth"] =
                    moment(display["dateOfBirth"], ["L", moment.HTML5_FMT.DATE])
                        .format(moment.HTML5_FMT.DATE);
            }
            if (element["Mobile Number"].length > 8 && element["Mobile Number"].charAt(0) !== "+") {
                display["phoneNumber"] = "+" + element["Mobile Number"];
            } else {
                display["phoneNumber"] = element["Mobile Number"];
            }
            display["email"] = element["Email Address"];
            if (!isClient) {
                display["employmentPosition"] = element["Job Title"];
                display["department"] = element["Department/Group"];
            }
            display["isDirty"] = DirtyState.TRUE;
            display["invalidData"] = {};

            data.push(display);
        });

        this.insertDataToForm(data);
    };

    insertDataToForm = (data) => {
        let employees = this.state.employeeArray;
        let newEmployees = [];

        data.forEach(element => {
            let exists = employees.find(employee => element["email"] === employee["email"]);
            if (!exists) {
                newEmployees.push(element);
            }
        });

        employees = newEmployees.concat(employees);
        let dirtyRowCount = this.state.dirtyRows + data.length;
        let sortables = this.state.sortableColumns;
        sortables[SortableColumns.STATUS] = SortableColumnState.ASCENDING;

        this.setState({
            employeeArray: employees,
            dirtyRows: dirtyRowCount,
            sortableColumns: sortables,
        });
    };

    postEmployees = (data) => {
        let suffix = this.getSuffix();
        let jsonArray = JSON.parse(JSON.stringify(data));

        this.setState({
            errorList: [],
            fetching: this.state.fetching + 1
        });
        axios.post(`${API_URL}/${suffix}`,
            jsonArray, {
                headers: {
                    Authorization: "Bearer " + this.props.user.token
                }
            })
            .then(res => {
                this.getEmployees();
                this.removeNew();
                this.setState({
                    fetching: this.state.fetching - 1
                });
            })
            .catch(error => {
                this.setState({
                    fetching: this.state.fetching - 1
                });                
                if (error.response) {
                    let errors = [];
                    if (error.response.data.error === returnErrorMessages.PHONE_ERROR) {
                        errors.push(ErrorList.MOBILE_ERROR);
                        this.setState({
                            errorList: errors
                        });
                    }

                    let messages = "";
                    if (error.response.data.error.messages) {
                        if (typeof(error.response.data.error.messages) === "object") {
                            messages = error.response.data.error.messages;
                        } else {
                            messages = error.response.data.error.messages.split("\n");
                        }
                    } else {
                        messages = error.response.data.error.toString();
                    }

                    if (typeof(messages) === "object" ?
                        messages.find(v => v === "Please add more users") :
                        messages === "Please add more users"
                    ) {
                        return Modal.open(
                            "Subscription Cap Reached",
                            <div>
                                <p>You have used up all available subscriptions. You will need to purchase more in
                                    order to add new clients/employees. Alternatively, you can remove an existing
                                    user to free up a slot -- however, this does mean that user will no longer be
                                    able to use DailyFixMe.</p>
                                <p>To add more users simply click <strong>Active Subscriptions</strong> in
                                    the navigation panel, or click the button below, and purchase
                                    more subscriptions.
                                </p>
                            </div>,
                            () => window.location.href = "/active",
                            "Purchase More Users",
                            false
                        );
                    }

                    if (typeof(messages) === "object") {
                        messages.forEach(message => {
                            return Toast.error(message);
                        });
                    } else {
                        return Toast.error(messages, 10000);
                    }
                } else {
                    return Toast.error(error.message);
                }
             });
    };

    addNew = () => {
        this.setState({
            newLine: true,
            errorList: [],
        });
    };

    removeNew = () => {
        this.setState({
            newLine: false,
            newFirstName: "",
            newLastName: "",
            newTitle: "",
            newDepartment: "",
            newBirthday: "",
            newMobile: "",
            newEmail: "",
            newPermission: false,
        });
    };

    newUser = () => {
        const { isClient } = this.props;

            let errorList = [];
            let newEmployee = {};
            newEmployee["newUser"] = true;
            newEmployee["firstName"] = this.state.newFirstName;
            newEmployee["lastName"] = this.state.newLastName;
            newEmployee["email"] = this.state.newEmail;
            newEmployee["phoneNumber"] = this.state.newMobile.replace(/\s+/g, "");
            if (newEmployee["phoneNumber"]) {
                newEmployee["phoneNumber"] = newEmployee["phoneNumber"][0] !== "+" ?
                    `+${this.state.countryCallingCode}${newEmployee["phoneNumber"]}` : newEmployee["phoneNumber"];
            }
            newEmployee["dateOfBirth"] = this.state.newBirthday.replace(/[ /]/g, "-");
            if (newEmployee["dateOfBirth"]) {                
                const dateOfBirth = new Date(newEmployee["dateOfBirth"]);
                if (dateOfBirth == "Invalid Date") {
                    errorList.push(ErrorList.BIRTH_ERROR);
                } else {                    
                    const formatter = Intl.DateTimeFormat('default', { year: 'numeric', month: '2-digit', day: '2-digit'});
                    newEmployee["dateOfBirth"] = formatter.format(dateOfBirth);
                }
            }

            if (!isClient) {
                newEmployee["employmentPosition"] = this.state.newTitle;
                newEmployee["department"] = this.state.newDepartment;
                newEmployee["noticeboardPermission"] = this.state.newPermission;
            }

            // Validates email
            if (newEmployee["email"]) {
                if (!this.validateEmail(newEmployee["email"])) {
                    errorList.push(ErrorList.EMAIL_ERROR);
                }
                
                const checkEmail = obj => obj.email === newEmployee["email"];
                if(this.state.employeeArray.some(checkEmail)) {
                    errorList.push(ErrorList.EMAIL_EXISTING);
                }
                
            }
      
            if (newEmployee["firstName"] === "") {
                errorList.push(ErrorList.EMPTY_FIRST_NAME);
            }

            return { newEmployee, errorList };
    }

    saveNew = () => {
        let {newEmployee, errorList } = this.newUser();

        if (errorList.length === 0) {
            this.postEmployees([newEmployee]);
        } else {
            this.setState({
                errorList: errorList,
            });
        }
    };

    updateEmployeeValue = (index, detailIndex, value) => {
        const employees = this.state.employeeArray;
        let dirtyRowCount = this.state.dirtyRows;

        if (employees[index][detailIndex] !== value &&
            employees[index]["isDirty"] === DirtyState.FALSE) {
            employees[index]["isDirty"] = DirtyState.TRUE;
            dirtyRowCount++;
        }
        if (detailIndex === "dateOfBirth") {
            if (employees[index]["dateOfBirth"] === "Invalid date") {
                value = "";
            }
            employees[index][detailIndex] = FormatAsYouType.dob(value, this.state.countryCode);
        } else {
            employees[index][detailIndex] = value;
        }

        this.setState({
            employeeArray: employees,
            dirtyRows: dirtyRowCount,
        });
    };

    updateEmployee = (employee, index) => {
        let employees = this.state.employeeArray;

        employees[index] = employee;

        this.setState({
            employeeArray: employees
        });
    };

    formatEmployeeValues = (employee, index) => {
        employee["phoneNumber"] = formatPhoneNumberIntl(employee["phoneNumber"]);
        if (employee["dateOfBirth"]) {
            employee["dateOfBirth"] =
                moment(employee["dateOfBirth"], ["L", moment.HTML5_FMT.DATE])
                    .format("L");
        }
        this.updateEmployee(employee);
    };

    removeEmployee = (uuid) => {
        if (window.confirm("Are you sure you want to delete this user?")) {
            let suffix = this.getSuffix();

            this.setState({
                fetching: this.state.fetching + 1
            });

            axios.delete(`${API_URL}/${suffix}/${uuid}`,
                {
                    headers: {
                        Authorization: "Bearer " + this.props.user.token
                    }
                })
                .then(res => {
                    this.setState({
                        fetching: this.state.fetching - 1
                    });
                    this.getEmployees();
                })
                .catch(error => {
                    this.setState({
                        fetching: this.state.fetching - 1
                    });
                    if (error.response) {
                        return Toast.error(error.response.data.error);
                    } else {
                        return Toast.error(error.message);
                    }
                });
        }
    };

    saveAllDirty = () => {
        this.setState({
            errorList: []
        });
        let bulkEmployees = this.state.employeeArray.flatMap(a => a.isDirty === 1 ? a : []);
        
        if(this.state.newLine) {
            
            let {newEmployee, errorList } = this.newUser();

            bulkEmployees.push(newEmployee);

            if (errorList.length > 0) {
                this.setState({
                    errorList: errorList,
                });
            }      
        }
        
        this.postEmployees(bulkEmployees);
    };

    postEmployee = (index) => {
        let suffix = this.getSuffix();
        let employees = this.state.employeeArray;
        let employee = employees[index];
        let errorList = [];

        employee["isDirty"] = DirtyState.UNKNOWN;
        employee["newUser"] = true;
        // Escapes save function if email is not validated
        if (!this.validateEmail(employee["email"])) {
            employee["isDirty"] = DirtyState.TRUE;
            errorList.push(ErrorList.EMAIL_ERROR);
        }

        if (employee["phoneNumber"]) {
            employee["phoneNumber"] = employee["phoneNumber"].replace(/\s+/g, "");
            employee["phoneNumber"] = employee["phoneNumber"][0] !== "+" ?
                `+${this.state.countryCallingCode}${employee["phoneNumber"]}` : employee["phoneNumber"];
        }
        if (employee["dateOfBirth"]) {
            employee["dateOfBirth"] = employee["dateOfBirth"].replace(/[ /]/g, "-");
            employee["dateOfBirth"] =
                moment(employee["dateOfBirth"], ["L", moment.HTML5_FMT.DATE])
                    .format(moment.HTML5_FMT.DATE);
        }

        if (errorList.length > 0) {
            this.setState({
                errorList: errorList
            })
        } else {
            axios.post(`${API_URL}/${suffix}`,
                [employee], {
                    headers: {
                        Authorization: "Bearer " + this.props.user.token
                    }
                })
                .then(res => {
                    this.getEmployees();
                })
                .catch(error => {
                    if (error.response) {
                        let errors = [];
                        if (error.response.data.error === returnErrorMessages.PHONE_ERROR) {
                            errors.push(ErrorList.MOBILE_ERROR);
                            this.setState({
                                errorList: errors
                            });
                        }
                        if (error.response.data.error.messages) {
                            return Toast.error(error.response.data.error.messages.toString());
                        }
                        return Toast.error(error.response.data.error.toString());
                    } else {
                        return Toast.error(error.message);
                    }
                });

            this.updateEmployee(employee, index);
        }
    }

    saveEmployee = (index, uuid) => {
        let suffix = this.getSuffix();
        let employees = this.state.employeeArray;
        let employee = employees[index];
        let errorList = [];

        employee["isDirty"] = DirtyState.UNKNOWN;

        // Escapes save function if email is not validated
        if (!this.validateEmail(employee["email"])) {
            employee["isDirty"] = DirtyState.TRUE;
            errorList.push(ErrorList.EMAIL_ERROR);
        }

        if (!employee["firstName"]) {
            employee["isDirty"] = DirtyState.TRUE;
            errorList.push(ErrorList.FIRST_NAME);
        }

        if (employee["phoneNumber"]) {
            employee["phoneNumber"] = employee["phoneNumber"].replace(/\s+/g, "");
            employee["phoneNumber"] = employee["phoneNumber"][0] !== "+" ?
                `+${this.state.countryCallingCode}${employee["phoneNumber"]}` : employee["phoneNumber"];
        }
        if (employee["dateOfBirth"]) {
            employee["dateOfBirth"] = employee["dateOfBirth"].replace(/[ /]/g, "-");
            employee["dateOfBirth"] =
                moment(employee["dateOfBirth"], ["L", moment.HTML5_FMT.DATE])
                    .format(moment.HTML5_FMT.DATE);
        }

        if (errorList.length !== 0) {
            this.setState({
                errorList: errorList
            })
        } else {
            axios.put(`${API_URL}/${suffix}/${uuid}`,
                employee, {
                    headers: {
                        Authorization: "Bearer " + this.props.user.token
                    }
                })
                .then(res => {
                    employee["isDirty"] = DirtyState.FALSE;
                    let dirtyRowCount = this.state.dirtyRows - 1;

                    this.formatEmployeeValues(employee, index);

                    this.setState({
                        dirtyRows: dirtyRowCount,
                    });
                })
                .catch(error => {
                    employee["isDirty"] = DirtyState.TRUE;
                    this.formatEmployeeValues(employee, index);

                    if (error.response) {
                        if (error.response.data.error.messages) {
                            return Toast.error(error.response.data.error.messages.toString());
                        }
                        return Toast.error(error.response.data.error.toString());
                    } else {
                        return Toast.error(error.message);
                    }
                });
            this.updateEmployee(employee, index);
        }
    };

    purchaseMore = () => {
        const { history } = this.props;
        localStorage.setItem("redirect", true);
        history.push("/active");
    };

    updateSmsInvite = () => {
        this.setState({
            isSentSmsInvitation: !this.state.isSentSmsInvitation,
        });
    };

    updateEmailInvite = () => {
        this.setState({
            isSentEmailInvitation: !this.state.isSentEmailInvitation,
        });
    };

    sortColumns = (columnIndex) => {
        const columns = this.state.sortableColumns;
        let fieldName = "";

        // Setting all other column values to inactive, this way they will always start as descending when clicked after another column,
        // while any subsequent clicks will toggle between descending and ascending sort.
        for (let i = 0; i < SortableColumns.FLAG_LENGTH; i++) {
            if (i === columnIndex) {
                let value = columns[columnIndex];

                switch (value) {
                case SortableColumnState.DESCENDING:
                    value = SortableColumnState.ASCENDING;
                    break;
                case SortableColumnState.ASCENDING:
                case SortableColumnState.INACTIVE:
                default:
                    value = SortableColumnState.DESCENDING;
                    break;
                }

                columns[columnIndex] = value;
            } else {
                columns[i] = SortableColumnState.INACTIVE;
            }
        }

        // Calling the sort for the particular column affected.
        switch (columnIndex) {
        case SortableColumns.FIRST_NAME:
            fieldName = "firstName";
            break;
        case SortableColumns.LAST_NAME:
            fieldName = "lastName";
            break;
        case SortableColumns.BIRTH_DATE:
            fieldName = "dateOfBirth";
            break;
        case SortableColumns.DEPARTMENT:
            fieldName = "department";
            break;
        case SortableColumns.JOB_TITLE:
            fieldName = "employmentPosition";
            break;
        case SortableColumns.EMAIL:
            fieldName = "email";
            break;
        case SortableColumns.MOBILE:
            fieldName = "phoneNumber";
            break;
        case SortableColumns.NOTICEBOARD_PERMISSION:
            fieldName = "noticeboardPermission";
            break;
        case SortableColumns.STATUS:
        default:
            this.sortStatus(columns[columnIndex]);
            break;
        }

        if (fieldName !== "")
            this.sortField(columns[columnIndex], fieldName);

        this.setState({
            sortableColumns: columns
        });
    };

    sortField = (sortableState, fieldName) => {
        const employees = this.state.employeeArray;

        if (sortableState === SortableColumnState.ASCENDING) {
            employees.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : -1);
        } else {
            employees.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : -1);
        }

        this.setState({
            employeeArray: employees
        });
    };

    sortStatus = (sortableState) => {
        const employees = this.state.employeeArray;

        if (sortableState === SortableColumnState.ASCENDING) {
            employees.sort((a, b) => {
                let aStatus = a.status ? "ACTIVE" : (a.hasReceivedInvitation ? "AWAITING INVITATION" : this.doesEmployeeHaveError(a) ? "ERROR" : "PENDING");
                let bStatus = b.status ? "ACTIVE" : (b.hasReceivedInvitation ? "AWAITING INVITATION" : this.doesEmployeeHaveError(b) ? "ERROR" : "PENDING");
                return (aStatus > bStatus) ? 1 : -1;
            });
        } else {
            employees.sort((a, b) => {
                let aStatus = a.status ? "ACTIVE" : (a.hasReceivedInvitation ? "AWAITING INVITATION" : this.doesEmployeeHaveError(a) ? "ERROR" : "PENDING");
                let bStatus = b.status ? "ACTIVE" : (b.hasReceivedInvitation ? "AWAITING INVITATION" : this.doesEmployeeHaveError(b) ? "ERROR" : "PENDING");
                return (aStatus < bStatus) ? 1 : -1;
            });
        }

        this.setState({
            employeeArray: employees
        });
    };

    birthdayToolTip = (index) => {
        let dobValue = "";
        if (index >= 0) {
            const employees = this.state.employeeArray;
            const employee = employees[index];
            dobValue = employee["dateOfBirth"];
        } else {
            dobValue = this.state.newBirthday;
        }
        let locale = moment.locale().split("-");
        let localeStr = locale[locale.length - 1].toUpperCase();
        localeStr = localeStr === "EN" ? "US" : localeStr;

        let dobFormat = FormatAsYouType.formatType(dobValue, localeStr);
        const example = "Example of a valid date";

        // Because the system would enter the wrong date if the system expects an American format but the
        // user is typing an international one, or vice versa, we have to force their hand.
        if (localeStr === "US" && dobFormat === YearFormat.INTL) {
            dobFormat = YearFormat.AMERICAN;
        }

        switch (dobFormat) {
        default:
        case YearFormat.UNKNOWN:
            break;
        case YearFormat.INTL:
            return `${example} (International): DD/MM/YYYY`;
        case YearFormat.ISO_8601:
            return `${example}: YYYY/MM/DD`;
        case YearFormat.AMERICAN:
            return `${example} (American): MM/DD/YYYY`;
        }

        return `${example} ${ localeStr === "US" ?
            "(American): MM/DD/YYYY" : "(International): DD/MM/YYYY"}`;
    };

    sendSmsInvite = () => {
        let suffix = this.getSuffix();
        if (this.state.smsInviteInProgress) {
            return;
        }

        // Facebook Pixel Tracking
        window.fbq && window.fbq("track", "Contact");

        this.setState({
            smsInviteInProgress: true,
        });

        axios({
            method: "GET",
            url: `${API_URL}/company/${suffix}/sendSmsInvites`,
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then((response) => {
            if (response.data.subject !== null && response.data.subject === "rate-limit") {
                Modal.open("SMS Rate-Limited", response.data.info);
            } else {
                this.updateSmsInvite();
            }
        }).finally(() => {
            this.setState({
                smsInviteInProgress: false,
            }, () => {
                this.getEmployees();
            });
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
    };

    sendEmailInvite = () => {
        let suffix = this.getSuffix();
        if (this.state.emailInviteInProgress) {
            return;
        }

        // Facebook Pixel Tracking
        window.fbq && window.fbq("track", "Contact");

        this.setState({
            emailInviteInProgress: true,
        });

        axios({
            method: "GET",
            url: `${API_URL}/company/${suffix}/checkEmails`,
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then((response) => {
            if (response.data.subject !== null && response.data.subject === "rate-limit") {
                Modal.open("Email Rate-Limited", response.data.info);
            } else {
                this.updateEmailInvite();
            }
        }).finally(() => {
            this.setState({
                emailInviteInProgress: false
            }, () => {
                this.getEmployees();
            });
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
    };

    getExample = (number) => {
        if (number == null || number.length === 0) {
            return "";
        }

        if (number[0] !== "+") {
            number = `+${this.state.countryCallingCode} ${number}`;
        }

        const asYouType = new AsYouType();
        asYouType.input(number);

        const phoneNumber = asYouType.getNumber();
        if (phoneNumber == null || phoneNumber.countryCallingCode == null) {
            return "";
        }

        const callingCodes = phoneNumber.metadata.country_calling_codes;
        const country = callingCodes[phoneNumber.countryCallingCode][0];

        return ` Example (${country}): ${ getExampleNumber(country, examples).formatNational() }`;
    };

    selectQuoteCategory = () => {
        const { history } = this.props;
        history.push("/quoteSelection");
    }

    showCSVModal = () => {
        this.setState({
            showCSVModal: true
        });
    }

    generateNewAccessToken = (uuid) => {
        this.setState({
            fetching: this.state.fetching + 1
        });

        axios.post(`${API_URL}//updateAccessToken/`,
            {uuid: uuid},
            {
                headers: {
                    Authorization: "Bearer " + this.props.user.token
                }
            })
            .then(res => {
                this.setState({
                    fetching: this.state.fetching - 1,
                    showAccessTokenModal: false
                });
                this.getEmployees();  
            })
            .catch(error => {
                this.setState({
                    fetching: this.state.fetching - 1
                });
                if (error.response) {
                    return Toast.error(error.response.data.error);
                } else {
                    return Toast.error(error.message);
                }
            });
    }

    handlecheckAllUsers = () => {
        this.setState({
            checkAllUsers: !this.state.checkAllUsers
        }, () => {            
            if (this.state.checkAllUsers == false) {
                this.setState({
                    removeSelectedUsers: []
                });
            } else {
                this.setState({
                    removeSelectedUsers: this.state.employeeArray.map((employee => employee.uuid))
                });
            }

            this.setState({
                showRemoveSelectedButton: this.state.checkAllUsers
            });
            
        });
    }

    updateCheckAllInput = (length) => {
        if (length == 0) {
            this.setState({showRemoveSelectedButton: false});
        }
    }

    addRemoveUser = (uuid) => {
        if (this.state.removeSelectedUsers.includes(uuid)) {
            let removeSelectedUsers = this.state.removeSelectedUsers.filter(item => item !== uuid);
            this.setState({
                removeSelectedUsers: removeSelectedUsers,
                checkAllUsers: false
            }, this.updateCheckAllInput(removeSelectedUsers.length));
        } else {
            this.setState({ 
                removeSelectedUsers: [...this.state.removeSelectedUsers, uuid] 
            }, () => {
                this.setState({showRemoveSelectedButton: true});
                if (this.state.existedUsers == this.state.removeSelectedUsers.length) {
                    this.setState({checkAllUsers: true})
                }
            });
        }        
    }

    handleRemoveSelectedUsers = () => {
        if (window.confirm("Are you sure you want to delete selected user(s)?")) {
            let suffix = this.getSuffix();

            this.setState({
                fetching: this.state.fetching + 1
            });

            axios.post(`${API_URL}/${suffix}/deleteUsers/`,
                {uuids: this.state.removeSelectedUsers},
                {
                    headers: {
                        Authorization: "Bearer " + this.props.user.token
                    }
                })
                .then(res => {
                    this.setState({
                        fetching: this.state.fetching - 1,        
                        removeSelectedUsers: [],
                        showRemoveSelectedButton: false,
                        checkAllUsers: false,
                    });
                    this.getEmployees();                    
                })
                .catch(error => {
                    this.setState({
                        fetching: this.state.fetching - 1
                    });
                    if (error.response) {
                        return Toast.error(error.response.data.error);
                    } else {
                        return Toast.error(error.message);
                    }
                });
        }
    }

    showAccessTokenModal = (email, uuid) => {
        let data = {
            "email": email,
            "uuid": uuid
        };

        this.setState({
            showAccessTokenModal: true,
            userNewToken: data
        });
    }
    
    render() {
        const isClient = this.state.userType == PageType.EMPLOYEE ? false : true;
        const { fetching, employeeArray, companyName,
            totalUsers, newLine, smsInviteInProgress,
            emailInviteInProgress, isSentEmailInvitation,
            isSentSmsInvitation, existedUsers, remainDays,
            subscriptionPeriod, errorList, checkAllUsers, showRemoveSelectedButton, removeSelectedUsers, showCSVModal, showAccessTokenModal
        } = this.state;

        const pageName = isClient ? "Client" : "Employee"
        if (fetching > 0) {
            return <LoadingIndicator />;
        }

        if (isSentSmsInvitation) {
            return <InviteConfirmation updateInvite={this.updateSmsInvite} isClient={isClient} inviteType="SMS"/>;
        }

        if (isSentEmailInvitation) {
            return <InviteConfirmation updateInvite={this.updateEmailInvite} isClient={isClient} inviteType="email"/>;
        }

        //generate empty lines when employee/client list is less than 10 records
        let emptyList;
        if (employeeArray.length < 10) {
            emptyList = Array.apply(0, Array(10 - employeeArray.length)).map((value, index) => {
                return (
                    <Tr key={index} style={{ height: "46px" }}>
                        <Td width={"8%"}/>
                        <Td width={!isClient ? "8%" : "12%"}/>
                        <Td width={!isClient ? "17%" : "20%"}/>
                        <Td width={!isClient ? "10%" : "10%"}/>
                        {!isClient && <Td width={"15%"}/>}
                        <Td width={!isClient ? "8%" : "15%"}/>
                        <Td width={"10%"}/>
                        <Td width={"12%"}/>
                        <Td width={!isClient ? "16%" : "20%"} borderRight="none"/>
                    </Tr>
                );});
        }

        return (
            <>
                <FlexContainer>
                    <Text size="30px" color="#2e3036" weight="500">
                        { pageName } User List
                    </Text>
                    <Text size="20px" color="#23262D" weight="500" margin="0">
                        Total {pageName}s: <strong>{employeeArray.length}</strong>
                    </Text>
                    <Text size="20px" color="#23262D" weight="500" margin="0">
                        Total {pageName}s: <strong>
                            { isClient ?
                                totalUsers - ((existedUsers - employeeArray.length) + employeeArray.length)
                                : totalUsers - (employeeArray.length + (existedUsers - employeeArray.length))}
                        </strong>
                    </Text>
                    <FlexContainer height="40px" marginTop="20px" direction="row" width="100%">
                        <FlexContainer direction="row" width="10%">
                            <StyledButton backgroundColor="#000000" color="#FFFFFF" style={{ position: "relative", cursor: "pointer" }}>
                                Upload .csv File
                            </StyledButton>
                            <CSVReader
                                onFileLoaded={this.upload}
                                inputRef={this.fileInput}
                                style={UploadButton}                        
                                configOptions={{ header: true }}
                            />                            
                        </FlexContainer>
                        <FlexContainer direction="row" width="17%">
                            <Text color="#2e3036">
                                Download Your Current {pageName} List&nbsp;
                            </Text>
                            <Text weight="500" decoration="underline" onClick={this.showCSVModal}>Here</Text>
                            </FlexContainer>
                        <FlexContainer width="15%">
                            {showRemoveSelectedButton && <RemoveSelectedButton onClick={this.handleRemoveSelectedUsers}>
                                Remove Selected Users
                            </RemoveSelectedButton>}
                        </FlexContainer>
                        <FlexContainer width="15%">
                            <BorderContainer>
                                <InputSelector onChange={(e) => this.setState({userType: e.target.value})}>
                                    <option value={PageType.EMPLOYEE}>Employee List</option>
                                    <option value={PageType.CLIENT}>Client List</option>                                    
                                </InputSelector>
                            </BorderContainer>
                        </FlexContainer>
                        <FlexContainer direction="row" width="43%" justifyContent="flex-end">
                            {
                                this.state.dirtyRows > 1 ?
                                    <StyledButton style={{ marginRight: "10px" }} onClick={this.saveAllDirty}>
                                        <span>Save All Users</span>
                                    </StyledButton>
                                    :
                                    ""
                            }
                            {
                                isClient ?
                                    <StyledButton backgroundColor="#6A1E00" style={{ fontSize: "14px", marginRight: "10px" }} onClick={this.selectQuoteCategory} >
                                        Quote Selection
                                    </StyledButton>
                                    :
                                    ""
                            }
                            <SendSMSInviteButton progress={smsInviteInProgress} backgroundColor="#002D6A" style={{ fontSize: "14px", marginRight: "10px", fontWeight: "500", border: "1px solid #002D6A", boxShadow: "2px 2px #ededed", cursor: "pointer" }} onClick={() => this.sendSmsInvite()}>
                                Send SMS App Invite
                            </SendSMSInviteButton>
                            <SendEmailInviteButton progress={emailInviteInProgress} backgroundColor="#006CFF" style={{ fontSize: "14px", fontWeight: "500", border: "1px solid #006CFF", boxShadow: "2px 2px #ededed", cursor: "pointer" }} onClick={() => this.sendEmailInvite()}>
                                Send Email App Invite
                            </SendEmailInviteButton>
                        </FlexContainer>
                    </FlexContainer>
                    <HeaderContainer>
                        <Table>
                        <thead>
                                <Tr>
                                    <Th width={"8%"} style={{ userSelect: "none" }}>
                                        <label>
                                            <Checkbox checked={checkAllUsers} onChange={this.handlecheckAllUsers}/>
                                        </label>
                                    </Th>
                                    <Th onClick={() => this.sortColumns(SortableColumns.FIRST_NAME)} width={!isClient ? "8%" : "12%"}>
                                        <ColumnHeader
                                            heading="First Name"
                                            sortDirection={this.state.sortableColumns[SortableColumns.FIRST_NAME]}
                                        />
                                    </Th>
                                    <Th onClick={() => this.sortColumns(SortableColumns.EMAIL)} width={!isClient ? "17%" : "20%"}>
                                        <ColumnHeader
                                            heading="Email Address"
                                            sortDirection={this.state.sortableColumns[SortableColumns.EMAIL]}
                                        />
                                    </Th>
                                    <Th onClick={() => this.sortColumns(SortableColumns.MOBILE)} width={"10%"}>
                                        <ColumnHeader
                                            heading="Mobile Number"
                                            sortDirection={this.state.sortableColumns[SortableColumns.MOBILE]}
                                        />
                                    </Th>
                                    {!isClient ?
                                        <Th onClick={() => this.sortColumns(SortableColumns.DEPARTMENT)} width={"15%"}>
                                            <ColumnHeader
                                                heading="Department / Group"
                                                sortDirection={this.state.sortableColumns[SortableColumns.DEPARTMENT]}
                                            />
                                        </Th>
                                        :
                                        <Th onClick={() => this.sortColumns(SortableColumns.DEPARTMENT)} width={"15%"}>
                                            <ColumnHeader
                                                heading="Client Type"
                                                sortDirection={this.state.sortableColumns[SortableColumns.DEPARTMENT]}
                                            />
                                        </Th>
                                    }
                                    <Th onClick={() => this.sortColumns(SortableColumns.JOB_TITLE)} width={!isClient ? "10%" : "12%"}>
                                        <ColumnHeader
                                            heading={isClient ? "Subscription Status" : "Job Title" }
                                            sortDirection={this.state.sortableColumns[SortableColumns.JOB_TITLE]}
                                        />
                                    </Th>
                                    <Th onClick={() => this.sortColumns(SortableColumns.USER_CODE)} width={"10%"}>
                                        <ColumnHeader
                                            heading={"User Code" }
                                            sortDirection={this.state.sortableColumns[SortableColumns.USER_CODE]}
                                        />
                                    </Th>
                                    {!isClient && <Th onClick={() => this.sortColumns(SortableColumns.NOTICEBOARD_PERMISSION)} width={"12%"}>
                                        <ColumnHeader
                                            heading="Admin Perimission"
                                            sortDirection={this.state.sortableColumns[SortableColumns.NOTICEBOARD_PERMISSION]}
                                        />
                                    </Th>}
                                    <Th onClick={() => this.sortColumns(SortableColumns.STATUS)} width={!isClient ? "16%" : "20%"}>
                                        <ColumnHeader
                                            heading="Status"
                                            sortDirection={this.state.sortableColumns[SortableColumns.STATUS]}
                                        />
                                    </Th>
                                </Tr>
                            </thead>
                        </Table>
                    </HeaderContainer>
                    <ScrollContainer>
                        <Table>
                            <tbody>
                                <Tr>
                                    <Td colspan={isClient? 8 : 9} style={{borderBottom: "1px solid #2D3037"}}>
                                        <Text align="left" onClick={this.addNew} style={{cursor: "pointer"}}>
                                            <img src={AddIcon} style={{verticalAlign: "middle", marginRight: "10px"}}/>Add New User</Text>
                                    </Td>
                                </Tr>
                                { newLine &&
                                    <Tr>
                                        <Td width={"8%"}>
                                            <DeleteButton color="#2e3036" onClick={this.removeNew} style={{ cursor: "pointer" }}>
                                                <img width="20px" src={DeleteNewImg} alt="DeleteNew" />
                                            </DeleteButton>                                            
                                        </Td>
                                        <Td width={!isClient ? "8%" : "12%"}>
                                            <TextInput
                                                placeholder="First Name"
                                                value={this.state.newFirstName}
                                                onChange={(e) => this.setState({ newFirstName: e.target.value })}
                                                isHighlightred={errorList.length > 0 && errorList.includes(ErrorList.EMPTY_FIRST_NAME)}/>
                                        </Td>
                                        <Td width={!isClient ? "17%" : "20%"}>
                                            <TextInput
                                                placeholder={isClient ? "Email Address" : "Work Email Address"}
                                                value={this.state.newEmail}
                                                onChange={(e) => this.setState({ newEmail: e.target.value })}
                                                isHighlightred={errorList.length > 0 && errorList.includes(ErrorList.EMAIL_ERROR)}/>
                                        </Td>
                                        <Td width={"10%"}>
                                            <Tooltip
                                                index={"New"}
                                                text={`Please enter a valid phone number.${ this.getExample(this.state.newMobile) }`}
                                                active={ this.showInvalidPhoneNumberTooltip("New") }
                                                color="#FF4E4E"
                                            >
                                                <ValidatableTextInput
                                                    placeholder="Mobile Number"
                                                    invalid={!this.validatePhoneNumber(this.state.newMobile)}
                                                    value={(this.state.newMobile != null && this.asYouTypeAssist(this.state.newMobile)) || ""}
                                                    onChange={(e) => this.setState({ newMobile: e.target.value })}
                                                    onClick={(e) => this.setState({ newMobile: e.target.value })}
                                                    onBlur={(e) => this.setState({ newMobile: e.target.value })}
                                                    className={ `${"ValidationPhoneNumberNew"}` }
                                                />
                                            </Tooltip>
                                        </Td>
                                        {!isClient &&
                                            <Td width={"15%"}>
                                                <TextInput
                                                    placeholder="Department / Group"
                                                    value={this.state.newDepartment}
                                                    onChange={(e) => this.setState({ newDepartment: e.target.value })} />
                                            </Td>}
                                        { isClient && 
                                            <Td width={"15%"}>
                                                <TextInput
                                                    placeholder="Client Type"
                                                    value={this.state.newTitle}
                                                    onChange={(e) => this.setState({ newTitle: e.target.value })} />
                                            </Td>
                                        }
                                        {isClient ?
                                            <Td width={"12%"}>
                                                <Text color="black" size="14px">
                                                    <b>{remainDays}</b> Days Remaining
                                                </Text>
                                            </Td>
                                            :
                                            <Td width={"10%"}>
                                                <TextInput
                                                    placeholder="Job Title"
                                                    value={this.state.newTitle}
                                                    onChange={(e) => this.setState({ newTitle: e.target.value })} />
                                            </Td>
                                        }
                                        <Td width={"10%"}>&nbsp;</Td>
                                        {!isClient &&
                                            <Td width={"12%"}>
                                                <label>
                                                    <Checkbox readOnly checked={this.state.newPermission} onChange={() => this.setState({ newPermission: !this.state.newPermission })} />
                                                </label>
                                            </Td>
                                        }
                                        <Td borderRight="none" width={!isClient ? "16%" : "20%"}>
                                            <Button border="1px solid #2e3036" color="#d8d8d8" padding="5px 20px 5px"
                                                borderRadius="20px" backgroundColor="#2e3036" onClick={this.saveNew}>
                                                Add
                                            </Button>
                                        </Td>
                                    </Tr>
                                }

                                { employeeArray.map((employee, index) => {
                                    return (
                                        <Tr key={employee.uuid} 
                                            onClick={() => this.setState({editUser: employee.uuid})}
                                            style={{"border": (employee.uuid == this.state.editUser) ? "1px solid #2D3037": "none"}}>
                                            <Td width={"8%"}>
                                                <label>
                                                <Checkbox readOnly
                                                checked={removeSelectedUsers.some(remEmployee => remEmployee === employee.uuid)}
                                                value={employee.uuid}
                                                onChange={() => this.addRemoveUser(employee.uuid)} />
                                                </label>
                                            </Td>
                                            <Td width={!isClient ? "8%" : "12%"}>
                                                <ValidatableTextInput
                                                    value={employee["firstName"]}
                                                    onChange={(e) => this.updateEmployeeValue(index, "firstName", e.target.value)}
                                                    invalid={!employee["firstName"]}/>
                                            </Td>
                                            <Td width={!isClient ? "17%" : "20%"}>
                                                <ValidatableTextInput
                                                    value={employee["email"]}
                                                    invalid={(!this.validateEmail(employee["email"]))}
                                                    onChange={(e) => this.updateEmployeeValue(index, "email", e.target.value)}
                                                />
                                            </Td>
                                            <Td width={"10%"}>
                                                <Tooltip
                                                    index={ !newLine ? index : 1 }
                                                    text={`Please enter a valid phone number.${ this.getExample(employee["phoneNumber"]) }`}
                                                    active={ this.showInvalidPhoneNumberTooltip(index) }
                                                    color="#FF4E4E"
                                                >
                                                    {employee["isDirty"] !== DirtyState.UNKNOWN ?
                                                        <ValidatableTextInput
                                                            value={(employee["phoneNumber"] != null && this.asYouTypeAssist(employee["phoneNumber"])) || ""}
                                                            invalid={!this.validatePhoneNumber(employee["phoneNumber"])}
                                                            onBlur={(e) => this.updateEmployeeValue(index, "phoneNumber", e.target.value) }
                                                            onClick={(e) => this.updateEmployeeValue(index, "phoneNumber", e.target.value) }
                                                            onChange={(e) => this.updateEmployeeValue(index, "phoneNumber", e.target.value) }
                                                            className={ `${"ValidationPhoneNumber" + index}` }
                                                        />
                                                        :
                                                        <ValidatableTextInTable invalid={!this.validatePhoneNumber(employee["phoneNumber"])}>
                                                            { formatPhoneNumberIntl(employee["phoneNumber"]) }
                                                        </ValidatableTextInTable>
                                                    }
                                                </Tooltip>
                                            </Td>
                                            { !isClient &&
                                                <Td width={"15%"}>
                                                    <TextInput value={employee["department"]}
                                                        onChange={(e) => this.updateEmployeeValue(index, "department", e.target.value)} />
                                                </Td>
                                            }
                                            { isClient && 
                                                <Td width={"15%"}>
                                                    <TextInput value={employee["employmentPosition"]}
                                                        onChange={(e) => this.updateEmployeeValue(index, "employmentPosition", e.target.value)} />
                                                </Td>
                                            }
                                            { isClient ?
                                                <Td width={"12%"}>
                                                    <TextInTable>
                                                        <b>{remainDays}</b> Days Remaining
                                                    </TextInTable>
                                                </Td>
                                                :
                                                <Td width={"10%"}>
                                                    <TextInput value={employee["employmentPosition"]}
                                                        onChange={(e) => this.updateEmployeeValue(index, "employmentPosition", e.target.value)} />
                                                </Td>
                                            }
                                            <Td width={"10%"}>
                                                <StyledButton onClick={() => this.showAccessTokenModal(employee["email"], employee["uuid"])}
                                                backgroundColor="#000000" color="#FFFFFF" 
                                                style={{ position: "relative", cursor: "pointer" }}>
                                                    {employee["accessToken"]}
                                                </StyledButton>                                            
                                            </Td>                                    
                                            { !isClient &&
                                                <Td width={"12%"}>
                                                    <Checkbox
                                                        color="#85b336"
                                                        checked={employee["noticeboardPermission"]}
                                                        size="2"
                                                        onClickCallback={() => this.updateEmployeeValue(index, "noticeboardPermission", !employee["noticeboardPermission"])}
                                                    />
                                                </Td>
                                            }
                                            <Td borderRight="none" width={!isClient ? "16%" : "20%"}>
                                                { employee["isDirty"] === DirtyState.TRUE ?
                                                    this.doesEmployeeByIndexHaveError(index) ?
                                                        <Button border="1px solid #61230b" color="white" padding="3px 14px 3px"
                                                            borderRadius="20px" backgroundColor="#61230b" size="14px">
                                                            Error(s)
                                                        </Button>
                                                        :
                                                        <Button border="1px solid #2e3036" color="#d8d8d8" padding="3px 14px 3px"
                                                            borderRadius="20px" backgroundColor="#2e3036" size="14px"
                                                            onClick={() => employee["uuid"] == null ? this.postEmployee(index) : this.saveEmployee(index, employee["uuid"])}>
                                                            Save
                                                        </Button>
                                                    : employee["isDirty"] === DirtyState.UNKNOWN ?
                                                        <Button border="1px solid #2e3036" color="black" padding="3px 14px 3px"
                                                            borderRadius="20px" backgroundColor="transparent" size="14px">
                                                            Saving
                                                        </Button>
                                                        :
                                                        <TextInTable
                                                            color={
                                                                (employee["status"] && "#77B500") ||
                                                                (employee["hasReceivedInvitation"] && "#2c6cf6") ||
                                                                (this.doesEmployeeByIndexHaveError(index) && "#FF4E4E") || "black"
                                                            }
                                                        >
                                                            {
                                                                employee["status"] ?
                                                                    <div style={{ fontWeight: "500" }}>Active</div>
                                                                    :
                                                                    employee["hasReceivedInvitation"] ?
                                                                        <div style={{ fontWeight: "500" }}>Invite sent, app not installed</div>
                                                                        :
                                                                        this.doesEmployeeByIndexHaveError(index) ?
                                                                            <div style={{ fontWeight: "500" }}>Invalid Input</div>
                                                                            :
                                                                            <div style={{ fontWeight: "500" }}>Invite Not Sent</div>
                                                            }
                                                        </TextInTable>
                                                }
                                            </Td>
                                        </Tr>
                                    );})}
                                { emptyList }
                            </tbody>
                        </Table>
                    </ScrollContainer>
                </FlexContainer>
                {showCSVModal && <>
                    <Backdrop />
                    <ModalContainer>
                        <CSVModal closeCallback={() => this.setState({showCSVModal: false})} uploadCallback={this.upload} isClient={isClient} />
                    </ModalContainer>
                </>}
                {showAccessTokenModal && <>
                    <Backdrop />
                    <ModalContainer>
                        <AccessTokenModal closeCallback={() => this.setState({showAccessTokenModal: false})} 
                            user={this.state.userNewToken}
                            generate={this.generateNewAccessToken} />
                    </ModalContainer>
                </>}
            </>
        );
    }
}

class InviteConfirmation extends Component {
    render() {
        const { updateInvite, isClient, inviteType } = this.props;
        return (
            <FlexContainer justifyContent="center" alignItems="center" height="70vh" width="50rem" style={{ margin: "auto" }}>
                <img src={Correct} alt="success" height="95px" />
                <Text color="#23262D" size="60px" margin="40px 0 0">
                    { isClient ? "Client" : "Employee" } {inviteType.charAt(0).toUpperCase() + inviteType.slice(1)} App Invite
                </Text>
                <Text color="#23262D" size="60px" margin="0 0 30px">Sent Successfully</Text>
                <Text color="black" size="14px" width="650px" lineHeight="25px" margin="0">
                    An app invitation has been send to your { isClient ? "client/s" : "employee/s" } via {inviteType}. This invitation will include a unique
                    code that will grant them access into your customised business Me app.
                </Text>
                <StyledButton style={{ width: "100px", height: "40px", marginTop: "65px" }}
                    onClick={updateInvite}>
                    Done
                </StyledButton>
            </FlexContainer>
        );
    }
}

export default withRouter(EmployeeList);