import React from "react";
import ReactDOM from "react-dom";
import App from "./scenes/App";
import { initConsent } from "cookieconsent/CookieConsent";
import "cookieconsent/src/styles/base.css";
import "stylesheets/application.css";
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://98e2779a151d4bcb994055e81f334c6d@sentry.io/1336441"
});

initConsent();

ReactDOM.render(
    <App/>,
    document.getElementById("root")
);