require("cookieconsent");

const consentPopup = new window.cookieconsent.Popup();

const gtagScriptUrl = "https://www.googletagmanager.com/gtag/js?id=G-3JNF7LT0XJ";

const gtagInitScript = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-3JNF7LT0XJ');
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NJWXF2M');
`;

const facebookInitScript = `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1286005364902132');
fbq('track', 'PageView');
`;

function getDomain() {
    return "business.dailyfixme.com";
}

function trackingAlreadyAdded() {
    const script = document.getElementById("PIXEL_SCRIPT");
    return script !== null;
}

function addServices() {
    const prod = window.location.hostname === getDomain();
    if (prod && !trackingAlreadyAdded()) {
        // Create DOM elements
        const googleScriptSourced = document.createElement("script");
        const googleScriptLocal = document.createElement("script");
        googleScriptSourced.id = "G_SCRIPT_1";
        googleScriptLocal.id = "G_SCRIPT_2";

        const facebookScript = document.createElement("script");
        const facebookNoScript = document.createElement("noscript");
        facebookScript.id = "PIXEL_SCRIPT";
        facebookNoScript.id = "PIXEL_NOSCRIPT";

        // Configure those elements as per Google/Facebook guides
        googleScriptSourced.async = true;
        googleScriptSourced.src = gtagScriptUrl;

        googleScriptLocal.appendChild(document.createTextNode(gtagInitScript));

        facebookScript.appendChild(document.createTextNode(facebookInitScript));

        const img = document.createElement("img");
        img.height = 1;
        img.width = 1;
        img.src = "https://www.facebook.com/tr?id=1286005364902132&ev=PageView&noscript=1";
        facebookNoScript.appendChild(img);

        document.head.append(googleScriptSourced, googleScriptLocal, facebookScript, facebookNoScript);
    }
}

function removeServices() {
    if (trackingAlreadyAdded()) {
        const script1 = document.getElementById("G_SCRIPT_1");
        const script2 = document.getElementById("G_SCRIPT_2");
        const script3 = document.getElementById("PIXEL_SCRIPT");
        const noscript = document.getElementById("PIXEL_NOSCRIPT");
        if (script1) {
            script1.remove();
        }
        if (script2) {
            script2.remove();
        }
        if (script3) {
            script3.remove();
        }
        if (noscript) {
            noscript.remove();
        }
    }
}

/**
 * Hides the entire component (the popup dialog and the default 'Cookie Policy' button)
 */
function removePopup() {
    const dialogContainer = document.getElementById("consent-dialog-container");
    dialogContainer.style.display = "none";
}

/**
 * Make the component visible again, show the dialog, and revoke any previous response
 */
export function reshowDialog() {
    consentPopup.clearStatus();
    consentPopup.open();
    const dialogContainer = document.getElementById("consent-dialog-container");
    dialogContainer.style.display = "flex";
}

/**
 * Configure the behaviour of the consent dialog.
 * See JavaScript API at https://www.osano.com/cookieconsent/documentation/javascript-api/
 */
export function initConsent() {
    let options = {
        palette: {
            popup: {
                background: "#64386b",
                text: "#ffcdfd"
            },
            button: {
                background: "#f8a8ff",
                text: "#3f0045"
            }
        },
        container: document.getElementById("consent-dialog-container"),
        position: "bottom-right",
        type: "opt-in",
        content: {
            message: "We use cookies to measure the effectiveness of our advertising.",
            deny: "Decline",
            allow: "Allow cookies",
            href: "https://s3-ap-southeast-2.amazonaws.com/dailyfixme.content/Privacy+Policy.pdf"
        },
        cookie: {
            domain: getDomain()
        },
        revokable: true,
        location: false,
        showLink: true,
        onStatusChange: function(status, chosenBefore) {
            // Hide dialog when action taken, and enable/disable tracking as requested
            removePopup();
            if (status === "allow") {
                addServices();
            } else {
                removeServices();
            }
        },
        onInitialise: function(status) {
            // Hide the UI if previously responded, a custom link will instead be available to revoke previous response
            if (status === "allow") {
                addServices();
                removePopup();
            } else if (status === "deny") {
                removePopup();
            }
        }
    };
    consentPopup.initialise(options);
}
