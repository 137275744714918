import React, { Component } from "react";
import axios from "axios";
import styled from "styled-components";
import { API_URL } from "App";
import FlexContainer from "components/FlexContainer";
import Text from "components/Text";
import LeftText from "../components/LeftText";
import Price from "../components/Price";
import { RadioButton, RadioGroup } from "@trendmicro/react-radio";
import CancelButton from "../components/CancelButton";
import ProceedButton from "../components/ProceedButton";
import "@trendmicro/react-radio/dist/react-radio.css";
import { SubscriptionContext } from "../SubscriptionContext";
import { SUBSCRIPTION_COST, PRICE_PER_USER, Forms } from "../Subscription";
import PaymentForm from "../components/PaymentForm";
import LoadingIndicator from "LoadingIndicator";
import SquareLogo from "img/Square.png";
import Toast from "Toast";

export const HorizonContainer = styled.div`
    display: flex;
    flex-direction: ${ p => p.direction || "column" };
    margin: 0;
    width: ${p => p.width};
    align-items: ${ p => p.align || "center" };
`;

const Hr = styled.hr`
    margin: 0 64px;
`;

export const HorizonLine = styled.hr`
    margin: 40px 0 20px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    margin: ${p => p.margin || "64px 0"};
    justify-content: ${p => p.justifyContent || "center"};
    width: ${p => p.width};
`;

export const NewRadioButton = styled(RadioButton)`
    .radio---control-radio---2rwkY:not(.radio---disabled---eCY9b):hover {
        border: 1px solid #2D3037;
    }
    .radio---control-radio---2rwkY:not(.radio---disabled---eCY9b):hover >
    .radio---input-radio---2yM_b:checked {
        border: 1px solid #2D3037;
        background-color: #2D3037;
    }
    .radio---input-radio---2yM_b:checked + .radio---control-indicator---dcwXW::before {
        border: 1px solid #2D3037;
        background-color: #2D3037;
    }
 `;

export const Methods = {
    "EXISTING": "Existing Card",
    "NEW": "New Card"
};

class Review extends Component {
    state = {
        isProcessClickable: true,
        loading: false,
        loaded: false,
        method: Methods.EXISTING,
    };

    componentDidMount() {
        const { cards, currentForm } = this.context;
        this.loadingSquareJS();

        if (cards.length === 0 && currentForm === Forms.CHECKOUT) {
            return Toast.info("Card information doesn't exist");
        }
    }

    loadingSquareJS = () => {
        // Add Square to our project
        // Previously referenced https://medium.com/square-corner-blog/online-payments-form-react-9ecf164880bf
        // Now using Web Payments SDK: https://developer.squareup.com/docs/web-payments/overview
        let that = this;
        let sqPaymentScript = document.createElement("script");
        sqPaymentScript.src = (window.location.hostname === "business.dailyfixme.com") ?
            "https://web.squarecdn.com/v1/square.js" :
            "https://sandbox.web.squarecdn.com/v1/square.js";
        sqPaymentScript.type = "text/javascript";
        sqPaymentScript.async = false;
        sqPaymentScript.onload = () => {
            that.setState({
                loaded: true,
                isProcessClickable: true
            });
        };
        document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    };

    static contextType = SubscriptionContext;

    purchaseMore = () => {
        const { token, moreUsers, nextClick, selectedCard, getAllCards, isAustralian,
            remainDays, endDayMomentType, startDayMomentType } = this.context;
        this.setState({
            loading: true
        });

        axios.post(`${API_URL}/company/square/more`, {
            numOfUsers: moreUsers,
            cardId: selectedCard
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            // Facebook Pixel Tracking
            window.fbq && window.fbq("track", "Purchase", {
                value: this.getTotalCost(moreUsers, isAustralian, remainDays, endDayMomentType, startDayMomentType),
                currency: "AUD"
            });

            getAllCards();
            nextClick();
        }).catch(error => {
            this.setState({
                loading: false
            });
            if (error.response) {
                if (error.response.data.error.messages) {
                    return Toast.error(error.response.data.error.messages.toString());
                }
                return Toast.error(error.response.data.error.toString());
            } else {
                return Toast.error(error.message);
            }
        });
    };

    updateLoading = () => {
        this.setState({
            loading: true
        });
    };

    updatMethod = (value) => {
        this.setState({
            method: value
        });
    };

    // Used for sending facebook pixel statistics.
    getTotalCost = (users, isAustralian, remainDays, endDayType, startDayType) => {
        let diffDays;
        if (endDayType) {
            diffDays = endDayType.diff(startDayType, "days");
        }
        const subTotalOfMore = parseFloat(
            PRICE_PER_USER * users * 12 / diffDays * parseInt(remainDays)
        ).toFixed(2);
        const gstOfMore = parseFloat(0.1 * subTotalOfMore).toFixed(2);

        if (isAustralian) {
            return parseFloat(parseFloat(subTotalOfMore) + parseFloat(gstOfMore)).toFixed(2);
        }
        return parseFloat(subTotalOfMore).toFixed(2);
    };

    render() {
        //add method and updateValue when using radio buttons back
        const { totalNumber, userCost, gstCost, totalCost, companyName, cards, selectedCard, isAustralian, startDayMomentType,
            moreUsers, moreCost, moreGst, moreTotal, backToPrevious, subscriptionEndDate, getAllCards, endDayMomentType,
            currentForm, remainDays, subscriptionPeriod, token, nextClick, updateSelectedCard } = this.context;

        const { method } = this.state;

        if (this.state.loading) {
            return <LoadingIndicator />;
        }

        const updatePaymentAndPurchase = () => {
            // Facebook Pixel Tracking
            window.fbq && window.fbq("track", "Purchase", {
                value: this.getTotalCost(moreUsers, isAustralian, remainDays, startDayMomentType, endDayMomentType),
                currency: "AUD"
            });
            window.fbq && window.fbq("track", "AddPaymentInfo");
            nextClick();
        };

        return (
            <FlexContainer flexGrow="1">
                <Text size="40px" color="#23262D" margin="0 0 100px 0">
                    Payment Confirmation
                </Text>
                <FlexContainer margin="auto" justifyContent="center" direction="row">
                    <FlexContainer alignItems="flex-end">
                        <FlexContainer>
                            <Price
                                Forms={Forms}
                                currentForm={currentForm}
                                companyName={companyName}
                                SUBSCRIPTION_COST={SUBSCRIPTION_COST}
                                PRICE_PER_USER={PRICE_PER_USER}
                                totalNumber={currentForm === Forms.REVIEW ? totalNumber : moreUsers}
                                userCost={currentForm === Forms.REVIEW ? userCost : moreCost}
                                gstCost={currentForm === Forms.REVIEW ? gstCost : moreGst}
                                totalCost={currentForm === Forms.REVIEW ? totalCost : moreTotal}
                                remainDays={remainDays}
                                isAustralian={isAustralian}
                                subscriptionPeriod={subscriptionPeriod}
                                subscriptionEndDate={subscriptionEndDate}
                                endDayMomentType={endDayMomentType}
                                startDayMomentType={startDayMomentType}
                            />
                        </FlexContainer>
                    </FlexContainer>
                    <Hr />
                    <FlexContainer justifyContent="center">
                        <LeftText color="black" size="30px" weight="500" margin="0">
                            { currentForm === Forms.REVIEW ? "Confirm Payment" : "Select Payment Method" }
                        </LeftText>
                        { currentForm === Forms.REVIEW ?
                            <HorizonContainer>
                                <FlexContainer direction="column">
                                    { this.state.loaded ?
                                        <PaymentForm paymentForm={window.SqPaymentForm} getAllCards={getAllCards}
                                            token={token} numOfUsers={totalNumber} companyName={companyName} currentForm={currentForm}
                                            callBack={ backToPrevious} nextClick={nextClick} updateLoading={this.updateLoading} />
                                        :
                                        <LoadingIndicator />
                                    }
                                </FlexContainer>
                            </HorizonContainer>
                            :
                            <HorizonContainer align="start">
                                <RadioGroup
                                    name="method"
                                    value={method}
                                >
                                    <HorizonContainer direction="row">
                                        <FlexContainer direction="column">
                                            <NewRadioButton
                                                value={Methods.EXISTING}
                                                label="Use an existing credit/debit card"
                                                checked={ method === Methods.EXISTING }
                                                onChange={() => this.updatMethod(Methods.EXISTING)} />
                                        </FlexContainer>
                                        <FlexContainer direction="column">
                                            <NewRadioButton
                                                style={{ marginLeft: "10px" }}
                                                value={Methods.NEW}
                                                label="Add a new Card"
                                                checked={ method === Methods.NEW}
                                                onChange={() => this.updatMethod(Methods.NEW)} />
                                        </FlexContainer>
                                    </HorizonContainer>
                                </RadioGroup>
                                { this.state.method === Methods.NEW ?
                                    <FlexContainer align="start" direction="column">
                                        <PaymentForm paymentForm={window.SqPaymentForm} getAllCards={getAllCards}
                                            token={token} numOfUsers={moreUsers} companyName={companyName} currentForm={currentForm}
                                            callBack={ backToPrevious} nextClick={updatePaymentAndPurchase} updateLoading={this.updateLoading} />
                                    </FlexContainer>
                                    :
                                    <FlexContainer>
                                        <FlexContainer border="1px solid #707070" padding="20px 40px 20px 20px" marginTop="20px" align="left" >
                                            <RadioGroup
                                                name="card"
                                                value={selectedCard}
                                            >
                                                <FlexContainer direction="column">
                                                    {cards.map((card) => {
                                                        return (
                                                            <NewRadioButton
                                                                key={card.cardId}
                                                                style={{ marginLeft: "10px" }}
                                                                value={card.cardId}
                                                                label={card.displayInfo}
                                                                checked={ selectedCard === card.cardId}
                                                                onChange={() => updateSelectedCard(card.cardId)} />);}
                                                    )}
                                                </FlexContainer>
                                            </RadioGroup>
                                        </FlexContainer>
                                        <FlexContainer marginTop="20px">
                                            <img src={SquareLogo} height="40px" width="140px" alt="square" />
                                        </FlexContainer>
                                        <ButtonContainer>
                                            <CancelButton onClick={() => backToPrevious()}>
                                                BACK
                                            </CancelButton>
                                            <ProceedButton color="#51315D" onClick={this.purchaseMore}>
                                                Confirm Payment
                                            </ProceedButton>
                                        </ButtonContainer>
                                    </FlexContainer>
                                }
                            </HorizonContainer>
                        }
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        );
    }
}

export default Review;