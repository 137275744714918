import React, { Component } from "react";
import styled, { css } from "styled-components";
import LeftText from "../components/LeftText";

export const LineContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 400px;
    height: ${p => p.height || "50px"};
    align-items: ${p => p.alignItems || "center"};
    margin: ${ p => p.margin };
    background-color: ${p => p.color || "transparent"};
    ${props => props.isTopBorder && css`
        border-top: 1px solid black;
    `};
    ${props => props.isBottomBorder && css`
        border-bottom: 1px solid black;
    `};
`;

class Price extends Component {
    render() {
        const { companyName, PRICE_PER_USER, totalNumber, currentForm, Forms, subscriptionPeriod, subscriptionEndDate, remainDays, isAustralian,
            startDayMomentType, endDayMomentType } = this.props;

        const subTotal = PRICE_PER_USER * totalNumber;
        const gst = 0.1 * subTotal;

        //used for purchase more users
        let diffDays;
        if (endDayMomentType) {
            diffDays = endDayMomentType.diff(startDayMomentType, "days");
        }
        const subTotalOfMore = parseFloat(PRICE_PER_USER * totalNumber * 12 / diffDays * parseInt(remainDays)).toFixed(2);
        const gstOfMore = parseFloat(0.1 * subTotalOfMore).toFixed(2);

        return (
            <div>

                { (currentForm === Forms.PLAN || currentForm === Forms.PURCHASE || currentForm === Forms.CHECKOUT) &&
                    <div>
                        <LeftText size="30px" color="#2D3037" margin="0" fontWeight="600" noWrap>
                            Business Subscription:
                        </LeftText>
                        <LeftText size="20px" margin="0 0 20px" color="#C70099" fontWeight="600">
                            Annual Plan, Prepaid
                        </LeftText>
                    </div>
                }
                { currentForm === Forms.REVIEW &&
                    <div>
                        <LeftText size="30px" margin="0 0 20px 0" fontWeight="600">
                            Subscription Overview
                        </LeftText>
                        <LineContainer height="auto" margin="0" alignItems="flex-start">
                            <LeftText size="20px" margin="0" fontWeight="600" noWrap>
                                Business Name:
                            </LeftText>
                            <LeftText size="20px" margin="0" color="#C70099">
                                &nbsp;
                                {
                                    companyName.length > 25 ?
                                        companyName.substring(0, 24) + "..."
                                        :
                                        companyName
                                }
                            </LeftText>
                        </LineContainer>
                        <LineContainer height="30px" margin="0 0 30px 0">
                            <LeftText size="20px" margin="0" fontWeight="600" noWrap>
                                Business Subscription:
                            </LeftText>
                            <LeftText size="20px" margin="0" color="#C70099">
                                &nbsp; Annual Plan, Prepaid
                            </LeftText>
                        </LineContainer>
                    </div>
                }
                {(currentForm === Forms.PLAN || currentForm === Forms.REVIEW || currentForm === Forms.PURCHASE || currentForm === Forms.CHECKOUT) &&
                    <div>
                        <LineContainer isTopBorder={true} color="#f0f0f0">
                            <LeftText size="14px" margin="0 0 0 20px" width="300px" fontWeight="600">
                                Price Per User
                            </LeftText>
                            <LeftText size="14px" margin="0 20px 0 0" align="right" fontWeight="600" width="200px">
                                ${PRICE_PER_USER}/mo
                            </LeftText>
                        </LineContainer>
                        <LineContainer>
                            <LeftText size="14px" margin="0 0 0 20px" width="300px" fontWeight="600">
                                Number of Users
                            </LeftText>
                            <LeftText size="14px" margin="0 20px 0 0" align="right"  fontWeight="600" width="200px">
                                {totalNumber}
                            </LeftText>
                        </LineContainer>
                        {(currentForm === Forms.PLAN || currentForm === Forms.REVIEW) &&
                            <LineContainer color="#f0f0f0" isBottomBorder={true}>
                                <LeftText size="14px" margin="0 0 0 20px" width="300px" fontWeight="600">
                                    Subscription Duration
                                </LeftText>
                                <LeftText size="14px" margin="0 20px 0 0" align="right" fontWeight="600" width="200px">
                                    {subscriptionPeriod} Months
                                </LeftText>
                            </LineContainer>
                        }
                    </div>
                }
                { currentForm === Forms.PURCHASE || (currentForm === Forms.CHECKOUT) ?
                    <div>
                        <LineContainer margin="0" color="#f0f0f0" isBottomBorder={true}>
                            <LeftText size="14px" margin="0 0 0 20px" width="300px" fontWeight="600">
                                Subscription End Date
                            </LeftText>
                            <LeftText size="14px" margin="0 20px 0 0" align="right" width="200px" fontWeight="600">
                                {subscriptionEndDate}
                            </LeftText>
                        </LineContainer>
                        <LineContainer margin="0" color="#f0f0f0">
                            <LeftText size="14px" margin="0 0 0 20px" width="300px" fontWeight="600">
                                Subtotal
                            </LeftText>
                            <LeftText size="14px" margin="0 20px 0 0" align="right" width="200px" fontWeight="600">
                                ${subTotalOfMore}
                            </LeftText>
                        </LineContainer>
                        { isAustralian &&
                            <LineContainer margin="0" isBottomBorder={true}>
                                <LeftText size="14px" margin="0 0 0 20px" width="300px" fontWeight="600">
                                    GST
                                </LeftText>
                                <LeftText size="14px" margin="0 20px 0 0" align="right" width="200px" fontWeight="600">
                                    ${gstOfMore}
                                </LeftText>
                            </LineContainer>
                        }
                        <LineContainer margin="0">
                            <LeftText size="20px" margin="0 0 0 20px" width="300px" fontWeight="600">
                                Total
                            </LeftText>
                            <LeftText size="20px" margin="0 20px 0 0" align="right" width="200px" fontWeight="600">
                                ${isAustralian ?
                                    (parseFloat(subTotalOfMore) + parseFloat(gstOfMore)).toFixed(2)
                                    : parseFloat(subTotalOfMore).toFixed(2)}
                            </LeftText>
                        </LineContainer>
                    </div>
                    :
                    <div>
                        { isAustralian &&
                            <div>
                                <LineContainer margin="0">
                                    <LeftText size="14px" margin="0 0 0 20px" width="300px" fontWeight="600">
                                        Subtotal
                                    </LeftText>
                                    <LeftText size="14px" margin="0 20px 0 0" align="right" fontWeight="600" width="200px">
                                        ${subTotal.toFixed(2)}/yr
                                    </LeftText>
                                </LineContainer>
                                <LineContainer color="#f0f0f0">
                                    <LeftText size="14px" margin="0 0 0 20px" width="300px" fontWeight="600">
                                        GST
                                    </LeftText>
                                    <LeftText size="14px" margin="0 20px 0 0" align="right" fontWeight="600" width="200px">
                                        ${gst.toFixed(2)}
                                    </LeftText>
                                </LineContainer>
                            </div>
                        }
                        <LineContainer margin="0" isTopBorder={true}>
                            <LeftText size="20px" margin="0 0 0 20px" width="300px" fontWeight="600">
                                Total
                            </LeftText>
                            <LeftText size="20px" margin="0 20px 0 0" align="right" fontWeight="600" width="200px">
                                {/* The following regex is used to add comma inside number. e.g. convert 2000 to 2,000 */}
                                ${isAustralian ?
                                    parseFloat((subTotal + gst) * subscriptionPeriod).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : parseFloat((subTotal) * subscriptionPeriod).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr
                            </LeftText>
                        </LineContainer>
                    </div>
                }
            </div>
        );
    }
}

export default Price;