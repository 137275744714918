import React from "react";
import styled from "styled-components";
import LeftArrowSmall from "img/LeftArrowSmall.png";

const StyledButton = styled.button`
    color: ${p => p.color || "transparent"};
    border: ${p => p.border || "none"};
    border-radius: ${p => p.borderRadius || "none"};
    outline: none;
    background-color: ${p => p.backgroundColor || "transparent"};
    background: url(${LeftArrowSmall}) no-repeat left transparent;
    background-position-x: 10%;
    background-position-y: center;
    padding: ${p => p.padding || "none"};
    font-size: ${p => p.size};
    margin-top: ${p => p.marginTop};
    margin-left: ${p => p.marginLeft};
    margin-right: ${p => p.marginRight};
    margin-bottom: ${p => p.marginBottom};
    white-space: nowrap;
    width: ${p => p.width};
    height: ${p => p.height};
    font-family: "Affogato";
    cursor: ${p => p.cursor || "pointer"};
`;

function BackButton(props) {
    const { children, ...styleProps } = props;
    return (
        <StyledButton {...styleProps} >
            {children}
        </StyledButton>
    );
}

export default BackButton;