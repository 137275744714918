import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "App";
import styled from "styled-components";
import Text from "components/Text";
import FormField, { ErrorText } from "components/FormField";
import NextButton from "components/NextButton";
import Form from "components/Form";
import FlexContainer from "components/FlexContainer";
import PhoneNumberInput from "components/PhoneNumberInput";
import { RadioButton, RadioGroup } from "@trendmicro/react-radio";
import LoadingIndicator from "LoadingIndicator";

const CountryRadioButton = styled(RadioButton)`
    color: white;
    .radio---control-radio---2rwkY:not(.radio---disabled---eCY9b):hover {
        border: 1px solid white;
    }
    .radio---control-radio---2rwkY:not(.radio---disabled---eCY9b):hover >
    .radio---input-radio---2yM_b:checked {
        border: 1px solid white;
        background-color: #51315D;
    }
    .radio---input-radio---2yM_b:checked + .radio---control-indicator---dcwXW::before {
        border: 1px solid white;
        background-color: white;
    }

    .radio---input-radio---2yM_b:checked + .radio---control-indicator---dcwXW::after {
        background-color: #51315D;
    }

    .radio---input-radio---2yM_b + .radio---control-indicator---dcwXW::before {
        background-color: white;
    }
 `;

const ErrorList = {
    DIFF_PASS: 1,
    EMAIL_FORMAT: 2,
    EMPTY_EMAIL: 3,
    EMPTY_FIRST_NAME: 4,
    EMPTY_PASS: 5,
    EMPTY_REPASS: 6,
    MIN_LENGTH: 7,
    EMPTY_COUNTRY: 8,
};

const ErrorStrings = {
    [ErrorList.DIFF_PASS]: "* Please make sure passwords are same",
    [ErrorList.EMAIL_FORMAT]: "* Please make sure Email format is correct",
    [ErrorList.EMPTY_EMAIL]: "* Please enter an email address",
    [ErrorList.EMPTY_FIRST_NAME]: "* Please enter a first name",
    [ErrorList.EMPTY_PASS]: "* Please enter a password",
    [ErrorList.EMPTY_REPASS]: "* Please enter a re-enter password",
    [ErrorList.MIN_LENGTH]: "* Please enter a password at least at least six characters length",
    [ErrorList.EMPTY_COUNTRY]: "* Please select whether you are an Australian company",
};

class SignUp extends Component {
    state = {
        firstName: "",
        mobile: "",
        email: "",
        password: "",
        rePassword: "",
        errorMessage: "",
        inputErrors: [],
        isAustralian: null,
        loading: false,
    };

    register = (e) => {
        const { firstName, mobile, email, password, rePassword, isAustralian } = this.state;

        e.preventDefault();
        let errorArray = [];

        if (firstName.trim() === "") {
            errorArray.push(ErrorList.EMPTY_FIRST_NAME);
        }

        if (email.trim() === "") {
            errorArray.push(ErrorList.EMPTY_EMAIL);
        } else if (! /^(.+)@(.+)\.(.+)$/.test(email)) {
            errorArray.push(ErrorList.EMAIL_FORMAT);
        }

        if (password.trim() === "") {
            errorArray.push(ErrorList.EMPTY_PASS);
        } else if (password.length < 6) {
            errorArray.push(ErrorList.MIN_LENGTH);
        } else if (password !== rePassword) {
            errorArray.push(ErrorList.DIFF_PASS);
        }

        if (rePassword.trim() === "") {
            errorArray.push(ErrorList.EMPTY_REPASS);
        }

        if (isAustralian === null) {
            errorArray.push(ErrorList.EMPTY_COUNTRY);
        }

        if (errorArray.length === 0) {
            this.setState({
                inputErrors: errorArray,
                errorMessage: "",
                loading: true,
            });

            axios.post(`${API_URL}/company/register`, {
                firstName: firstName,
                phoneNumber: mobile,
                email: email,
                password: password,
                isAustralian: isAustralian
            })
                .then(response => {
                    this.setState({
                        loading: false
                    });
                    // Facebook Pixel Tracking
                    window.fbq && window.fbq("track", "CompleteRegistration");

                    this.props.signUpComplete(response.data.account);
                })
                .catch(error => {
                    if (error.response) {
                        this.setState({
                            errorMessage: error.response.data.error,
                            loading: false
                        });
                    }
                });
        } else {
            this.setState({
                inputErrors: errorArray,
            });
        }
    };

    setAustralian = () => {
        this.setState({ isAustralian: true });
    };

    setNotAustralian = () => {
        this.setState({ isAustralian: false });
    };

    render() {
        const { errorMessage, inputErrors, isAustralian, loading } = this.state;
        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

        const firstNameErrors = getErrorStrings(ErrorList.EMPTY_FIRST_NAME);
        const emailErrors = getErrorStrings(ErrorList.EMPTY_EMAIL, ErrorList.EMAIL_FORMAT);
        const passwordErrors = getErrorStrings(ErrorList.EMPTY_PASS, ErrorList.MIN_LENGTH, ErrorList.DIFF_PASS);
        const rePasswordErrors = getErrorStrings(ErrorList.EMPTY_REPASS, ErrorList.DIFF_PASS);
        const emptyCountryErrors = getErrorStrings(ErrorList.EMPTY_COUNTRY);

        return (
            <FlexContainer flexGrow="1" justifyContent="center">
                <Form>
                    <Text color="red"><strong>{errorMessage}</strong></Text><br/>
                    <Text size="18px" weight="500" color="white" shadow="0px 0px 20px #FFFFFF">
                        <strong>Welcome</strong>
                    </Text>
                    <FormField
                        placeholder="First Name"
                        align="center"
                        onChange={(e) => this.setState({ firstName: e.target.value }) }
                        errors={firstNameErrors}
                    />
                    <PhoneNumberInput
                        country="AU"
                        defaultCountry="AU"
                        placeholder="Mobile Number"
                        value={this.state.mobile}
                        onChange={phone => this.setState({ mobile: phone }) }
                    />
                    <FormField
                        placeholder="Email Address"
                        align="center"
                        onChange={(e) => this.setState({ email: e.target.value }) }
                        errors={emailErrors}
                    />
                    <FormField
                        type="password"
                        placeholder="Create Password"
                        align="center"
                        onChange={(e) => this.setState({ password: e.target.value }) }
                        errors={passwordErrors}
                    />
                    <FormField
                        type="password"
                        placeholder="Re-Enter Password"
                        align="center"
                        onChange={(e) => this.setState({ rePassword: e.target.value }) }
                        errors={rePasswordErrors}
                    />
                    <RadioGroup name="method" value={isAustralian}>
                        <div>
                            <CountryRadioButton
                                style={{ marginLeft: "10px", marginTop: "1.2rem", fontSize: "10px", display: "flex" }}
                                value={true}
                                checked={ isAustralian? isAustralian : false }
                                onChange={this.setAustralian} >
                                <span style={{ fontWeight: "500", fontSize: "1rem" }}>We are an Australian company.</span>
                            </CountryRadioButton>
                            <CountryRadioButton
                                style={{ marginLeft: "10px", marginTop: "1.2rem", fontSize: "10px", display: "flex" }}
                                value={false}
                                checked={ isAustralian === false}
                                onChange={this.setNotAustralian} >
                                <p style={{ margin: "0", fontWeight: "500", fontSize: "1rem" }}>
                                    We are a company located outside of Australia.
                                </p>
                            </CountryRadioButton>
                        </div>
                    </RadioGroup>
                    <ErrorText margin="0 20px 20px">
                        {emptyCountryErrors}
                    </ErrorText>
                    { loading ?
                        <FlexContainer width="100%" height="30px" >
                            <LoadingIndicator height="7px" width="7px"/>
                        </FlexContainer>
                        :
                        <NextButton
                            alt="Register"
                            onClick={this.register}
                        />
                    }
                </Form>
            </FlexContainer>
        );
    }
}

export default SignUp;