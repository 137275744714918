import React from "react";
import FlexContainer from "FlexContainer";
import LogoWhiteGlow from "../img/Logo.png";

const Logo = () => (
    <FlexContainer direction="row" alignItems="center">
        <img src={LogoWhiteGlow} alt="Me logo" style={{ width: "126px", height: "60px", objectFit: "contain" }} />        
    </FlexContainer>
);

export default Logo;