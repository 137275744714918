import React, { Component, useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { API_URL } from "App";
import axios from "axios";
import { SettingContext } from "../SettingContext";
import LoadingIndicator from "LoadingIndicator";
import moment from "moment";
import jsPDF from 'jspdf';
import Button from "components/Button";
import Text from "components/Text";
import FlexContainer from "components/FlexContainer";
import InvoiceTemplate from "components/InvoiceTemplate";
import Toast from "Toast";
import "./payments.css";

import downloadImage from "img/download.png";
import CloseButton from "img/CloseButton.png";

const DisplayText = styled(Text)`
    text-align: ${p => p.align || "left"};
    color: #000000;
    font-size: ${p => p.size || "14px"};
    height: 2.4rem;
    line-height: 2.4rem;
`;

const DivContainer = styled.div`
    text-align: ${p => p.align || "left"};
`;

const DivChild = styled.div`
    float: left;
    width: ${p => p.width || "20%"};
    margin: 10px;
`;

const InvoiceGridContainer = styled.div`
    display: grid;
    grid-template-columns: 15% 55% 30%;
    grid-temnpate-rows: 100%;
    text-align: ${p => p.align || "left"};
`;

const InvoiceGridChild = styled.div`
    margin: 0 10px 0 10px;
`;

const PaymentContainer = styled.div`
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #000000;
    border-radius: 20px;
    opacity: 1;
`;

const SaveButton = styled(Button)`
    background: #51315D;
    color: #ffffff;
    border-radius: 20px;
    width: 90px;
    height: 2.4rem;
`;

const EditButton = styled(Button)`
    background: #000000;
    color: #ffffff;
    border-radius: 20px;
    width: 135px;
    height: 2.4rem;
`;

const CancelButton = styled(Button)`
    width: 2.4rem;
    height: 2.4rem;
    background-color: transparent;
    background: url(${CloseButton}) no-repeat right transparent;
`;

const DownloadButton = styled(Button)`
    background: url(${downloadImage}) transparent no-repeat;
    width: 16px;
    height: 20px;
    padding: 6px;
    margin: 0 10px;
`

const Hr = styled.hr`
    margin: 0;
`;

function Payments() {
    const { fullName, token } = useContext(SettingContext);
    const [loading, setLoading] = useState(true);
    const [cards, setCards] = useState([]);
    const [cardLoading, setCardLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [invoicesLoading, setInvoicesLoading] = useState(true);
    const [selectedCard, setSelectedCard] = useState([]);
    const [showEditCard, setShowEditCard] = useState(false);
    const [isProcessClickable, setIsProcessClickable] = useState(false);
    const [clickable, seClickable] = useState(false);
    const [card, setCard] = useState(null);
    
    useEffect(() => {
        fetchCards();
        fetchInvoices();
        if (!window.Square) {
            console.error("Square not loaded");
            return;
        } else {
            makeCardForm();
        }
        setLoading(false);
    }, []);

    //Fetch Cards
    const fetchCards = async () => {
        try {
            const cardResponse = axios.get(`${API_URL}/company/square/cards`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            });

            const res = await cardResponse;
            res.data.cards.forEach(card => {
                if (card.isDefault) {
                    setSelectedCard({
                        cardBrand: card.cardBrand,
                        cardLast4:  card.cardLast4,
                        expiration: card.expiration
                    });
                    setCardLoading(false);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
        
    // Fetch Invoices
    const fetchInvoices = async () => {
        try {
            let invoicesRes = await axios.get(`${API_URL}/company/getAllInvoices`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            });

            const res = await invoicesRes;
            setInvoices(res.data);
            setInvoicesLoading(false);
        } catch (error) {
            console.log(error)
        }
    }

    const getInvoice = async (uuid) => {
        setInvoicesLoading(true);
        try {
            let invoicesRes = await axios.get(`${API_URL}/company/getCustomerInvoice/${uuid}`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            });
            setInvoicesLoading(false);
            return InvoiceTemplate(new jsPDF(), invoicesRes.data);

        } catch (error) {
            setInvoicesLoading(false);
            console.log(error)
        }
    }

    const editCard = () => {
        setShowEditCard(true)
    }

    const handleCancel = () => {
        setShowEditCard(false);
    }

    const SaveCard = async () => {
        const tokenResult = await card.tokenize();
        if (tokenResult.status === "OK") {
            onCardNonceReceived(tokenResult.token);
        } else {
            if (window.location.hostname !== "business.dailyfixme.com") {
                console.error("Tokenization failed", tokenResult);
            }
            tokenResult.errors.map((error, index) => {
                return (
                    Toast.info(error.message.toString())
                );
            });
        }
    }

    const onCardNonceReceived = (cardToken) => {
        axios.post(`${API_URL}/company/square/card`, {
            nonce: cardToken
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(() => {
            fetchCards();
            setLoading(false);
            return Toast.info("Your payment method was successfully updated.");
        }).catch(error => {
            if (error.response) {
                if (error.response.data.error.messages) {
                    setLoading(false);
                    Toast.error(error.response.data.exception.toString());
                } else {
                    setLoading(false);
                    Toast.error(error.response.data.error.toString());
                }
            } else {
                setLoading(false);
                Toast.error(error.message.toString());
            }
        });
    }

    const makeCardForm = async () => {
        let locationId;
        let applicationId;
        if (window.location.hostname === "business.dailyfixme.com") {
            locationId = "N3ANA4TY5QBPK";
            applicationId = "sq0idp-M--KH8n0nrz6y84Dpa9qVg";
        } else {
            locationId = "L68F9PPET2VMT";
            applicationId = "sandbox-sq0idb-Wy_cpLu6QBUPCvqIfMy3jQ";
        }
        
        if (!window.Square) {
            throw new Error('Square.js failed to load properly');
        }
        const payments = window.Square.payments(applicationId, locationId);
        const card = await payments.card();
        await card.attach("#card");
        onPaymentFormLoaded(card);
    }

    const onPaymentFormLoaded= (card) => {
        // allow submit button be clickable after the square form can be loaded completelly
        seClickable(true);
        setCard(card);
    }

    //Loading Indicator
    if (loading) {
        return <LoadingIndicator />;
    }
    return(
    <FlexContainer flexGrow="1" width="900px" margin="0 auto">
        <DisplayText size="20px">Payment Method</DisplayText>
        <PaymentContainer style={{height: showEditCard ? "121px" : "90px"}}>
            <DivContainer>
                <DivChild width="10%">
                    <DisplayText>{fullName}</DisplayText>
                </DivChild>
                <DivChild width="65%">
                    {!showEditCard && <>
                        {cardLoading ? 
                            <LoadingIndicator /> :
                            <DisplayText>{selectedCard.cardLast4}</DisplayText>
                        }
                    </>}                    
                    <div style={{visibility: showEditCard ? 'visible' : 'hidden', marginTop: "10px"}}>
                        <form id="payment-form" style={{display: showEditCard ? 'initial' : 'none'}}>
                            <div id="card" style={{height: "55px"}}></div>
                            <input type="hidden" id="card-nonce" name="nonce" />
                        </form>
                    </div>
                </DivChild>
                <DivChild width="15%">
                    {showEditCard == false ?
                    <DisplayText align="right">
                        <EditButton disabled={cardLoading? "disabled" : ""} onClick={(e) => {editCard()}}>Change</EditButton>
                    </DisplayText>
                    :
                    <DisplayText align="right">
                        <SaveButton disabled={!clickable? "disabled" : ""} onClick={(e) => {SaveCard()}}>Save</SaveButton>
                        <CancelButton onClick={(e) => {handleCancel()}}>&nbsp;</CancelButton>
                    </DisplayText>
                    }
                </DivChild>
            </DivContainer>
        </PaymentContainer>
        <DisplayText size="20px">Billing History</DisplayText>
        <Hr/>
        {invoicesLoading ? <LoadingIndicator /> :
        <>
            {invoices.map((invoice, key) => {
                return (
                <InvoiceGridContainer key={key} style={{background: key % 2 == 0? "#EFEFEF": "#FFFFFF"}}>
                    <InvoiceGridChild>
                        <DisplayText>{moment(invoice.date).format('MMMM YYYY')}</DisplayText>
                    </InvoiceGridChild>
                    <InvoiceGridChild>
                        <DisplayText>{invoice.description}</DisplayText>
                    </InvoiceGridChild>
                    <InvoiceGridChild>
                        <DisplayText>Invoice No {invoice.invoiceNo} <DownloadButton onClick={(e) => {getInvoice(invoice.uuid)}}/></DisplayText>
                    </InvoiceGridChild>
                </InvoiceGridContainer>)
            })}
        </>
        }
    </FlexContainer>
    )
}

export default Payments;