import React from "react";
import styled from "styled-components";
import Text from "components/Text";

const HorizonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-self: ${p => p.self || "center"};
    margin-top: ${p => p.top};
    margin-bottom: ${p => p.bottom};
    width: ${p => p.maxWidth}
`;

function DifferentFonts(props) {
    const { mediumText, regularText, marginTop, size, color, 
        self, bottom, reverse, middleText } = props;
    return (
        <HorizonContainer top={marginTop} self={ self ? self : "start"} bottom={bottom}>
            <Text color="#23262D" size={size} margin="0" align="left" display="inline"
                weight={ reverse === true ? "400" : "500"} noWrap>
                {mediumText}
            </Text>
            <Text size={size} color={color} margin="0" align="left" display="inline"
                weight={ reverse === true ? "500" : "400"} >
                 &nbsp;{ regularText }
            </Text>
            { middleText && 
                <Text size={size} color={color} margin="0" align="left" display="inline"
                    family="Affogato">
                    &nbsp;{ middleText }
                </Text>}
        </HorizonContainer>
    );
} 

export default DifferentFonts;