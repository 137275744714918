import React, { useState, useRef } from "react";
import Button from "components/Button";
import Text from "components/Text";
import styled, { css } from "styled-components";
import { CSVReader } from "react-papaparse";

import EmployeeTemplate from "template/EmployeeTemplate.csv";
import ClientTemplate from "template/ClientTemplate.csv";

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 15% 70% 25%;

    height: 75%;
    width: 60%;

    margin: 20%;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #00000029;
    border-radius: 48px;

    :nth-child(0) {
        grid-column: 1;
        grid-row: 1;
    }

    :nth-child(1) {
        grid-column: 1;
        grid-row: 1;
    }

    :nth-child(2) {
        grid-column: 1;
        grid-row: 1;
    }
`;

const GridBody = styled.div`
    transform: translateX(8.235%);
    width: 87%;
    ${p => p.borderedTop && css`
        border-top: 1px solid black;
    `}
    ${p => p.borderedBottom && css`
        border-bottom: 1px solid black;
    `}
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;

    transform: translateY(35%);
    height: 100%;
    user-select: none;
    pointer-events: none;

    font-size: 1.8rem;
    font-weight: 500;
    font-family: Affogato;
    letter-spacing: 0.75px;
    color: #23262D;

    :nth-child(0) {
        grid-column: 1;
        grid-row: 1;
    }

    :nth-child(1) {
        grid-column: 2;
        grid-row: 1;
    }
`;

const GenericButtonStyle = css`
    height: 2.5rem;
    width: 9.375rem;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;

    font-family: Affogato;
    letter-spacing: 0.35px;
    line-height: 2.5rem;
    color: white;
    text-align: center;

    user-select: none;
    cursor: pointer;
`;

const StyledButton = styled(Button)`
    border: 1px solid #000000;
    color: ${p => p.color || "white"};
    padding: 10px 30px 10px;
    border-radius: 20px;
    background-color: ${ p => p.backgroundColor || "#2e3036"};
    width: 227px;
`;

const FinishButton = styled.div`
    display: inline-block;
    ${GenericButtonStyle}
    background: #006CFF 0% 0% no-repeat padding-box;
    font-weight: 400;
    color: #FFFFFF;
`;

const UploadButton = {
    opacity: 0,
    position: "relative",
    top: "-35px",    
    width: "222px",
    height: "33px"
};

const CloseButton = styled.div`
    display: inline-block;
    ${GenericButtonStyle}
    background: #2D3037 0% 0% no-repeat padding-box;
`;

const UploadContentBox = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;

    font-family: Affogato, Medium;
    letter-spacing: 0.35px;
    color: black;
    text-align: center;
    vertical-align: center;
    horizontal-align: center;


    user-select: none;
    cursor: pointer;
    border: 1px black solid;

    border-radius: 20px;

    width: 100%;
    height: 100%;
`

const UploadContentButtonStyle = css`
    height: 2.5rem;
    border-radius: 20px;

    font-family: Affogato;
    letter-spacing: 0.35px;
    line-height: 2.5rem;
    color: black;
    text-align: center;

    user-select: none;
    cursor: pointer;
    border: 1px black solid;
`;

const UploadContentButton = styled.div`
    ${UploadContentButtonStyle}
    font-family: Affogato, Medium;
    background: #000000;
    border-radius: 20px;
    color: #FFFFFF;
`;

const OuterFlexBox = styled.div`
    display: flex;
    flex-direction: column;

    min-width: 100%;
    min-height: 100%;
`;

const InnerFlexBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: ${p => p.alignItems || 'start'};
    margin-top: 10px;
`;

const DirtyState = {
    FALSE: 0,
    TRUE: 1,
    UNKNOWN: 2,
};

function CSVModal(props) {
    const { closeCallback, isClient, uploadCallback } = props;    
    const [items, setItems] = useState(null);
    const [fileName, setFileName] = useState(null);
    const fileInput = useRef(null);

    const upload = (items) => {
        let file = fileInput.current.value
        setFileName(file.split("\\").pop());
        setItems(items);
    }
    const finish = () => {        
        uploadCallback(items);
        closeCallback();
    }
    
    return(
        <Container>
            <GridBody style={{ paddingBottom: "10%" }}>
                <Header>
                    <Text align="left" color="#000000" size="30px">Upload a .csv File</Text>
                </Header>
            </GridBody>
            <GridBody bordered style={{ minHeight: "100%", height: "100%", marginBottom: "20px" }}>
                <OuterFlexBox style={{ minHeight: "100%", height: "100%", borderBottom: "1px solid #000" }}>
                    <Text align="left" color="#000000">
                        You can upload your user database in bulk using our preset template available for download below. 
                        Once you upload your .csv file your employee or client list will be generated with all the user information.
                    </Text>
                    <Text align="left" color="#51315D">
                        *Email addresses must be unique. If you already have users with any of the email addresses from the CSV file, 
                        these users will be overwritten.
                    </Text>
                    <Text align="left" color="#51315D">*Required fields are: First name and email address.</Text>
                    <Text align="left" color="#000000">Download our .csv template or enter employee/client details manually in the table.</Text>                    
                    <a href={isClient ? ClientTemplate : EmployeeTemplate}
                        download={isClient ? "client_template.csv" : "employee_template.csv"}
                        style={{ background: "#000000", color: "#FFFFFF", width: "12rem", padding: "10px 5px 10px 25px", marginBottom: "10px",
                        position: "relative", left: "-5px", lineHeight: "1.5rem",  borderRadius: "20px", fontWeight: "500", textDecoration:"none" }}>
                        Download .csv Template
                    </a>                    
                    <div style={{ display: "flex", flexDirection: "row", alignItems:"start"}}>
                    <StyledButton backgroundColor="#FFFFFF" color="#000000" style={{ position: "relative", left: "-5px", cursor: "pointer" }}>
                        Browse .csv File
                    </StyledButton>                 
                    <span style={{ position: "relative", color:"#000000", width: "400px", lineHeight: "2.5rem"}}>{fileName}</span>
                    </div>
                    <CSVReader
                        onFileLoaded={upload}
                        inputRef={fileInput}
                        style={UploadButton}                        
                        configOptions={{ header: true }}
                    />                    
                </OuterFlexBox>
            </GridBody>
            <GridBody>
                <OuterFlexBox>
                    <InnerFlexBox alignItems="end">
                        <div style={{ display: "flex", flexDirection: "row", alignItems:"start"}}>
                        <CloseButton onClick={() => closeCallback()}>Close</CloseButton>
                        <FinishButton onClick={() => finish()} style={{marginLeft: "20px"}}>Finish</FinishButton>
                        </div>
                    </InnerFlexBox>
                </OuterFlexBox>
            </GridBody>
        </Container>
    );
}

export default CSVModal;