import React, { Component } from "react";
import styled from "styled-components";
import Text from "components/Text";
import { TextInput } from "components/FormField";
import { SubscriptionContext } from "../SubscriptionContext";

export const StyledButton = styled(Text)`
    color: white;
    background-color: #51315D;
    border-radius: 20px;
    font-weight: 500;
    border: 1px solid #292C33;
    padding: 10px 10px;
    margin: 100px auto 0 auto;
    width: 150px;
    cursor: pointer;
`;

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const NewTextInput = styled(TextInput)`
    background-color: transparent;
    width: 669px;
    border-radius: 0;
    border-bottom: 1px solid ${props=> props.invalidColor || "#2D3037" };
    text-align: center;
    font-size: 30px;
    padding: 15px;
    &:focus {
        outline: none;
    }
`;

class Company extends Component {
    static contextType = SubscriptionContext;
    state = {
        companyName: "",
        errorMessage: "",
        invalidColor: ""
    };

    componentDidMount() {
        const { companyName, updateValue } = this.context;
        if (companyName === "") {
            updateValue("isNextClickable", false);
        }
    }

    /**
     * @param {string} name
     */
    setCompanyName(name) {
        this.setState({
            companyName: name
        });
    }

    validateCompanyName() {
        let { companyName } = this.state;
        const { updateCompanyValue } = this.context;

        companyName = companyName.trim();
        console.log("Company name: " + companyName);

        if (companyName.length === 0) {
            this.setState({
                errorMessage: "You must enter your business name to proceed.",
                invalidColor: "#FF4B4B"
            });
            return;
        } else {
            updateCompanyValue("companyName", companyName);
        }
    }

    render() {
        const { currentForm } = this.context;
        console.log("Current form: " + currentForm);

        return (
            <Container>
                <Text size="20px" color="#23262D" margin="0 0 60px 0">
                    Enter Your <b>Business Name</b>
                </Text>
                <form style={{ textAlign: "center"}} onSubmit={e => { e.preventDefault(); }}>
                    <NewTextInput
                        placeholder={this.state.errorMessage} 
                        invalidColor={this.state.invalidColor}
                        onChange={(e) => this.setCompanyName(e.target.value)}
                        onClick={() => this.setState({errorMessage: "", invalidColor: ""})}/>
                    <StyledButton marginTop="30px" onClick={() => this.validateCompanyName()}>
                        Next
                    </StyledButton>
                </form>
            </Container>
        );
    }
}

export default Company;