import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "App";
import styled from "styled-components";
import DropZone from "react-dropzone";
import Text from "components/Text";
import Button from "components/Button";
import FlexContainer from "components/FlexContainer";
import UploadImg from "img/Upload.png";
import ImageSlider from "../components/ImageSlider";
import { CustomiseContext } from "../CustomiseContext";
import DifferentFonts from "components/DifferentFonts";
import BackgroundContainer from "components/BackgroundContainer";
import GridBackground from "img/GridBackground.png";
import Toast from "Toast";
import BrowseFileButton from "BrowseFileButton";

const Container = styled.div`
    display: flex;
    margin: auto;
`;

const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const UploadSize = {
    width: 391,
    height: 102,
};

const StyledButton = styled(Button)`
    width: ${p => p.width || "130px"};
    height: 40px;
    color: ${p => p.color || "#23262D"};
    background-color: ${p => p.backgroundColor || "transparent"};
    border-radius: 20px;
    border: 1px solid #23262D;
    padding: ${p => p.padding || "5px"};
    box-shadow: 2px 2px #e3e3e3;
    font-weight: 500;
    margin-top: 1em;
`;

const PhoneContainer = styled.div`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    margin-left: 200px;
    width: 400px;
`;

class UploadLogo extends Component {
    state = {
        accept: ["image/png", "image/jpeg"],
        dropzoneActive: false,
        maxSize: 5242880, //5MB

        isUplodaing: false,
        timestamp: "",
        uploadPercentage: "",

        errorMessage: "",
    };

    static contextType = CustomiseContext;

    /**
     * File dropped into logo drop zone, validate file type and upload to S3
     * @param droppedFiles {Array<File>}
     */
    onDrop = (droppedFiles) => {
        const { token } = this.context;

        // If the drop zone receives anything other than the accepted formats, or anything greater
        // than the max file size specified, the droppedFiles array will be empty.
        if (droppedFiles.length === 0) {
            this.setState({
                errorMessage: "Your logo must be of the PNG or JPEG format, and less than 5MB in size."
            });
            return;
        }

        // Take first file if multiple provided and use that
        let fileToUpload = droppedFiles[0];
        let fileExtension = fileToUpload.name.split(".").pop();
        this.setState({
            errorMessage: ""
        });

        axios.get(`${API_URL}/company/generateUrl/${fileExtension}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {
            const fullUrl = res.data.url;
            const storedUrl = fullUrl.substring(0, fullUrl.indexOf("?"));

            //set preview of of the updated file to the props
            this.context.uploadFile("file", droppedFiles.map(oneFile => ({
                ...oneFile,
                preview: URL.createObjectURL(oneFile)
            })));

            //set parameters applied in S3 updating
            const parameter = {
                headers: {
                    "Content-Type": fileToUpload.type
                },

                //set current updating percentage to S3 of the file and used to display on screen
                onUploadProgress: progressEvent => {
                    let uploadPercent = (progressEvent.loaded / progressEvent.total) * 100;
                    //get current time and add it to the end of logo url in order to guarantee the file can be
                    //auto refreshed when a new logo is updated due to the updated logo's url is the same
                    let currentTime = new Date().getTime();

                    this.setState({
                        isUploading: true,
                        timestamp: currentTime,
                        uploadPercentage: uploadPercent,
                    });
                }
            };

            //send updated file to the pre-signed url
            axios.put(fullUrl, fileToUpload, parameter)
                .then(result => {
                    this.context.nextAvailable(storedUrl);
                })
                .catch(err => {
                    return Toast.error(err.message);
                });
        }).catch(error => {
            return Toast.error(error.message);
        });
    };

    clickNext = () => {
        if (this.context.isNextClickable) {
            this.context.nextClick();
        } else {
            this.setState({
                errorMessage: "Please upload a company logo first!"
            });
        }
    };

    render() {
        const { accept, maxSize, isUploading, uploadPercentage, errorMessage, timestamp } = this.state;
        const { file, assetUrl, companyName, currentScreen, currentForm, screenIds, colourPalette,
            leftScreen, rightScreen } = this.context;

        return (
            <Container>
                <FlexContainer style={{ flexShrink: 0 }}>
                    <Text size="40px" color="black" align="left" margin="30px 0">Customise <br/>Me </Text>
                    <DifferentFonts 
                        size="30px"
                        marginTop="40px" 
                        mediumText="Step 1: "
                        color="#2A2B2A"
                        regularText="Upload Your Logo"
                        bottom="30px"
                    />
                    <DifferentFonts 
                        size="14px"
                        marginTop="0" 
                        mediumText="Check the"
                        color="#23262D"
                        regularText="live preview"
                        bottom="20px"
                        reverse={true}
                        middleText="to see what your logo will look like on the app."
                        maxWidth="200px"
                    />
                    <DropZone
                        accept={accept}
                        maxSize={maxSize}
                        onDrop={this.onDrop.bind(this)}
                        style={UploadSize}
                    >
                        <UploadContainer>
                            {isUploading ? 
                                <div>
                                    {uploadPercentage === 100 ?
                                        <FlexContainer width="391px" height="102px" alignItems="center">
                                            <BackgroundContainer width="391px" height="102px" image={GridBackground}>
                                                <img style={{ maxWidth: "391px", maxHeight: "102px" }} src={file[0].preview} alt={file[0]} />
                                            </BackgroundContainer>
                                        </FlexContainer>
                                        :
                                        <FlexContainer width="391px" height="102px">
                                            <Text color="black">
                                                Uploading - {uploadPercentage.toFixed()}%
                                            </Text>
                                            <div style={{ marginLeft: "50px" }}>
                                                <svg width="391" height="10">
                                                    <rect width="391" height="10" fill="#566cad" rx="0" ry="0"></rect>
                                                    <rect width={3 * uploadPercentage} height="10" fill="#f9db5e" rx="0" ry="0"></rect>
                                                </svg>
                                            </div>
                                        </FlexContainer>
                                    }
                                </div>
                                :
                                <div>
                                    {file.length === 0 && assetUrl === "" ? 
                                        <img src={UploadImg} alt="UploadBackground" width="391px" /> :
                                        <FlexContainer width="391px" height="102px" alignItems="center">
                                            <BackgroundContainer width="391px" height="102px" image={GridBackground}>
                                                {file.length === 0 ? 
                                                    <img style={{ maxWidth: "391px", maxHeight: "102px" }} src={assetUrl} alt={assetUrl} />
                                                    :
                                                    <img style={{ maxWidth: "391px", maxHeight: "102px" }} src={file[0].preview} alt={file[0]} />
                                                }
                                            </BackgroundContainer>
                                        </FlexContainer>
                                    }
                                </div>
                            }
                        </UploadContainer>
                    </DropZone>

                    <BrowseFileButton onChange={(files) => this.onDrop(files)}>
                        Browse File
                    </BrowseFileButton>
                    <Text color="#23262D" weight="bold" align="left" margin="30px 0 0">
                        Image Format:
                    </Text>
                    <DifferentFonts 
                        size="14px"
                        marginTop="1em"
                        mediumText="PNG"
                        regularText="file background transparent"
                        color="black"
                    />
                    <DifferentFonts
                        size="14px"
                        mediumText="No edges"
                        regularText="around logo image"
                        color="black"
                        bottom="30px"
                    />
                    <hr style={{ width: "100%" }}/>
                    <DifferentFonts 
                        size="14px"
                        marginTop="0" 
                        mediumText="Continue to"
                        color="#23262D"
                        regularText="Step 2: Colour Selection."
                        bottom="1em"
                        reverse={true}
                    />
                    <StyledButton padding="10px" width="150px" color="#FFFFFF"
                        backgroundColor="#2D3037" onClick={() => this.clickNext()} >
                        Next Step
                    </StyledButton>
                    <Text color="red">
                        {errorMessage}
                    </Text>
                </FlexContainer>
                <PhoneContainer>
                    <ImageSlider 
                        companyName={companyName}
                        logoTopOffset={45}
                        phoneFrameTopOffset={30}
                        currentScreen={currentScreen} 
                        currentForm={currentForm}
                        screenIds={screenIds}
                        leftScreen={leftScreen}
                        rightScreen={rightScreen}
                        colourPalette={colourPalette}
                        assetUrl={assetUrl + "?" + timestamp} />
                </PhoneContainer>
            </Container>
        );
    }
}

export default UploadLogo;