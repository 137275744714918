import React, { Component } from "react";
import styled, { css } from "styled-components";
import Text from "components/Text";

export const TextInput = styled.input`
    background-color: ${ p => p.backgroundColor || "white" };
    opacity: ${ p => p.opacity || "1" };
    width: ${ p => p.width || "100%" };
    height: 2.4rem;
    flex-basis: ${ p => p.flexBasis};
    padding: ${ p => p.padding || "10px 20px 10px 20px" };
    border-radius: ${ p => p.borderRadius || "1.2rem" };
    border: ${ p => p.border || "none" };
    border-bottom: ${ p => p.borderBottom };
    margin: ${ p => p.margin || "5px 0" };
    text-align: ${ p => p.align};
    box-sizing: border-box;
    font-size: ${p => p.size || "0.8rem"};
    overflow: ${p => p.hiddenOverFlow && "hidden"};
    ::placeholder {
        opacity: ${ p => p.opacity || "initial" };
        color: ${ p => p.placeholderColor || "#6A1E00" };
        font-weight: 400;
    };
    ${ p => p.isHighlighted && css`
        border: 1px solid red;
    `};
    ::-webkit-inner-spin-button { display: none; }
    ::-webkit-datetime-edit-text { 
        color: #c1a999; 
        padding: 0 0.3rem; 
    }
    ::-webkit-datetime-edit-month-field { color: #6A1E00; }
    ::-webkit-datetime-edit-day-field { color: #6A1E00; }
    ::-webkit-datetime-edit-year-field { color: #6A1E00; }   
    ::-webkit-calendar-picker-indicator { background: #6A1E00; }
`;

export const ErrorText = styled(Text)`
    color: red;
    text-align: left;
    font-size: 12px;
    margin: ${p => p.margin || "0 20px 5px"};
    height: 12px;
    white-space: nowrap;
`;

class FormField extends Component {
    render() {
        const { placeholder, align, errors, onChange, type, onFocus, ...styleProps } = this.props;
        const errorsArray = errors ? errors : [];
    
        return (
            <div>
                <TextInput
                    placeholder={placeholder}
                    placeholderColor="#51315D"
                    align={align}
                    opacity="1.0"
                    type={type}
                    isHighlighted={errorsArray.length > 0}
                    onChange={onChange}
                    onFocus={onFocus}
                    {...styleProps}
                />
                <ErrorText>
                    {errorsArray}
                </ErrorText>
            </div>
        );
    }
}

export default FormField;