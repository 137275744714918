import React, { Component, useState, useContext } from "react";
import { API_URL } from "App";
import axios from "axios";
import FlexContainer from "components/FlexContainer";
import Toast from "Toast";
import Form from 'components/myForm';
import FormInput from 'components/FormInput';

import { SettingContext } from "../SettingContext";


function General() {    
    const { fullName, dob, mobileNumber, companyName, token, getCompanyDetails } = useContext(SettingContext);
        
    const initialValues = {
        fullName: fullName,
        dob: dob,
        mobileNumber: mobileNumber,
        companyName: companyName
    }

    const updateCompanyName = (companyName) => {
        axios.put(`${API_URL}/company`, {
            companyName: companyName
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(() => {
            return Toast.info("Company name was updated.");
        }).catch(error => {
            if (error.response) {    
                Toast.error(error.message.toString());
            }
        });
        return null;
    }

    const updateCompanyAccountName = (firstName, lastName) => {
        axios.put(`${API_URL}/company/updateCompanyAccountName`, {
            firstName: firstName,
            lastName: lastName,
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(() => {
            getCompanyDetails();
            return Toast.info("Account administrator name was updated.");
        }).catch(error => {
            if (error.response) {    
                Toast.error(error.message.toString());
            }
        });
    }

    const updateBirthDate = (birthDate) => {
        axios.put(`${API_URL}/company/updateCompanyAccountBirthDate`, {
            birthDate: birthDate,
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(() => {
            getCompanyDetails();
            return Toast.info("Account administrator birth date was updated.");
        }).catch(error => {
            if (error.response) {    
                Toast.error(error.message.toString());
            }
        });
    }

    const updatemobileNumber = (mobileNumber) => {
        axios.put(`${API_URL}/company/updateCompanyAccountPhoneNumber`, {
            phoneNumber: mobileNumber,
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(() => {
            getCompanyDetails();
            return Toast.info("Account administrator phone number was updated.");
        }).catch(error => {
            if (error.response) {    
                Toast.error(error.message.toString());
            }
        });
    }

    const submitHandler = (form, updateField) => {
        switch (updateField) {
            case "fullName":
                let fullName = form.fullName.trim().split(" ");  
                updateCompanyAccountName(fullName[0], fullName[1]); 
            break;
            
            case "companyName":
                updateCompanyName(form.companyName);
            break;

            case "dob":
                updateBirthDate(form.dob);
            break;

            case "mobileNumber":
                updatemobileNumber(form.mobileNumber);
            break;
            
            default:
            break;

        }
        
        getCompanyDetails();
    }

    return(
        <FlexContainer>
            <Form submit={submitHandler} initialValues={initialValues}>
                <FormInput
                    label="Name"
                    name="fullName"
                    type="text"
                    value={initialValues.fullName}/>
                <FormInput
                    label="Date of Birth"
                    name="dob"
                    type="date"
                    value={initialValues.dob}/>
                <FormInput
                    label="Mobile Number"
                    name="mobileNumber"
                    type="tel"
                    value={initialValues.mobileNumber}/>
                <FormInput
                    label="Business Name"
                    name="companyName"
                    type="text"
                    value={initialValues.companyName}/>
            </Form>
        </FlexContainer>
    );
}

export default General;