import React from "react";
import styled from "styled-components";

const StyledForm = styled.form`
    z-index: 2;
    width: ${p => p.width || "20em"};
    padding-bottom: ${ p => p.paddingBottom || "5vh"};
    margin-left: ${p => p.marginLeft};
    margin-top: ${p => p.marginTop};
`;

function Form(props) {
    const { children, ...styledProps } = props;
    return (
        <StyledForm {...styledProps}>
            {children}
        </StyledForm>
    );
}

export default Form;