import React, { useState, useContext } from "react";
import { API_URL } from "App";
import axios from "axios";
import styled, { css } from "styled-components";
import Backdrop from "Backdrop";
import CancellationModal from "../components/CancellationModal";
import CancellationTermsModal from "../components/CancellationTermsModal";
import FlexContainer from "components/FlexContainer";
import GridRow from "components/GridRow";
import Toast from "Toast";
import LoadingIndicator from "LoadingIndicator";

import { SettingContext } from "../SettingContext";

const ModalContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100vw - 424px);
    height: calc(100vh - 256px);

    z-index: 500;
    top: 100px;
`;

const CancelButton = styled.div`
    height: 2.5rem;
    width: 212px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;

    letter-spacing: 0.35px;
    line-height: 2.5rem;
    color: #FFFFFF;
    text-align: center;

    user-select: none;
    cursor: pointer;
    display: inline-block;
    background: #000000 0% 0% no-repeat padding-box;
    font-weight: 400;
    margin-top: 70px;
`;

function Subscription() {    
    const { companyName, subscriptionStart, subscriptionEnd, subscriptionStatus, purchasedUser, token } = useContext(SettingContext);
    const [loading, setLoading] = useState(false);
    const [showCancellationModal, setShowCancellationModal] = useState(false);
    const [showCancellationTermsModal, setShowCancellationTermsModal] = useState(false);
    const [status, setStatus] = useState(subscriptionStatus);

    const startDate = new Date(subscriptionStart);
    const subcriptionStartDate = new Intl.DateTimeFormat('default', {        
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).format(startDate);
    let endDate = new Date(subscriptionEnd);
    let currentDate = new Date();
    const remainingDaysDiff = Math.abs(currentDate-endDate);
    const remainingDays = Math.ceil(remainingDaysDiff/(1000 * 3600 * 24));

    const companyDetails = {
        companyName: companyName,
        subscriptionEnd: subscriptionEnd,
        purchasedUser: purchasedUser
    }
   
    const cancelSubscription = () => {
        setShowCancellationModal(false);
        setLoading(true);
        axios.put(`${API_URL}/company/cancel`, {}, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then (res => {
            setLoading(false);
            setStatus("Inactive");
            Toast.info("Your subscription has been cancelled.");       
        }).catch(error => {
            Toast.error(error.message);
        });
    }

    if (loading) {
        return <LoadingIndicator />;
    }

    return(
        <>
            <FlexContainer>
                <GridRow labelName={companyName} labelWeight="600" labelText={"Start Date: "+ subcriptionStartDate} />
                <GridRow labelName="Subscription Status: " labelNameLightColor="#A7CF5B" labelNameLight={status} labelText={"Finish Date: "+ subscriptionEnd} />
                <GridRow labelName="Total User Purchased: " labelNameLight={purchasedUser} labelNameLightWeight="600" labelText={"Expires in "+ remainingDays + " days"} labelTextWeight="600" />
            </FlexContainer>
            {status == "Active" && <CancelButton onClick={() => setShowCancellationModal(true)}>Cancel Subscription</CancelButton>}
            {showCancellationModal &&
                <>
                    <Backdrop/>
                    <ModalContainer>
                        <CancellationModal closeCallback={() => setShowCancellationModal(false)}
                            confirmCallback={() => cancelSubscription()}
                            companyDetails={companyDetails}
                            handleShowCancellationTermsModal={() => setShowCancellationTermsModal(true)}>
                        </CancellationModal>
                    </ModalContainer>
                </>
            }
            {showCancellationTermsModal &&
                <>
                    <Backdrop/>
                    <ModalContainer>
                        <CancellationTermsModal closeCallback={() => setShowCancellationTermsModal(false)}>
                        </CancellationTermsModal>
                    </ModalContainer>
                </>
            }
        </>
    );
}

export default Subscription;