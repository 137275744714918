import blackSun from "img/BlackSun.png";
import CustomerOfDFM from "img/CustomerOfDFM.png";
import DFMBlackLogo from "img/Logo.png";
import Customer from "img/Customer.png";
import PoweredBy from "img/PoweredBy.png";
import InstagramIcon from "img/InstagramIcon.png";
import FacebookIcon from "img/FacebookIcon.png";

const InvoiceTemplate = (doc, invoice) => {
    doc.setFontSize(10);
    var width = doc.internal.pageSize.getWidth()

    var logoImg = new Image()
    logoImg.src = blackSun
    doc.addImage(logoImg, "png", 100, 10, 10,10);

    var CustomerOfDFMImg = new Image();
    CustomerOfDFMImg.src = CustomerOfDFM;
    doc.addImage(CustomerOfDFMImg, "png", 67, 20, 75, 25);
    doc.text("Your tax invoice has been paid!", width/2, 55, { align: 'center' });

    doc.line(10, 60, 200, 60, "S")

    doc.text(`Bill to: ${invoice.billTo}`, width/2, 70, { align: 'center' });
    doc.text(`Account: ${invoice.property}`, width/2, 75, { align: 'center' });
    doc.text(`Date: ${invoice.date}`, width/2, 80, { align: 'center' });
    doc.text(`Description`, 20, 95, { align: 'center' });
    doc.text(`Price`, 110, 95, { align: 'center' });
    doc.text(`Invoice No: ${invoice.invoiceNo}`, width/2, 85, { align: 'center' });

    doc.line(10, 100, 200, 100, "S")

    doc.text(`Daily Fix Me Subscription - ${invoice.numberOfUsers} Users`, 10, 110);
    doc.text(`${invoice.startTime} to ${invoice.endTime}`, 10, 115);
    doc.text(`${invoice.totalIncGST}`, 150, 110);

    doc.line(10, 120, 200, 120, "S")

    doc.text(`Subtotal`, 110, 130); 
    doc.text(`${invoice.amount}`, 140, 130);
    doc.text("GST", 110, 135); 
    doc.text(`${invoice.GST}`, 140, 135);
    doc.text("Total", 110, 140); 
    doc.text(`${invoice.totalIncGST}`, 140, 140); 
    doc.text(`PAID`, 110, 145); 
    doc.text(`${invoice.balanceDue}`, 140, 145);
    doc.text(`All amounts are in AUD`, 110, 150); 

    doc.line(10, 160, 200, 160, "S")

    doc.text(`You can see the status of your subscription by visiting `, 10, 170);
    doc.setTextColor(0,0,255);
    doc.textWithLink('dailyfixme.com/login', 95, 170, {url: 'https://dailyfixme.com/login', color: 'blue'});

    var fullLogo = new Image();
    fullLogo.src = DFMBlackLogo;
    doc.addImage(fullLogo, "png", 90, 190, 30, 12);

    var customerMsg = new Image();
    customerMsg.src = Customer;
    doc.addImage(customerMsg, "png", 65, 200, 80, 15);

    doc.setTextColor(0,0,0);
    doc.text("Daily Fix Me by Onpoint Holdings PTY. LTD. 1/124 Exhibition Street, Melbourne, VIC 3000 ABN: 89618965807", 15, 235);

    var PoweredByImg = new Image();
    PoweredByImg.src = PoweredBy;
    doc.addImage(PoweredByImg, "png", 80, 245, 50, 10);

    var InstagramIconImg = new Image();
    InstagramIconImg.src = InstagramIcon;
    doc.addImage(InstagramIconImg, "png", 92, 260, 10, 10);
    doc.link(92, 260, 10, 10, {url: 'https://www.instagram.com/dailyfixme/'});

    var FacebookIconImg = new Image();
    FacebookIconImg.src = FacebookIcon;
    doc.addImage(FacebookIconImg, "png", 105, 260, 10, 10);
    doc.link(105, 260, 10, 10, {url: 'https://www.facebook.com/pg/Daily-Fix-Me-781958982177482/'});

    doc.setTextColor(0,0,255);
    doc.text("info@dailyfixme.com", 88, 280, {url:'mailto:info@dailyfixme.com'});

    doc.setTextColor(0,0,0);
    doc.text("Daily Fix Me © 2019", 88, 290);

    doc.save(`${invoice.invoiceNo}.pdf`);
    
    return doc;
};

export default InvoiceTemplate;