import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "App/UserContext";

class AuthorizedRoute extends Component {
    render() {
        const { component: Component, ...rest } = this.props;
        return (
            <UserContext.Consumer>
                {user => (
                    <Route {...rest} render={props => {
                        return user !== null
                            ? <Component {...rest} />
                            : <Redirect to="/login" />;
                    }} />
                )}
            </UserContext.Consumer>
        );
    }
}

export default AuthorizedRoute;