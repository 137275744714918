import React, { Component } from "react";
import styled, { css } from "styled-components";
import Text from "./Text";
import OphLogo from "../img/OphLogo.svg";
import { reshowDialog } from "cookieconsent/CookieConsent";

// Media Queries

const tablet = (...args) => css`
    @media (min-width: 38em) {
        ${css(...args)}
    }
`;
const desktop = (...args) => css`
    @media (min-width: 64em) {
        ${css(...args)}
    }
`;

// Styled components

const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-top: 32px;
    text-align: center;
    width: 100%;

    ${desktop`
        flex-direction: row;
        height: 9em;
        margin-top: 0;
        padding-bottom: 0;
    `};
`;

const FooterLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    ${tablet`
        width: 100%;
        flex-direction: row;
        box-sizing: border-box;
    `};
`;

const FooterLink = styled.a`
    color: white;
    font-weight: 500;
    padding: 16px 0;
    text-decoration: none;

    ${tablet`
        flex-grow: 1;
    `};

    ${desktop`
        flex-grow: 0;
        padding: 16px 16px;
        width: 12em;
    `};
`;

const FooterBtn = styled.p`
    font-weight: 500;
    color: white;
    padding: 16px 0;
    width: 100%;
    margin: 0;
    text-decoration: none;
    cursor: pointer;

    ${tablet`
        padding: 16px;
        width: 12em;
    `};
`;

const OphPowerContainer = styled.div`
    display: flex;
    width: 20em;
    align-items: center;
    justify-content: center;

    ${tablet`
        justify-content: right;
    `};
`;

const OphImage = styled.img`
    width: 8em;
    height: auto;
    margin: 2em;
`;

const OphLink = styled.a`
    text-decoration: none;
`;

class Footer extends Component {
    openCookieDialog = () => {
        reshowDialog();
    };

    render() {
        return (
            <FooterContainer>
                <OphPowerContainer />
                <FooterLinksContainer>
                    <FooterLink href="https://s3-ap-southeast-2.amazonaws.com/dailyfixme.content/Terms+and+Conditions.pdf" target="_blank" rel="noopener noreferrer">
                        Terms &amp; Conditions
                    </FooterLink>
                    <FooterLink href="https://s3-ap-southeast-2.amazonaws.com/dailyfixme.content/Privacy+Policy.pdf" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </FooterLink>
                    <FooterLink href="mailto:support@dailyfixme.com">
                        Contact Us
                    </FooterLink>
                    <FooterBtn onClick={this.openCookieDialog}>
                        Cookie Policy
                    </FooterBtn>
                </FooterLinksContainer>
                <OphPowerContainer>
                    <OphLink href="https://www.onpoint-holdings.com" target="_blank" rel="noopener noreferrer">
                        <Text color="white">powered by</Text>
                    </OphLink>
                    <OphLink href="https://www.onpoint-holdings.com" target="_blank" rel="noopener noreferrer">
                        <OphImage src={OphLogo} />
                    </OphLink>
                </OphPowerContainer>
            </FooterContainer>
        );
    }
}

export default Footer;
