import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 400px;
    margin-top: ${p => p.top || "50px"};
    justify-content: flex-end;
`;

function ButtonContainer(props) {
    const { children, ...styleProps } = props;
    return (
        <StyledContainer {...styleProps}>
            {children}
        </StyledContainer>
    );
}

export default ButtonContainer;