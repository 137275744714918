import React, { Component } from "react";
import "../css/styles.css";
import axios from "axios";
import { API_URL } from "App";
import styled from "styled-components";
import CancelButton from "../components/CancelButton";
import ProceedButton from "../components/ProceedButton";
import LoadingIndicator from "LoadingIndicator";
import CreditLogos from "img/CreditLogos.png";
import SquareLogo from "img/Square.png";
import FlexContainer from "components/FlexContainer";
import Text from "components/Text";
import { Forms } from "Subscription";
import { SubscriptionContext } from "../SubscriptionContext";
import Toast from "Toast";
import TermsConditionModal from "../components/TermsConditionModal";

const ErrorButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 25px 0;
`;

const ButtonContainer = styled.div`
    display: flex;
    margin: 25px 0;
`;

const styles = {
    name: {
        verticalAlign: "top",
        display: "none",
        margin: 0,
        border: "none",
        fontSize: "16px",
        fontFamily: "Helvetica Neue",
        padding: "16px",
        color: "#373F4A",
        backgroundColor: "transparent",
        lineHeight: "1.15em",
        placeholderColor: "#000",
        _webkitFontSmoothing: "antialiased",
        _mozOsxFontSmoothing: "grayscale"
    },
    leftCenter: {
        float: "left",
        textAlign: "center"
    },
    blockRight: {
        display: "block",
        float: "right"
    },
    center: {
        textAlign: "center"
    },
    marginLeft: {
        marginLeft: "20px",
        float: "right"
    }
};

class PaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardBrand: "",
            nonce: undefined,
            googlePay: false,
            applePay: false,
            masterpass: false,
            loading: false,
            errorMessage: [],
            clickable: false,
            showTC: false,
            acceptTC: false
        };
        this.requestCardNonce = this.requestCardNonce.bind(this);
    }

    async makeCardFormAsync() {
        let locationId;
        let applicationId;
        if (window.location.hostname === "business.dailyfixme.com") {
            locationId = "N3ANA4TY5QBPK";
            applicationId = "sq0idp-M--KH8n0nrz6y84Dpa9qVg";
        } else {
            locationId = "L68F9PPET2VMT";
            applicationId = "sandbox-sq0idb-Wy_cpLu6QBUPCvqIfMy3jQ";
        }

        const payments = window.Square.payments(applicationId, locationId);
        const card = await payments.card({});
        await card.attach("#card");
        this.onPaymentFormLoaded(card);
    }

    componentDidMount() {
        if (!window.Square) {
            console.error("Square not loaded");
            return;
        }
        this.makeCardFormAsync();
    }

    componentWillUnmount() {
        /** @const {null|window.Card} card */
        const card = this.state.card;
        if (card) {
            card.destroy();
        }
    }

    showTCModal = () => {
        this.setState({ showTC: true });
      };
    
    acceptTC = () => {
        this.setState({ 
            showTC: false,
        });
    };

    onPaymentFormLoaded(card) {
        // allow submit button be clickable after the square form can be loaded completelly
        this.setState({
            clickable: true,
            card: card
        });
        document.getElementById("sq-ccbox").removeAttribute("hidden");
    }

    async requestCardNonce() {
        if (!this.state.clickable) {
            return;
        }
        this.setState({
            loading: true,
        });

        const tokenResult = await this.state.card.tokenize();
        if (tokenResult.status === "OK") {
            this.onCardNonceReceived(null, tokenResult.token);
        } else {
            if (window.location.hostname !== "business.dailyfixme.com") {
                console.error("Tokenization failed", tokenResult);
            }
            this.onCardNonceReceived(tokenResult.errors, null);
        }
    }

    onCardNonceReceived(errors, nonce) {
        const { token, companyName, numOfUsers, nextClick, updateLoading, currentForm, getAllCards } = this.props;

        if (errors || !nonce) {
            let errorMessages = [];
            errors.forEach((error) => {
                errorMessages.push(error.message);
            });
            this.setState({
                loading: false,
                nonce: nonce,
                errorMessage: errorMessages
            });
            return;
        }

        this.setState({
            nonce: nonce,
            loading: true,
            showTC: true
        });

        // Purchase more or add more users
        if (currentForm === Forms.REVIEW) {
            axios.post(`${API_URL}/company/square`, {
                nonce: nonce,
                companyName: companyName,
                numOfUsers: numOfUsers
            }, {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + token
                }
            })
                .then(() => {
                    updateLoading();
                        this.setState({
                            loading: false,
                        }, 
                            getAllCards(),
                            this.acceptTC(),
                            nextClick()
                    );
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data.exception) {
                            this.setState({
                                loading: false,
                            });
                            Toast.error(error.response.data.exception.toString());
                        } else {
                            this.setState({
                                loading: false,
                            });
                            Toast.error(error.response.data.error.toString());
                        }
                    } else {
                        this.setState({
                            loading: false,
                        });
                        Toast.error(error.message.toString());
                    }
                });
        } else if (currentForm === Forms.CHECKOUT) {
            axios.post(`${API_URL}/company/square/more/newCard`, {
                nonce: nonce,
                numOfUsers: numOfUsers
            }, {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + token
                }
            })
                .then(() => {
                    updateLoading();
                    this.setState({
                        loading: false
                        }, 
                        getAllCards(),
                        this.acceptTC(),
                        nextClick()
                    );
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data.exception) {
                            this.setState({
                                loading: false,
                            });
                            Toast.error(error.response.data.exception.toString());
                        } else {
                            this.setState({
                                loading: false,
                            });
                            Toast.error(error.response.data.error.toString());
                        }
                    } else {
                        this.setState({
                            loading: false,
                        });
                        Toast.error(error.message.toString());
                    }
                });
        } else if (currentForm === Forms.EDIT) {
            axios.post(`${API_URL}/company/square/card`, {
                nonce: nonce
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            }).then(() => {
                getAllCards();
                nextClick();
                return Toast.info("Change payment method success");
            }).catch(error => {
                if (error.response) {
                    if (error.response.data.error.messages) {
                        this.setState({
                            loading: false,
                        });
                        Toast.error(error.response.data.exception.toString());
                    } else {
                        this.setState({
                            loading: false,
                        });
                        Toast.error(error.response.data.error.toString());
                    }
                } else {
                    this.setState({
                        loading: false,
                    });
                    Toast.error(error.message.toString());
                }
            });
        }
    }

    static contextType = SubscriptionContext;

    render() {
        const { callBack, currentForm } = this.props;
        const { loading, errorMessage, showTC } = this.state;

        return (
            <div className="container">
                <div id="form-container">
                    { !this.state.clickable && <LoadingIndicator/>}
                    <FlexContainer border="1px solid #000000" padding="20px" margin="30px 0">
                        <div id="sq-ccbox" hidden>
                            {currentForm === Forms.REVIEW &&
                                <FlexContainer direction="row" justifyContent="space-between">
                                    <Text size="20px" color="black" weight="500">Credit or Debit Card </Text>
                                    <span style={styles.blockRight}>
                                        <img src={CreditLogos} height="50px" width="150px" alt="CreditLogos" />
                                    </span>
                                </FlexContainer>
                            }
                            <div id="cc-field-wrapper">
                                <FlexContainer>
                                    <div id="card" />
                                    <input type="hidden" id="card-nonce" name="nonce" />
                                    <FlexContainer marginTop="20px">
                                        <img src={SquareLogo} height="40px" width="140px" alt="square" />
                                    </FlexContainer>
                                </FlexContainer>
                            </div>
                        </div>
                    </FlexContainer>
                    <ErrorButtonContainer>
                        { errorMessage ?
                            errorMessage.map((error, index) => {
                                return (
                                    <Text
                                        key={index}
                                        color="red"
                                        size="17px"
                                        weight="400"
                                        align="left"
                                        margin="0"
                                        height="17px"
                                    >
                                        <li>{error}</li>
                                    </Text>
                                );
                            })
                            :
                            <div></div>
                        }
                        <ButtonContainer style={{ height: "40px" }} >
                            <CancelButton onClick={ callBack } disabled={this.state.loading} >
                                BACK
                            </CancelButton>
                            {loading ?
                                <FlexContainer width="160px" padding="5px 10px" marginLeft="20px" height="40px">
                                    <LoadingIndicator height="20px" width="20px"/>
                                </FlexContainer>
                                :
                                <ProceedButton color="#51315D" onClick={this.requestCardNonce}>
                                    { currentForm === Forms.EDIT ? "Save Changes" : "Confirm Payment" }
                                </ProceedButton>
                            }
                        </ButtonContainer>
                    </ErrorButtonContainer>
                </div>
                <p style={styles.center} id="error" />
                <TermsConditionModal show={showTC} acceptTC={this.acceptTC} />
            </div>
        );
    }
}

export default PaymentForm;