import React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";

const BackdropContainer = styled.div`
    position: fixed;
    display: flex;
    align-items: ;center;
    justify-content: center;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
`;

function Backdrop()  {
    return (
        <BackdropContainer />
    )
}

export default Backdrop;