import React, { Component } from "react";
import styled from "styled-components";
import Button from "components/Button";
import Text from "components/Text";
import BackImg from "img/plus.svg";
import PrimaryImg from "img/PrimaryBtn.svg";
import SecondaryImg from "img/SecondaryBtn.svg";
import { Screens } from "Customise";
import ColorPicker from "../components/ColorPicker";
import ImageSlider from "../components/ImageSlider";
import FlexContainer from "components/FlexContainer";
import { CustomiseContext } from "../CustomiseContext";
import DifferentFonts from "components/DifferentFonts";
import LoadingIndicator from "LoadingIndicator";

const Container = styled.div`
    display: flex;
    margin: auto;
`;

const ColorButtonsContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
`;

const ButtonContainer = styled.div`
    width: 100px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ButtonsContainder = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

const PhoneContainer = styled.div`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    margin-left: 64px;
    width: 400px;
`;

export function ScreenSwitch(param) {
    switch (param) {
    case Screens.MAIN:
        return "Main Screen";
    case Screens.NOTICEBOARD:
        return "Noticeboard Screen";
    case Screens.PLAYLISTS:
        return "Playlists Screen";
    case Screens.GOALS:
        return "Goals Screen";
    case Screens.SPLASH:
        return "Loading Screen";
    default:
        break;
    }
}

class SelectColor extends Component {
    static contextType = CustomiseContext;

    state = {
        timestamp: "",
        loading: false,
        errorMessage: "",
    }

    componentDidMount() {
        //make sure logo image will be updated which the current page rendered
        let currentTime = new Date().getTime();
        this.setState({
            timestamp: currentTime,
            loading: false,
        });
    }

    handleBackgroundChange = (color) => {
        this.context.updateBackgroundColor(color);
    };

    handlePrimaryColorChange = (color) => {
        this.context.updatePrimaryColor(color);
    };

    handleSecondaryColor = (color) => {
        this.context.updateSecondaryColor(color);
    };

    finish = () => {
        // Determine if all three colours have been set by the user yet.
        // The context colour objects are initialised as arrays for initial display, and then
        // become a string after being set by the user.
        if (Array.isArray(this.context.backGroundColor) ||
            Array.isArray(this.context.primaryColor) ||
            Array.isArray(this.context.secondaryColor)) {
            this.setState({
                errorMessage: "Please select all three colours first!"
            });
        } else {
            // Facebook Pixel Tracking
            window.fbq && window.fbq("track", "CustomizeProduct");

            this.setState({ loading: true });
            this.context.nextClick();
        }
    };

    render() {
        const { timestamp, loading, errorMessage } = this.state;

        const { currentScreen, backGroundColor, secondaryColor, primaryColor, colourPalette,
            currentForm, screenIds, leftScreen, rightScreen, isComplete, assetUrl } = this.context;

        return (
            <Container>
                <FlexContainer style={{ flexShrink: 0 }}>
                    <Text size="60px" color="black" align="left" margin="30px 0">Customise <br/>My Me App </Text>
                    <DifferentFonts 
                        size="30px"
                        marginTop="0" 
                        mediumText="Step 2:"
                        color="#2A2B2A"
                        regularText="Colour Selection"
                        bottom="0"
                    />
                    <Text size="14px" color="black" margin="20px 0" align="left" maxWidth="450px" lineHeight="20px">
                        Select three colours that represent your business best. Refer to the 
                        live preview on the right for a visual representation and see your 
                        app come to life!
                    </Text>
                    
                    {/* Generating color pickers for different screens */}
                    <Text size="20px" color="#2A2B2A" margin="30px 0" align="left">
                            Select Colours:
                    </Text>
                    <ColorButtonsContainer>
                        <ButtonContainer>
                            <ColorPicker color={backGroundColor} colorImg={BackImg} isComplete={isComplete}
                                handleColorChange={this.handleBackgroundChange} />
                            <Text size="15px" color="black" align="left">Background</Text>
                        </ButtonContainer>
                        <ButtonContainer>
                            <ColorPicker color={primaryColor} colorImg={PrimaryImg} isComplete={isComplete}
                                handleColorChange={this.handlePrimaryColorChange} />                          
                            <Text size="15px" color="black">Primary</Text>
                        </ButtonContainer>
                        <ButtonContainer>
                            <ColorPicker color={secondaryColor} colorImg={SecondaryImg} isComplete={isComplete}
                                handleColorChange={this.handleSecondaryColor} />
                            <Text size="15px" color="black" align="left">Secondary</Text>
                        </ButtonContainer>
                    </ColorButtonsContainer>
                    
                    <hr style={{ width: "100%" }}/>
                    <DifferentFonts 
                        size="14px"
                        marginTop="10px" 
                        mediumText="Click"
                        color="#23262D"
                        regularText="Finish"
                        bottom="0"
                        reverse={true}
                        middleText="to complete customisation. You can make additional changes as"
                        maxWidth="500px"
                    />
                    <DifferentFonts 
                        size="14px"
                        marginTop="0" 
                        mediumText="required by selecting"
                        color="#23262D"
                        regularText="‘My Me App’"
                        bottom="25px"
                        reverse={true}
                        middleText="on the side menu."
                        maxWidth="500px"
                    />
                    <ButtonsContainder>
                        <Button color="black" backgroindColor="transparent" borderRadius="20px" border="1px solid black"
                            padding="10px" width="80px" onClick={() => this.context.previousClick()}>
                            Back
                        </Button>
                        { loading ? 
                            <FlexContainer width="130px" height="34px" >
                                <LoadingIndicator height="7px" width="7px"/> 
                            </FlexContainer>
                            :
                            <Button color="#d8d8d8" backgroundColor="black" borderRadius="20px" border="1px solid black"
                                padding="10px" marginLeft="10px" width="130px" style={{ boxShadow: "2px 2px #e3e3e3" }} 
                                onClick={ this.finish } >
                            Finish
                            </Button>
                        }
                    </ButtonsContainder>
                    <Text color="red">
                        {errorMessage}
                    </Text>
                </FlexContainer>
                <PhoneContainer>
                    <ImageSlider 
                        logoTopOffset={45}
                        phoneFrameTopOffset={30}
                        currentScreen={currentScreen}
                        currentForm={currentForm}
                        screenIds={screenIds}
                        leftScreen={leftScreen}
                        rightScreen={rightScreen}
                        colourPalette={colourPalette}
                        assetUrl={assetUrl + "?" + timestamp}/>
                </PhoneContainer>
            </Container>
        );
    }
}

export default SelectColor;