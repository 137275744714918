import React, { Component } from "react";
import styled from "styled-components";
import FlexContainer from "components/FlexContainer";
import Text from "components/Text";
import LeftText from "../components/LeftText";
import { TextInput } from "components/FormField";
import Price from "../components/Price";
import ButtonContainer from "../components/ButtonContainer";
import CancelButton from "../components/CancelButton";
import ProceedButton from "../components/ProceedButton";
import { SubscriptionContext } from "../SubscriptionContext";
import { SUBSCRIPTION_COST, PRICE_PER_USER, Forms } from "../Subscription";

const StyledTextInput = styled(TextInput)`
    padding: 5px 40px;
    width: 280px;
    height: 55px;
    background-color: transparent;
    color: #2A2B2A;
    margin: 0 0 40px;
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #2D3037;
    border-radius: 60px;
    ::placeholder {
        color: #2A2B2A;
    }
`;

const Hr = styled.hr`
    margin: 0 64px;
`;

const InlineText = styled.p`
    display: inline-block;
    margin: 0;
    font-size: 14px;
    font-family: ${p => p.family || "Affogato"};
    font-weight: ${p => p.weight};
    color: ${p => p.color || "inherit"};
`;

class Plan extends Component {
    static contextType = SubscriptionContext;

    render() {
        const { totalNumber, userCost, gstCost, totalCost, isAustralian, startDayMomentType, 
            currentForm, moreUsers, moreCost, moreGst, moreTotal, subscriptionEndDate, endDayMomentType,
            updatePrice, backToPrevious, nextClick, remainDays, subscriptionPeriod } = this.context;

        const proceedToPayment = () => {
            // Facebook Pixel Tracking
            window.fbq && window.fbq("track", "AddToCart");

            nextClick();
        };

        return (
            <FlexContainer flexGrow="1">                
                <Text size="30px" color="#23262D" margin="0 0 100px 0" weight="600">
                    {currentForm === Forms.PLAN ?  
                        "Subscription Details" : "Purchase More Users"}
                </Text>
                <FlexContainer margin="auto" justifyContent="center" direction="row">
                    <FlexContainer minHeight="400px" alignItems="flex-end">
                        <FlexContainer width="400px" >
                            { currentForm === Forms.PLAN && 
                                <div>
                                    <LeftText size="30px" color="#2A2B2A" margin="0" fontWeight="600" >
                                        Number of Users:
                                    </LeftText>
                                    <LeftText size="14px" color="#2A2B2A" margin="0" lineHeight="22px">
                                        Please enter the total number of Users that will be invited to 
                                        use your custom Me app. This includes employees and clients.
                                    </LeftText>
                                    <LeftText size="14px" color="#51315D" margin="20px 0 0" lineHeight="22px">
                                        <InlineText weight="500"> <b>For Example:</b> </InlineText><br/>
                                        <InlineText>Total Users Purchased: </InlineText>
                                        <InlineText weight="500">&nbsp;50</InlineText><br/>
                                        <InlineText>Number of Active Employees:</InlineText> 
                                        <InlineText weight="500">&nbsp;30</InlineText> <br/>
                                        <InlineText>Number of Active Clients:</InlineText>
                                        <InlineText weight="500">&nbsp;10</InlineText> <br/>
                                        <InlineText>Total Number of Users Remaining:</InlineText>
                                        <InlineText weight="500">&nbsp;10 </InlineText> 
                                    </LeftText>
                                    <LeftText size="20px" margin="30px 0 10px">
                                        <b>Enter total number of users here:</b><br/>
                                        <InlineText size="14px" color="#51315D">Minimum of 1 user</InlineText><br/>
                                        <InlineText size="14px" color="#51315D">Maximum of 1000 users</InlineText><br/>
                                    </LeftText>
                                </div> 
                            }
                            { currentForm === Forms.PURCHASE && 
                                <div>
                                    <LeftText size="30px" color="#2A2B2A" margin="20px 0" fontWeight="600">
                                        New Users:
                                    </LeftText>
                                    <LeftText size="14px" color="#2A2B2A" margin="0" lineHeight="22px" fontWeight="600">
                                        Please Note: Any additional users you purchase will be added to your 
                                        current ongoing 12 month subscription ending {subscriptionEndDate}. Therefore your new 
                                        users subscription will end simultaneously with your initial sign up.
                                    </LeftText>
                                    <LeftText size="20px" margin="60px 0 10px" color="#51315D" fontWeight="600">
                                        Enter total number of additional users here:
                                    </LeftText>
                                </div>
                            }
                            <StyledTextInput
                                type="number"                             
                                placeholder={ currentForm === Forms.PLAN ? totalNumber : moreUsers }
                                onChange={(e) => updatePrice(currentForm, e.target.value)}>
                            </StyledTextInput>
                            { currentForm === Forms.PLAN && 
                                <LeftText margin="0" color="#C70099" weight="500" size="14px">
                                    **Please note that you will be able to purchase additional
                                    users after your account has been activated.
                                </LeftText>
                            }
                        </FlexContainer>
                    </FlexContainer>
                    <Hr/>
                    <FlexContainer minHeight="400px">
                        <Price
                            Forms={Forms}
                            currentForm={currentForm} 
                            SUBSCRIPTION_COST={SUBSCRIPTION_COST}
                            PRICE_PER_USER={PRICE_PER_USER}
                            totalNumber={currentForm === Forms.PLAN ? totalNumber : moreUsers}
                            userCost={currentForm === Forms.PLAN ? userCost : moreCost}
                            gstCost={currentForm === Forms.PLAN ? gstCost : moreGst}
                            totalCost={currentForm === Forms.PLAN ? totalCost : moreTotal}
                            isAustralian={isAustralian}
                            remainDays={remainDays}
                            subscriptionPeriod={subscriptionPeriod}
                            subscriptionEndDate={subscriptionEndDate}
                            endDayMomentType={endDayMomentType}
                            startDayMomentType={startDayMomentType}
                        />                     
                        <ButtonContainer>
                            <CancelButton onClick={() => backToPrevious()}>
                                Back
                            </CancelButton>
                            <ProceedButton color="#51315D" onClick={proceedToPayment}>
                                Proceed To Payment
                            </ProceedButton>
                        </ButtonContainer>
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        );
    }
}

export default Plan;