import React, { Component } from "react";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { ErrorText } from "FormField";

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border: none;
    border-radius: 1.2rem;
    height: 2.4rem;
`;

const StyledPhoneInput = styled(PhoneInput)`

    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2.4rem;
    
    --PhoneInputCountrySelectArrow-color: #51315D;
    --PhoneInputCountrySelectArrow-opacity: 100%;

    .PhoneInputCountry {
        position: absolute;
        margin-left: 1.2rem;
        height: 2.4rem;
    }

    .PhoneInputInput {
        background-color: transparent;
        border: none;
        border-radius: 1.2rem;
        height: 100%;
        border: none;
        box-sizing: border-box;
        opacity: 1;
        font-size: 0.8rem;
        text-align: center;

        ::placeholder {
            opacity: 1;
            color: #51315D;
            font-weight: 400;
            font-size: 0.8rem;
            height: 100%;
        }
    }
`;

class PhoneNumberInput extends Component {
    render() {
        const { errors } = this.props;
        const errorsArray = errors ? errors : [];
        return (
            <div>
                <InputContainer>
                    <StyledPhoneInput {...this.props} />
                </InputContainer>
                <ErrorText>
                    {errorsArray}
                </ErrorText>
            </div>

        );
    }
}

export default PhoneNumberInput;