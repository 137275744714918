import React, { Component } from "react";
import styled from "styled-components";
import Next from "img/Next.svg";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: ${p => p.margin};
`;

const Image = styled.img`
    height: 30px;
    width: 100%;
    object-fit: contain;
`;

const Label = styled.p`
    color: #707070;
    font-size: 20px;
    margin: 0;
    margin-top: 5px;
`;

class NextButton extends Component {
    render() {
        const { alt, text, onClick, src, margin } = this.props;

        return (
            <Container onClick={onClick} margin={margin}>
                <Image 
                    src={src ? src : Next}
                    alt={alt}
                />
                {text && (
                    <Label>
                        {text}
                    </Label>
                )}
            </Container>
        );
    }
}

export default NextButton;